<template>
  <div class="sidebar">
    <div class="sidebar__logo mb-3 ms-1">
      <img
        class="m-auto"
        :src="require('@/assets/images/sidebarlogo.png')"
        alt="" />
    </div>
    <div class="mb-3 text-white">.</div>
    <div class="sidebar__menu">
      <template v-for="(option, index) in menuOptions">
        <div
          v-if="validateUserPermission(index)"
          class="sidebar__menu--item"
          :class="{ selected_item: validateIsSelected(option.route) }"
          :key="index + option.title"
          @click="changeRoute(option.route)">
          <div class="sidebar__menu--item__logo">
            <font-awesome-icon
              :icon="[option.logoSource, option.logo]"
              size="lg" />
          </div>
          <div class="sidebar__menu--item__title">{{ option.title }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import menuOptions from "@/constants/sidebar";
import { logOut } from "@/helpers/auth";
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      menuOptions,
    };
  },
  computed: {
    ...mapGetters(["getPermissionRoutesByUserType"]),
  },
  methods: {
    validateIsSelected(parentRoute) {
      const route = this.$route.name;
      return route ? route.includes(parentRoute) : false;
    },
    validateUserPermission(index) {
      const menuOption = this.menuOptions[index];
      if (menuOption.route === "logOut") return true;
      return this.getPermissionRoutesByUserType.includes(menuOption.route);
    },
    changeRoute(routeToGo) {
      if (routeToGo === this.$route.name) return;
      if (routeToGo === "logOut") {
        logOut();
        return;
      }
      if (routeToGo === "patient") {
        this.$router.push({
          name: routeToGo,
          params: { id: 1 },
        });
      } else {
        this.$router.push({
          name: routeToGo,
        });
      }
    },
  },
};
</script>
