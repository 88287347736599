export default (axios) => ({
  getSchedule() {
    return axios.get("v1/agendas");
  },
  getScheduleByUser() {
    return axios.get("v1/agendas_user");
  },
  getScheduleServices() {
    return axios.get("v1/agendas_services");
  },
  postSchedule(payload) {
    return axios.post(`v1/agendas`, payload);
  },
  putSchedule(id, payload) {
    return axios.put(`v1/agendas/${id}`, payload);
  },
  removeSchedule(id) {
    return axios.delete(`v1/agendas/${id}`);
  },
  getServices(payload) {
    return axios.get("v1/services", { params: payload });
  },
  postServices(payload) {
    return axios.post(`v1/services`, payload);
  },
  putServices(id, payload) {
    return axios.put(`v1/services/${id}`, payload);
  },
  removeService(id) {
    return axios.delete(`v1/services/${id}`);
  },
  getClinics() {
    return axios.get("v1/clinics");
  },
  getMedics() {
    return axios.get("v1/medics");
  },
  getMedicsByClinic(payload) {
    return axios.get("v1/medics", { params: payload });
  },
  getMigrationLogs(payload) {
    return axios.get("v1/migration_logs", { params: payload });
  },
  migratePatient(payload) {
    return axios.post(`v1/migrate_filiation`, payload);
  },
});
