export default (axios) => ({
  getPatientPsychologies(payload) {
    return axios.get("v1/patient_psychologies", { params: payload });
  },
  getPatientPsychology(id) {
    return axios.get(`v1/patient_psychology/${id}`);
  },
  postPatientPsychology(payload) {
    return axios.post("v1/patient_psychology", payload);
  },
  getPatientPsychologyLast(payload) {
    return axios.get("v1/patient_psychologies_last", { params: payload });
  },
});
