export default {
  setLoaderCount(state, value) {
    const temp = state.loader.count + value;
    if (temp >= 0) {
      state.loader.count = temp;
    }
  },
  setLoaderMessage(state, message = "") {
    if (state.loader.message === "" && message === "") {
      return;
    }
    state.loader.message = message;
  },
  setLoaderMessageWTimeout(state, message = "") {
    if (state.loader.message === "" && message === "") {
      return;
    }
    state.loader.message = message;
    setTimeout(() => {
      state.loader.message = "";
    }, 5000);
  },
};
