export default {
  getSecondOpinion(state) {
    return state.newFiliationSecondOpinionState;
  },
  getPaymentTypeAction(state) {
    return state.paymentTypeAction;
  },
  getPaymentEvidenceSrc(state) {
    return state.paymentEvidenceSrc;
  },
  getAppointmentTypeAction(state) {
    return state.appointmentTypeAction;
  },
  getPatientSelected(state) {
    return state.patientSelected;
  },
  getFolderOption(state) {
    return state.folderOption;
  },
  getFolderComponents(state) {
    return state.folderComponents;
  },
  getOccupations(state) {
    return state.occupations;
  },
  getOccupationsPartner(state) {
    return state.occupationsPartner;
  },
  getViewMode(state) {
    return state.viewMode;
  },
  getOwe(state) {
    return state.owe;
  },
  getPlanName(state) {
    return state.planName;
  },
  getHasPartner(state) {
    return state.hasPartner;
  },
  getCompleteModeBanner(state) {
    return state.completeModeBanner;
  },
  getHidenSecondaryComponent(state) {
    return state.hidenSecondaryComponent;
  },
};
