<template>
  <div class="wrapperHome">
    <!-- Sidebar -->

    <Sidebar />
    <BMenu />

    <!-- Page content -->
    <div class="wrapperHome__page">
      <HeaderSection />

      <div class="wrapperHome__page--content">
        <transition name="scale" mode="out-in">
          <router-view></router-view>
        </transition>
        <footer class="wrapperHome__page--footer mt-4">
          <p>
            {{ `Versión: ${getAppVersion} Último Deploy: ${getLastUpdate}` }}
          </p>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderSection from "@/components/shared/Header.vue";
import Sidebar from "@/components/shared/Sidebar.vue";

import BMenu from "@/components/shared/BurgerMenu.vue";

export default {
  name: "MainMenu",
  components: {
    HeaderSection,
    Sidebar,
    BMenu,
  },
  computed: {
    getAppVersion() {
      return process.env.VUE_APP_VERSION;
    },
    getLastUpdate() {
      return process.env.VUE_APP_LAST_DEPLOY;
    },
  },
};
</script>
