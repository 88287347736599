var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fields",class:{ 'pt-1': !_vm.typeAction }},[(_vm.typeAction)?_c('div',{staticClass:"titles"},[_vm._v("Datos de Pareja")]):_vm._e(),_c('div',[_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"form-group field-100"},[_c('label',{attrs:{"for":"partner_name"}},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_name),expression:"couple.partner_name"}],staticClass:"field-100",attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction,"type":"text","placeholder":"Nombres"},domProps:{"value":(_vm.couple.partner_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.couple, "partner_name", $event.target.value)}}})])]),_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"form-group field-100"},[_c('label',{attrs:{"for":"partner_last_name"}},[_vm._v("Apellidos")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_last_name),expression:"couple.partner_last_name"}],staticClass:"field-100",attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction,"type":"text","placeholder":"Apellidos"},domProps:{"value":(_vm.couple.partner_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.couple, "partner_last_name", $event.target.value)}}})])]),_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"d-50 me-3"},[_c('div',{staticClass:"form-group field-100"},[_c('label',{attrs:{"for":"partner_date_birth"}},[_vm._v("Nacimiento")]),_c('date-picker',{staticClass:"field-100",attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction,"format":'DD/MM/YYYY',"placeholder":"Nacimiento","disabled-date":function (date) { return date >= _vm.$moment().subtract(18, 'years'); },"default-value":_vm.$moment().subtract(18, 'years')},on:{"change":function($event){_vm.couple.partner_age = _vm.$moment().diff(
                _vm.couple.partner_date_birth,
                'years',
                false
              )}},model:{value:(_vm.couple.partner_date_birth),callback:function ($$v) {_vm.$set(_vm.couple, "partner_date_birth", $$v)},expression:"couple.partner_date_birth"}})],1)]),_c('div',{staticClass:"d-50 ms-3"},[_c('div',{staticClass:"form-group field-100"},[_c('label',{attrs:{"for":"partner_age"}},[_vm._v("Edad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_age),expression:"couple.partner_age"}],staticClass:"field-100",attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction,"type":"number","placeholder":"Edad"},domProps:{"value":(_vm.couple.partner_age)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.couple, "partner_age", $event.target.value)}}})])])]),_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"gender"},[_c('label',{staticClass:"me-3 d-block"},[_vm._v(" Sexo ")]),_c('span',{staticClass:"me-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_sex),expression:"couple.partner_sex"}],attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction,"type":"radio","name":"sex","value":"male"},domProps:{"checked":_vm._q(_vm.couple.partner_sex,"male")},on:{"change":function($event){return _vm.$set(_vm.couple, "partner_sex", "male")}}}),_vm._v(" M")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_sex),expression:"couple.partner_sex"}],attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction,"type":"radio","name":"sex","value":"female"},domProps:{"checked":_vm._q(_vm.couple.partner_sex,"female")},on:{"change":function($event){return _vm.$set(_vm.couple, "partner_sex", "female")}}}),_vm._v(" F ")])]),_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"form-group field-100"},[_c('label',{staticClass:"me-3 d-block",class:{
            error:
              (_vm.$v.couple.partner_identification.$error &&
                !_vm.$v.couple.partner_identification.required) ||
              (_vm.$v.couple.partner_identification_image.$error &&
                !_vm.$v.couple.partner_identification_image.required),
          }},[_c('span',{staticClass:"blueCitmer"},[_vm._v("*")]),_vm._v("Identificación ")]),_c('span',{staticClass:"fieldInputsFiliation"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_identification),expression:"couple.partner_identification"}],staticClass:"field-100",attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.couple, "partner_identification", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":"","hidden":"","value":""}},[_vm._v("Identificación")]),_vm._l((_vm.identifications),function(identification){return _c('option',{key:((identification.key) + "-" + (identification.name)),domProps:{"value":identification.key}},[_vm._v(" "+_vm._s(identification.name)+" ")])})],2)]),(
            !_vm.validateIDEAction &&
            _vm.isPreviewIDEActive &&
            (_vm.filiationInfo
              ? _vm.filiationInfo.partner_identification_image
                ? _vm.filiationInfo.partner_identification_image.length > 0
                : false
              : false)
          )?_c('PreviewFile',{staticClass:"mt-3",attrs:{"fileType":_vm.filiationInfo
              ? _vm.filiationInfo.partner_identification_image
                ? _vm.filiationInfo.partner_identification_image.length > 0
                  ? _vm.filiationInfo.partner_identification_image[0]
                      .file_type === 'file'
                    ? 'pdf'
                    : 'img'
                  : 'empty'
                : 'empty'
              : 'empty',"multiple":"","collapseName":'previewIDE',"isEditActive":_vm.isEditActive,"previewFile":_vm.filiationInfo.partner_identification_image},on:{"changeUploadedFile":_vm.changeIDE,"deleteUploadedFile":_vm.deleteIdentificationImages}}):_vm._e(),(
            _vm.couple.partner_identification !== 'present_on_clinic' &&
            _vm.couple.partner_identification !== 'not_apply' &&
            _vm.couple.partner_identification !== '' &&
            (_vm.filiationInfo
              ? _vm.filiationInfo.partner_identification_image
                ? _vm.filiationInfo.partner_identification_image.length === 0
                : true
              : true)
          )?_c('UploadContent',{ref:"uploadContentID",attrs:{"multiple":true},on:{"changeFiles":_vm.changeFilesID,"removeFiles":_vm.removeSecondOpinionDocID}}):_vm._e()],1),(
          (_vm.$v.couple.partner_identification.$error &&
            !_vm.$v.couple.partner_identification.required) ||
          (_vm.$v.couple.partner_identification_image.$error &&
            !_vm.$v.couple.partner_identification_image.required)
        )?_c('div',{staticClass:"error ms-3"},[_vm._v(" El campo es requisito ")]):_vm._e()]),_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"form-group field-100"},[_c('label',{class:{ error: _vm.$v.couple.partner_occupation_id.$error }},[_c('span',{staticClass:"blueCitmer"},[_vm._v("*")]),_vm._v("Ocupación ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_occupation_id),expression:"couple.partner_occupation_id"}],staticClass:"field-100",attrs:{"disabled":!_vm.isEditActive && !_vm.typeAction},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.couple, "partner_occupation_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":"","hidden":"","value":""}},[_vm._v("Ocupación")]),_vm._l((_vm.getOccupationsPartner),function(occupation){return _c('option',{key:((occupation.id) + "-" + (occupation.name)),domProps:{"value":occupation.id}},[_vm._v(" "+_vm._s(_vm.occupationNameByUserType(occupation))+" ")])})],2)]),(
          _vm.$v.couple.partner_occupation_id.$error &&
          !_vm.$v.couple.partner_occupation_id.required
        )?_c('div',{staticClass:"error ms-3"},[_vm._v(" El campo es requisito ")]):_vm._e()]),(_vm.couple.partner_occupation_id === 57)?_c('div',{staticClass:"d-100"},[_c('div',{staticClass:"form-group field-100",class:{ error: _vm.$v.couple.partner_occupation_other.$error }},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couple.partner_occupation_other),expression:"couple.partner_occupation_other"}],staticClass:"field-100",attrs:{"type":"text","disabled":!_vm.isEditActive && !_vm.typeAction},domProps:{"value":(_vm.couple.partner_occupation_other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.couple, "partner_occupation_other", $event.target.value)}}})]),(
          _vm.$v.couple.partner_occupation_other.$error &&
          !_vm.$v.couple.partner_occupation_other.required
        )?_c('div',{staticClass:"error ms-3"},[_vm._v(" El campo es requisito ")]):_vm._e()]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"partner_occupation_other"}},[_c('span',{staticClass:"blueCitmer"},[_vm._v("*")]),_vm._v("Otra Ocupación")])}]

export { render, staticRenderFns }