import store from "@/store";

export default {
  getClinic(state) {
    if (!store.getters.getIsLogged) return [];
    if (!state.clinic_selected) {
      const [profileClinics] = JSON.parse(
        localStorage.getItem("user-profile")
      )?.clinics_ids;
      if (profileClinics) {
        if (Array.isArray(profileClinics)) {
          store.dispatch("updateClinic", profileClinics[0]);
        } else {
          store.dispatch("updateClinic", profileClinics);
        }
      }
    }
    return state.clinic_selected;
  },
  getClinicList(state) {
    return state.clinics;
  },
};
