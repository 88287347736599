export default {
  setMedic(state, medic = "") {
    if (!medic) state.medic_selected = "";
    state.medic_selected = medic;
    if (medic) {
      localStorage.setItem("medic_selected", JSON.stringify(medic));
    } else {
      localStorage.removeItem("medic_selected");
    }
  },
  setMedicList(state, medics = null) {
    if (!medics) state.medics = [];
    state.medics = medics;
    if (medics) {
      localStorage.setItem("medics", JSON.stringify(medics));
    } else {
      localStorage.removeItem("medics");
    }
  },
};
