const sdaOpinionList = [
  "Ingenes",
  "Biofert",
  "Nascere",
  "Fertygen",
  "IECH",
  "Clínica Vida fértil",
  "Procrea",
  "New Hope Fertility Center",
  "Inmater",
  "Instituto Vida",
  "FertilT",
  "Eligen",
  "Fertyplace",
  "CEUMER Centro Universitario de Medicina Reproductiva UANL",
  "Otro",
];

const appointmentHours = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
];

const medicsFields = [
  { class: "text-center", key: "name", label: "Nombre del Médico" },
  { class: "text-center", key: "user_type", label: "Tipo de médico" },
  { class: "text-center", key: "actions", label: "Acciones" },
];

const patientsFields = [
  { class: "text-center", key: "nec", label: "NEC" },
  { class: "text-center", key: "name", label: "Nombre" },
  { class: "text-center", key: "contact_tel", label: "Telefono" },
  { class: "text-center", key: "patient_type", label: "Tipo" },
  { class: "text-center", key: "actions", label: "Acciones" },
];

const patientsFieldsEdit = [
  { class: "text-center", key: "nec", label: "NEC" },
  { class: "text-center", key: "name", label: "Nombre" },
  { class: "text-center", key: "patient_type", label: "Tipo" },
  { class: "text-center", key: "contact_tel", label: "Telefono" },
];

const commentsFields = [
  { class: "text-start", key: "comments", label: "Comentarios" },
];

const previewPatientsFields = [
  { class: "text-center", key: "nec", label: "NEC" },
  { class: "text-center", key: "name", label: "Nombre" },
  { class: "text-center", key: "contact_tel", label: "Telefono" },
];

const servicesFields = [
  { class: "text-center", key: "name", label: "Servicio" },
  { class: "text-center", key: "duration", label: "Duración" },
  { class: "text-center", key: "cost", label: "Costo" },
  { class: "text-center", key: "actions", label: "" },
];

const componentsFiliation = ["Patient", "Couple", "Address", "Contact"];

const identifications = [
  { key: "ine", name: "INE" },
  { key: "passport", name: "Pasaporte" },
  { key: "driver_license", name: "Licencia de conducir" },
  { key: "present_on_clinic", name: "Presenta en clínica" },
  { key: "not_apply", name: "No aplica" },
];

const contactTypes = [
  { key: "facebook", name: "Facebook" },
  { key: "google", name: "Google" },
  { key: "instagram", name: "Instagram" },
  { key: "internet", name: "Internet" },
  { key: "go_straight_to_the_clinic", name: "Llega directo a clínica" },
  { key: "newspaper", name: "Medios impresos (periódico)" },
  { key: "radio", name: "Radio" },
  { key: "social_media", name: "Redes Sociales" },
  { key: "employee_reference", name: "Referencia Empleado" },
  { key: "medical_reference", name: "Referencia Médica" },
  { key: "patient_reference", name: "Referencia Paciente Citmer" },
  { key: "tv", name: "TV" },
  { key: "whatsapp", name: "WhatsApp" },
];

const clinicsNames = [
  { id: 1, name: "Monterrey" },
  { id: 2, name: "Puebla" },
  { id: 3, name: "Ciudad de México" },
];

export {
  sdaOpinionList,
  appointmentHours,
  patientsFields,
  previewPatientsFields,
  servicesFields,
  componentsFiliation,
  identifications,
  contactTypes,
  clinicsNames,
  commentsFields,
  medicsFields,
  patientsFieldsEdit,
};
