import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "@/helpers/auth";
import Login from "@/views/Login.vue";
import Calendar from "@/views/Calendar.vue";
import * as childsRoutes from "./childrenRoutes";

Vue.use(Router);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      beforeEnter: authGuard,
      component: Login,
    },
    {
      path: "/usuarios",
      name: "users",
      beforeEnter: authGuard,
      component: loadView("Users"),
    },
    {
      path: "/calendar",
      name: "calendar",
      beforeEnter: authGuard,
      component: Calendar,
    },
    {
      path: "/agenda",
      name: "agenda",
      beforeEnter: authGuard,
      component: Calendar,
    },
    {
      path: "/metricas",
      name: "metrics",
      beforeEnter: authGuard,
      component: loadView("Metrics"),
    },
    {
      path: "/filiaciones",
      name: "filiations",
      beforeEnter: authGuard,
      component: loadView("patients/Main"),
    },
    {
      path: "/pacientes",
      name: "patients",
      beforeEnter: authGuard,
      component: loadView("patients/Main"),
      children: childsRoutes.patients,
    },
    {
      path: "/laboratorios",
      name: "laboratories",
      beforeEnter: authGuard,
      component: loadView("Laboratory"),
    },
    {
      path: "/historial_citas",
      name: "historyappointments",
      beforeEnter: authGuard,
      component: loadView("HistoryAppointments"),
    },
    {
      path: "/migrations",
      name: "migrations",
      beforeEnter: authGuard,
      component: loadView("Migrations"),
    },
    {
      path: "/settings",
      name: "settings",
      beforeEnter: authGuard,
      component: loadView("Settings"),
    },
    {
      path: "/quirofano",
      name: "surgery",
      beforeEnter: authGuard,
      component: loadView("patients/Surgery"),
    },
    {
      path: "*",
      name: "NotFound",
      redirect: "login",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Si hay una posición de desplazamiento guardada, usamos esa
      return savedPosition;
    }
    // Si no, regresamos al inicio de la página
    return { x: 0, y: 0 };
  },
});
