<template>
  <div class="search row justify-content-start">
    <div
      class="
        d-flex
        mb-3
        justify-content-start
        search__input
        col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9
      ">
      <button
        type="button"
        class="btn filterSearch w-auto nameOption"
        :class="[filter == 'name' ? 'filterSearch__active' : '']"
        @click="filter = 'name'">
        <font-awesome-icon :icon="['fas', 'search']" />
        <span class="ms-3">Nombre</span>
      </button>
      <button
        type="button"
        v-if="necRequired"
        class="btn filterSearch filterSearch__inactive w-auto necOption"
        :class="[filter == 'NEC' ? 'filterSearch__active' : '']"
        @click="filter = 'NEC'">
        <font-awesome-icon :icon="['fas', 'search']" /><span class="ms-3"
          >NEC</span
        >
      </button>
      <input
        v-model="search"
        v-on:keydown.enter.prevent="goSearch"
        class="form-control inputsGeneralClass"
        type="text"
        :placeholder="placeHolder" />
      <button
        type="button"
        class="btn btnSearch searchUserIcon"
        @click="goSearch">
        <font-awesome-icon
          class="svg-inline--fa fa-search fa-w-16"
          :icon="['fas', 'search']" />
        <template v-if="inputLocation">
          <span>Buscar</span>
        </template>
      </button>
    </div>
    <div
      class="
        d-flex
        mb-3
        justify-content-end
        col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3
      "
      v-if="inputDate">
      <date-picker
        v-model="date"
        :format="'DD/MM/YYYY'"
        :input-class="'form-control inputsGeneralClass w-100'"
        placeholder="Seleccione un rango de fecha"
        @change="goSearch"
        range />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "SearchComponent",
  components: {
    DatePicker,
  },
  props: {
    flexDirection: {
      type: String,
      required: false,
      default: () => "flex-end",
    },
    labelPatient: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputLocation: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchAction: {
      type: Boolean,
      required: false,
      default: true,
    },
    necRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    infoTypeBoth: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      date: [new Date(), new Date()],
      filter: "name",
      search: "",
      payload: {},
    };
  },
  watch: {
    searchAction() {
      if (this.autoSearch) {
        this.goSearch();
      }
    },
    getClinic() {
      if (this.autoSearch) {
        this.goSearch();
      }
    },
  },
  mounted() {
    if (this.autoSearch) {
      this.goSearch();
    }
    if (this.necRequired) {
      this.filter = "NEC";
    }
  },
  computed: {
    ...mapGetters(["getClinic"]),
    placeHolder() {
      return `Busqueda por ${this.filter === "name" ? "Nombre" : this.filter}`;
    },
  },
  methods: {
    ...mapActions(["removeAlertByKey"]),
    goSearch() {
      const payload = {
        search_type: this.filter,
        search: this.search,
      };
      if (this.inputDate) {
        [payload.date_ini] = this.date;
        [, payload.date_end] = this.date;
      }
      payload.infoType = this.necRequired ? "patients" : "filiations";
      if (this.infoTypeBoth) payload.infoType = "both";
      this.payload = payload;
      this.$emit("searchData", payload);
    },
  },
};
</script>
