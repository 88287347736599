<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <div class="row">
        <div class="col mx-3">
          <b-button
            block
            class="w-100"
            v-b-toggle="collapseName"
            variant="secondary"
            >Mostrar Documento</b-button
          >
        </div>
        <div class="col mx-3" v-if="isEditActive">
          <b-button
            class="w-100"
            @click="confirmRemoveFile"
            block
            variant="danger"
            >Eliminar documentos</b-button
          >
        </div>
      </div>
    </b-card-header>
    <b-collapse
      v-model="isVisible"
      :id="collapseName"
      :accordion="collapseName"
      role="tabpanel">
      <b-card-body>
        <div class="row">
          <div class="col-1 text-start my-auto" v-if="previewFile.length > 1">
            <button
              type="button"
              @click="index -= 1"
              :disabled="index == 0"
              class="btn evidenceArrowBtn">
              <font-awesome-icon
                class="tableIcon pointer"
                :icon="['fa', 'chevron-left']" />
            </button>
          </div>
          <div class="d-flex justify-content-center col">
            <transition name="scale" mode="out-in">
              <template v-if="previewFile[index].file_type === 'image'">
                <img
                  :src="previewFile ? previewFile[index].url : ''"
                  alt="evidenceClaims"
                  class="img-fluid" />
              </template>
              <template v-else>
                <embed
                  :src="previewFile ? previewFile[index].url : ''"
                  width="100%"
                  height="650"
                  type="application/pdf" />
              </template>
            </transition>
          </div>
          <div
            class="col-1 text-end align-middle my-auto"
            v-if="previewFile.length > 1">
            <button
              type="button"
              @click="index += 1"
              :disabled="index >= previewFile.length - 1"
              class="btn evidenceArrowBtn">
              <font-awesome-icon
                class="tableIcon pointer"
                :icon="['fa', 'chevron-right']" />
            </button>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PreviewFile",
  props: {
    collapseName: {
      type: String,
    },
    fileType: {
      type: String,
    },
    isEditActive: {
      type: Boolean,
    },
    previewFile: {
      required: true,
    },
  },
  data() {
    return {
      index: 0,
      isVisible: false,
    };
  },
  methods: {
    ...mapActions(["updateConfirmData"]),
    confirmRemoveFile() {
      this.updateConfirmData({
        title: "Cambiar o eliminar archivo",
        description:
          "¿Esta seguro de que desea cambiar o eliminar los archivos, se eliminaran directamente?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        confirmFunction: this.changeUploadedFile,
      });
    },
    changeUploadedFile() {
      this.isVisible = false;
      this.$emit("deleteUploadedFile");
    },
  },
};
</script>

<style>
.embedFile {
  width: 100%;
  height: auto !important;
}
</style>
