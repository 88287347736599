/**
 * filiationAppointmentsMixin
 * * Mixin para los form components de crear una filiacion.
 */
const filiationAppointmentsMixin = {
  props: {
    filiationInfo: {
      type: Object,
    },
    isEditActive: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // To know if is "create filiation"(true) or "preview edit"(false) option.
    typeAction: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  methods: {
    isFormDataValidByComponentName() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    isFormDataValid(componentPosition) {
      const activeComponent =
        this.$parent.$parent.$parent.$children[0].$children[0].$children[
          this.$parent.$parent.$parent.step + componentPosition
        ];
      activeComponent.$v.$reset();
      activeComponent.$v.$touch();
      return !activeComponent.$v.$error;
    },
    saveUpdateFiliation() {
      if (!this.isFormDataValidByComponentName()) return;
      const componentFormKey =
        this.$parent.$parent.$parent.componentByStep.toLowerCase();
      const formInfoActiveComponent = this[componentFormKey];
      const payload = this.getFormData(formInfoActiveComponent.data());
      payload.append("form_type", componentFormKey);
      if (Array.isArray(formInfoActiveComponent.second_opinion_doc)) {
        if (formInfoActiveComponent.second_opinion_doc.length > 0) {
          formInfoActiveComponent.second_opinion_doc.forEach(
            (secondOpinion) => {
              payload.append("second_opinion_doc[]", secondOpinion);
            }
          );
        }
      }
      payload.delete("second_opinion_doc");

      if (Array.isArray(formInfoActiveComponent.identification_image)) {
        if (formInfoActiveComponent.identification_image.length > 0) {
          formInfoActiveComponent.identification_image.forEach(
            (identification) => {
              payload.append("identification_image[]", identification);
            }
          );
        }
      }
      payload.delete("identification_image");

      if (Array.isArray(formInfoActiveComponent.partner_identification_image)) {
        if (formInfoActiveComponent.partner_identification_image.length > 0) {
          formInfoActiveComponent.partner_identification_image.forEach(
            (identification) => {
              payload.append("partner_identification_image[]", identification);
            }
          );
        }
      }
      payload.delete("partner_identification_image");
      this.$api.appointments
        .updateFiliation(payload, this.filiationInfo.id)
        .then(() => {
          if (componentFormKey === "patient") {
            this.isPreviewIDEActive = true;
            this.isPreviewSecondOpinionActive = true;
          }
          if (componentFormKey === "couple") {
            this.isPreviewIDEActive = true;
          }
          this.$parent.$parent.$parent.isEditFiliationActive = false;
          this.$parent.$parent.$parent.loadFiliationInfo(this.filiationInfo.id);
          this.$parent.$parent.$parent.$emit("refreshFiliationList");
        });
    },
    updateFormInfo(editInfo) {
      const activeComponent =
        this.$parent.$parent.$parent.$refs[
          this.$parent.$parent.$parent.componentByStep
        ];
      const componentFormKey =
        this.$parent.$parent.$parent.componentByStep.toLowerCase();
      activeComponent[componentFormKey].update(editInfo);
    },
    validateData(componentPosition) {
      const activeComponent =
        this.$parent.$parent.$parent.$children[0].$children[0].$children[
          this.$parent.$parent.$parent.step + componentPosition
        ];
      const componentFormKey =
        this.$parent.$parent.$parent.componentByStep.toLowerCase();
      if (this.isFormDataValid(componentPosition)) {
        this.$emit("validateForm", activeComponent[componentFormKey]);
        return true;
      }
      return false;
    },
  },
};

export default filiationAppointmentsMixin;
