export default {
  updateLoaderCountAndMsg({ commit }, payload) {
    commit("setLoaderMessage", payload.message);
    commit("setLoaderCount", payload.count);
  },
  updateLoaderCount({ commit }, count) {
    commit("setLoaderCount", count);
  },
  updateLoaderMsgWTimeout({ commit }, message) {
    commit("setLoaderMessageWTimeout", message);
  },
};
