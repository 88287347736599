import auth from "./auth";
import userProfile from "./user_profile";
import loader from "./loader";
import alerts from "./alerts";
import patient from "./patient";
import clinic from "./clinic";
import medic from "./medic";
import appointment from "./appointment";

export default {
  auth,
  loader,
  userProfile,
  alerts,
  patient,
  clinic,
  medic,
  appointment,
};
