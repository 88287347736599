export default {
  calendarData: null,
  calendarConfig: null,
  calendarFilter: {
    medic_id: "all",
    schedule_id: "all",
    medicList: [],
  },
  // State "create" or "edit" appointment
  ceAppointmentState: "create",
  // ID of appointment edit
  ceAppointmentEditID: null,
  // State "create" or "edit" filiation
  ceFiliationState: "create",
  filiationInfo: null,
  filiationState: null,
  appointmentEditID: null,
  selectedPatientID: null,
  selectedPatientNextAppointment: null,
  isCreateExternalPatient: false,
  isDonorPatient: false,
  infoAppointmentPayment: null,
};
