import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      medicListAppointment: [],
    };
  },
  watch: {
    clinic_id() {
      if (this.parentComponent === "CalendarFilter") {
        this.$api.calendar
          .getConfig({ clinic_id: this.getClinic })
          .then((res) => {
            this.updateCalendarConfig(res.data);
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getMedic",
      "getClinic",
      "getMedicList",
      "getClinicList",
      "getUserType",
    ]),
    parentComponent() {
      // eslint-disable-next-line no-underscore-dangle
      return this.$options._componentTag;
    },
    clinic_id: {
      get() {
        return this.getClinic;
      },
      set(value) {
        this.updateClinic(value);
      },
    },
    medic_id: {
      get() {
        return this.getMedic;
      },
      set(value) {
        this.updateMedic(value);
      },
    },
    medicList: {
      get() {
        return this.medicListAppointment;
      },
      set(value) {
        this.medicListAppointment = value;
      },
    },
  },
  methods: {
    ...mapActions([
      "updateMedic",
      "updateClinic",
      "updateAlerts",
      "updateMedicList",
      "updateCalendarConfig",
    ]),
    updateMedicListOnMounted(info, agendaType = null) {
      if (
        this.isMedicUser ||
        this.getUserType === "nutrition" ||
        this.getUserType === "psychology" ||
        this.getUserType === "abc" ||
        this.getUserType === "manager_abc"
      )
        return;
      if (!this.isCEAppointmentCreate) return;
      const payload = this.generatePayload(info, agendaType);
      this.$api.appointments.getAvailableMedics(payload).then((res) => {
        if (res.data.medics.length === 0) {
          this.updateAlerts({
            status: "warning",
            content: "No hay médicos disponibles.",
          });
        }
        this.medicList = res.data.medics;
        if (this.parentComponent === "LocationAndDate") {
          if (
            Object.keys(this.medicBeforeEdit).length > 0 &&
            !this.isCEAppointmentCreate
          ) {
            this.medicList.push(this.medicBeforeEdit);
          }
        }
      });
    },
    fillMedicList(isSelect = true) {
      if (!this.isCEAppointmentCreate && this.isMedicSelected && isSelect) {
        return;
      }
      const payload = this.generatePayload(
        null,
        this.getUserType === "nursing" ? "both" : null
      );
      this.$api.appointments.getAvailableMedics(payload).then((res) => {
        this.medicList = res.data.medics;
        if (res.data.medics.length === 0) {
          this.medic_id = null;
          this.updateAlerts({
            status: "warning",
            content: "No hay médicos disponibles.",
          });
        } else {
          if (
            this.isSameFilterBeforeEdit &&
            Object.keys(this.medicBeforeEdit).length > 0
          ) {
            this.medicListAppointment.push(this.medicBeforeEdit);
          }
          const isMedicEditOnList = this.medicList.findIndex(
            (med) => med.id === this.medic_id
          );
          if (isMedicEditOnList > 0) {
            this.medic_id = res.data.medics[isMedicEditOnList].id;
          } else {
            this.medic_id = res.data.medics[0].id;
          }
        }
      });
    },
    reloadMedicList() {
      this.updateMedicList();
      this.medic_id = "";
      const payload = {
        clinic_id: this.clinic_id,
      };
      if (this.getUserType === "nursing" || this.getUserType === "reception") {
        payload.agenda_type = "both";
      }
      this.$api.settings.getMedicsByClinic(payload).then((res) => {
        this.updateMedicList(res.data.medics);
        this.updateMedic(res.data.medics[0]?.id);
      });
    },
  },
};
