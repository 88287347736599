import Vue from "vue";
import VueI18n from "vue-i18n";
// TO - DO: if you want to add a new language set it by vuex
// import store from '@/store'

Vue.use(VueI18n);

function loadLocaleMessages() {
  if (typeof require.context === "undefined") {
    const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  }
  return false;
}

export default new VueI18n({
  // TO - DO: if you want to add a new language set it by vuex
  // locale: store.getters.getCurrentLanguage,
  locale: "es",
  fallbackLocale: "es",
  messages: loadLocaleMessages(),
});
