const menuOptions = [
  {
    title: "Agenda",
    logoSource: "far",
    logo: "calendar",
    route: "agenda",
  },
  {
    title: "Calendario",
    logoSource: "far",
    logo: "calendar",
    route: "calendar",
  },
  {
    title: "Citas",
    logoSource: "fa",
    logo: "folder-plus",
    route: "historyappointments",
  },
  {
    title: "Usuarios",
    logoSource: "fa",
    logo: "users",
    route: "users",
  },
  {
    title: "Pacientes",
    logoSource: "far",
    logo: "user",
    route: "patients",
  },
  {
    title: "Quirófano",
    logoSource: "fas",
    logo: "file-waveform",
    route: "surgery",
  },
  {
    title: "Métricas",
    logoSource: "fa",
    logo: "chart-bar",
    route: "metrics",
  },
  {
    title: "Filiaciones",
    logoSource: "far",
    logo: "user",
    route: "filiations",
  },
  {
    title: "Laboratorios",
    logoSource: "fas",
    logo: "microscope",
    route: "laboratories",
  },
  {
    title: "Migraciones",
    logoSource: "fa",
    logo: "file-export",
    route: "migrations",
  },
  {
    title: "Configuración",
    logoSource: "fa",
    logo: "cog",
    route: "settings",
  },
  {
    title: "Salir",
    logoSource: "fa",
    logo: "sign-out-alt",
    route: "logOut",
  },
];

export default menuOptions;
