<template>
  <div id="app" class="h-100">
    <div>
      <template v-if="$route.name != 'login'">
        <MainMenu></MainMenu>
      </template>
      <template v-else>
        <router-view />
      </template>
      <loading-states v-if="$store.getters.getLoaderCount > 0"></loading-states>
    </div>
    <AlertsContainer></AlertsContainer>
    <ConfirmationModal />
    <EditPassword />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditPassword from "@/components/shared/EditPassword.vue";
import refreshPageMixin from "@/mixin/automaticRefresh.mixin";
import AlertsContainer from "@/components/shared/AlertsContainer.vue";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import MainMenu from "./components/shared/MainMenu.vue";
import LoadingStates from "./components/shared/Loading.vue";

import "vue-select/dist/vue-select.css";

require("@/assets/scss/main.scss");

export default {
  name: "App",
  components: {
    AlertsContainer,
    EditPassword,
    MainMenu,
    LoadingStates,
    ConfirmationModal,
  },
  mixins: [refreshPageMixin],
  watch: {
    hashChanged(newVal) {
      if (newVal) {
        this.updateConfirmData({
          title: "Actualización",
          description:
            "Una actualización esta disponible. Por favor actualice la aplicación. También puedes aceptar esta actualización refrescando el navegador(ctrl+shift+r o cmd+shift+r).",
          params: null,
          okText: "Recargar",
          cancelText: "Cancelar",
          confirmFunction: this.reloadApp,
        });
      }
    },
    getUserPasswordChanged(newValue) {
      if (this.getIsLogged) {
        if (!newValue) {
          this.$bvModal.show("EditPassword");
        }
      }
    },
  },
  mounted() {
    if (this.getAppVersion !== "DEV") {
      this.initVersionCheck(`${location.origin}/version.json`);
      this.checkVersion(`${location.origin}/version.json`);
    }
    this.loadClinics();
    if (this.getIsLogged) {
      if (!this.getUserPasswordChanged) {
        this.$bvModal.show("EditPassword");
      }
    }
  },
  computed: {
    ...mapGetters(["getUserPasswordChanged", "getIsLogged"]),
    getAppVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    ...mapActions(["updateConfirmData"]),
    loadClinics() {
      const tokens = this.$store.getters.getLoginTokens;
      if (!tokens) return;
      this.$api.settings.getClinics().then((res) => {
        this.$store.dispatch("updateClinicList", res.data.clinics);
      });
    },
  },
};
</script>
