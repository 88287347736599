<template>
  <div class="fields" :class="{ 'pt-1': !typeAction }">
    <div class="titles" v-if="typeAction">Contacto</div>
    <div>
      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{
            error:
              $v.contact.contact_tel.$error && $v.contact.contact_tel.$dirty,
          }">
          <label for="contact_tel">
            <span class="blueCitmer">*</span>No. teléfono personal
          </label>
          <div class="d-flex align-items-center">
            <vue-country-code
              ref="countryCode"
              @onSelect="onSelectCountry"
              :disabled="!isEditActive && !typeAction"
              defaultCountry="MX"
              class="countryCode"></vue-country-code>
            <input
              @keypress="isNumber($event)"
              :disabled="!isEditActive && !typeAction"
              v-model="contact.contact_tel"
              @input="updateValue"
              class="field-100"
              type="tel" />
          </div>
        </div>
        <div
          class="error ms-3"
          v-if="$v.contact.contact_tel.$error && $v.contact.contact_tel.$dirty">
          El campo es requisito
        </div>
      </div>
      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{ error: $v.contact.contact_email.$error }">
          <label for="contact_tel"> @correo </label>
          <input
            :disabled="!isEditActive && !typeAction"
            v-model="contact.contact_email"
            class="field-100"
            type="email" />
        </div>
      </div>
      <div class="d-100">
        <div class="form-group field-100">
          <label>Pareja No. teléfono personal </label>
          <div class="d-flex align-items-center">
            <vue-country-code
              ref="countryCodePartner"
              @onSelect="onSelectCountryPartner"
              :disabled="!isEditActive && !typeAction"
              defaultCountry="MX"
              class="countryCode"></vue-country-code>
            <input
              @keypress="isNumber($event)"
              :disabled="!isEditActive && !typeAction"
              v-model="contact.contact_tel_partner"
              @input="updateValuePartner"
              class="field-100"
              type="tel" />
          </div>
        </div>
      </div>
      <div class="d-100">
        <div class="form-group field-100">
          <label for="email">Pareja @correo</label>
          <input
            v-model="contact.contact_email_partner"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="email" />
        </div>
      </div>

      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{
            error:
              $v.contact.contact_type.$error && $v.contact.contact_type.$dirty,
          }">
          <label for="contact_type">
            <span class="blueCitmer">*</span>Medio por el que nos contacto
          </label>
          <select
            :disabled="!isEditActive && !typeAction"
            v-model="$v.contact.contact_type.$model"
            class="field-100">
            <option selected disabled hidden value="">Tipo</option>
            <option
              v-for="contactType in contactTypes"
              :key="`${contactType.key}-${contactType.name}`"
              :value="contactType.key">
              {{ contactType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-100" v-if="contactType === 'go_straight_to_the_clinic'">
        <div class="form-group field-100">
          <label for="contact_medium">
            <span class="blueCitmer">*</span>Medio por el que nos conoce
          </label>
          <input
            v-model="$v.contact.contact_medium.$model"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text" />
        </div>
      </div>

      <div class="d-100" v-if="contactType === 'employee_reference'">
        <div
          class="form-group field-100"
          :class="{ error: $v.contact.employee_reference_name.$error }">
          <label for="employee_reference_name">
            <span class="blueCitmer">*</span>Nombre del Colaborador que lo
            refiere.
          </label>
          <input
            v-model="$v.contact.employee_reference_name.$model"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text" />
        </div>
        <div
          class="error ms-3"
          v-if="
            $v.contact.employee_reference_name.$error &&
            !$v.contact.employee_reference_name.required
          ">
          El campo es requisito
        </div>
      </div>

      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{ error: $v.contact.comments.$error }">
          <label class="d-block" for="comments">
            <span class="blueCitmer">*</span>Comentarios del Px Previo a su Cita
          </label>
          <textarea
            v-model="contact.comments"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            cols="30"
            rows="10"
            placeholder="Comentarios"></textarea>
        </div>
        <div
          class="error ms-3"
          v-if="$v.contact.comments.$error && !$v.contact.comments.required">
          El campo es requisito
        </div>
      </div>
      <div class="d-100" v-if="typeAction">
        <div class="form-group field-100">
          <label
            class="me-3 d-block"
            :class="{ error: $v.contact.paidOut.$error }">
            <span class="blueCitmer">*</span>¿Realizo su pago?
          </label>
          <span class="fieldInputsFiliation">
            <!-- PAID OUT SELECT TYPE -->
            <select v-model="$v.contact.paidOut.$model" class="field-100">
              <option
                v-for="option in paidOutOptionsFilter"
                :value="option.value"
                :selected="option.selected"
                :key="option.value">
                {{ option.label }}
              </option>
            </select>
            <!-- PAID OUT SELECT TYPE -->
            <!-- PAID OUT BUTTON ADD FILE -->
            <!-- PAID OUT BUTTON REMOVE FILE /-->
            <UploadContent
              v-if="contact.paidOut && contact.paidOut === 'yes'"
              ref="uploadContentPaid"
              @changeFiles="onFileChange"
              @removeFiles="removeImage"
              :multiple="true" />
          </span>
          <!-- PAID OUT INPUT FILE /-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */
import { contactTypes } from "@/constants/appointments";
import filiationAppointmentsMixin from "@/mixin/filiationAppointments.mixin";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import Form from "@/helpers/form";
import { mapGetters } from "vuex";

export default {
  name: "ContactData",
  mixins: [filiationAppointmentsMixin],
  components: {
    UploadContent: () =>
      import("@/components/patient/filiation/modals/UploadContent.vue"),
  },
  data() {
    return {
      paidOutOptions: [
        {
          value: "second_opinion",
          label: "Segunda opinión( sin costo )",
          selected: true,
        },
        {
          value: "no",
          label: "No, pagará en clínica",
          selected: true,
        },
        {
          value: "yes",
          label: "Si",
          selected: false,
        },
      ],
      contactTypes,
      countryNamePartner: "",
      countryName: "",
      contact: new Form({
        contact_tel: "",
        contact_tel_partner: "",
        contact_email: "",
        contact_email_partner: "",
        contact_type: "",
        contact_medium: "",
        comments: "",
        employee_reference_name: "",
        dial_code: "",
        iso2: "",
        dial_code_partner: "",
        iso2_partner: "",
        paidOut: "no",
        paidOut_image: [],
        file_type: "image",
      }),
      maxLength: 10,
      filesLength: 0,
    };
  },
  activated() {
    this.paidOutOptionSelected();
  },
  mounted() {
    if (!this.typeAction) {
      this.updateFormInfo(this.filiationInfo);
      this.dialCodeUpdate();
      return;
    }
    this.paidOutOptionSelected();
  },
  validations: {
    contact: {
      paidOut: {
        required: requiredIf(function idImageReq() {
          return this.typeAction;
        }),
      },
      contact_type: { required },
      paidOut_image: {
        required: requiredIf(function idImageReq() {
          return (
            this.contact.paidOut &&
            this.contact.paidOut === "yes" &&
            this.typeAction
          );
        }),
      },
      contact_tel: {
        required,
      },
      contact_email: {
        email,
      },
      comments: {
        required,
      },
      contact_medium: {
        required: requiredIf(function contactMedium() {
          return this.contact.contact_type === "go_straight_to_the_clinic";
        }),
      },
      employee_reference_name: {
        required: requiredIf(function idImageReq() {
          return this.contact.contact_type === "employee_reference";
        }),
      },
    },
  },
  methods: {
    paidOutOptionSelected() {
      if (this.getSecondOpinion) {
        this.contact.paidOut = "second_opinion";
      } else {
        this.contact.paidOut = "no";
      }
    },
    dialCodeUpdate() {
      const list = this.$refs.countryCode.filteredCountries;
      if (
        this.filiationInfo?.dial_code &&
        this.filiationInfo?.iso2 &&
        this.filiationInfo?.contact_tel
      ) {
        const countryActive = list.find(
          (item) => item.iso2 === String(this.filiationInfo?.iso2)
        );
        this.$refs.countryCode.choose(countryActive);
      }
      if (
        this.filiationInfo?.dial_code_partner &&
        this.filiationInfo?.iso2_partner &&
        this.filiationInfo?.contact_tel_partner
      ) {
        const countryActivePartner = list.find(
          (item) => item.iso2 === String(this.filiationInfo?.iso2_partner)
        );
        this.$refs.countryCodePartner.choose(countryActivePartner);
      }
    },
    getFormData(data) {
      const payload = data;
      delete payload.paidOut;
      delete payload.paidOut_image;
      const formData = new FormData();
      Object.keys(payload).forEach((key) =>
        formData.append(key, payload[key] || "")
      );
      return formData;
    },
    removeImage() {
      this.contact.paidOut = "no";
      this.contact.paidOut_image = "";
      this.filesLength = 0;
    },
    onFileChange() {
      const arrayFiles = [];
      const { files } = this.$refs.uploadContentPaid.uploadData;
      this.filesLength = files.length;
      files.forEach((file) => {
        arrayFiles.push(file);
      });
      this.contact.paidOut_image = arrayFiles;
    },
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode !== 8 && charCode !== 0 && charCode < 48) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
    onSelectCountry({ name, iso2, dialCode }) {
      this.contact.countryName = name;
      this.contact.iso2 = iso2;
      this.contact.dial_code = dialCode;
    },
    onSelectCountryPartner({ name, iso2, dialCode }) {
      this.countryNamePartner = name;
      this.contact.iso2_partner = iso2;
      this.contact.dial_code_partner = dialCode;
    },
    updateValue(event) {
      const { value } = event.target;
      if (String(value).length > this.maxLength)
        this.contact.contact_tel = this.contact.contact_tel.slice(
          0,
          this.maxLength
        );
    },
    updateValuePartner(event) {
      const { value } = event.target;
      if (String(value).length > this.maxLength)
        this.contact.contact_tel_partner =
          this.contact.contact_tel_partner.slice(0, this.maxLength);
    },
  },
  computed: {
    ...mapGetters(["getSecondOpinion"]),
    paidOutOptionsFilter() {
      if (this.getSecondOpinion) {
        return this.paidOutOptions.filter(
          (option) => option.value === "second_opinion"
        );
      }
      return this.paidOutOptions.filter(
        (option) => option.value !== "second_opinion"
      );
    },
    contactType() {
      return this.contact.contact_type;
    },
  },
};
</script>
