import store from "@/store";

export default {
  getMedic(state) {
    if (!state.medic_selected) {
      const medicSelected = JSON.parse(localStorage.getItem("medic_selected"));
      if (medicSelected) store.dispatch("updateMedic", medicSelected);
    }
    return state.medic_selected;
  },
  getMedicList(state) {
    if (!state.medics) {
      const medics = JSON.parse(localStorage.getItem("medics"));
      if (medics) store.dispatch("updateMedicList", medics);
    }
    return state.medics;
  },
};
