const oauth = require("axios-oauth-client");

export default (axios) => ({
  login(username, password) {
    return oauth.client(axios, {
      url: `/oauth/token`,
      grant_type: "password",
      username,
      password,
    });
  },
  refreshToken(token) {
    return oauth.client(axios, {
      url: `/oauth/token`,
      grant_type: "refresh_token",
      refresh_token: token,
    });
  },
});
