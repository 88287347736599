<template>
  <div class="fields" :class="{ 'pt-1': !typeAction }">
    <div class="titles" v-if="typeAction">Dirección</div>
    <div>
      <div class="d-100">
        <div class="form-group field-100">
          <label for="country"> País </label>
          <select
            :disabled="!isEditActive && !typeAction"
            v-model="country"
            class="field-100">
            <option selected disabled hidden value="">País</option>
            <option value="México">México</option>
            <option value="otro">Otro</option>
          </select>
          <input
            :disabled="!isEditActive && !typeAction"
            v-model="address.address_country"
            v-if="country === 'otro'"
            class="field-100 mt-2"
            type="text"
            placeholder="País" />
        </div>
      </div>
      <div class="d-100" v-if="country === 'México'">
        <div class="form-group field-100">
          <label for="state">Estado </label>
          <select
            :disabled="!isEditActive && !typeAction"
            v-model="address.address_state"
            class="field-100">
            <option selected disabled hidden value="">Estado</option>
            <option
              v-for="state in states"
              :key="`${state.key}-${state.name}`"
              :value="state.key">
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-100" v-if="country === 'otro'">
        <div class="form-group field-100">
          <label for="suburb">Estado</label>
          <input
            v-model="address.address_state"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text"
            placeholder="Estado" />
        </div>
      </div>
      <div class="d-100">
        <div class="form-group field-100">
          <label for="county">Municipio(delegación) </label>
          <input
            v-model="address.address_county"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text"
            placeholder="Municipio" />
        </div>
      </div>
      <div class="d-100">
        <div class="form-group field-100">
          <label for="suburb">Colonia</label>
          <input
            v-model="address.address_suburb"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text"
            placeholder="Colonia" />
        </div>
      </div>
      <div class="d-100">
        <div class="form-group field-100">
          <label for="suburb">Calle</label>
          <input
            class="field-100"
            v-model="address.address_street"
            :disabled="!isEditActive && !typeAction"
            type="text"
            placeholder="Calle" />
        </div>
      </div>

      <div class="dg-numbers">
        <div class="form-group field-100">
          <label for="address_int_num">Número interior</label>
          <input
            class="field-100"
            :disabled="!isEditActive && !typeAction"
            type="text"
            v-model="address.address_int_num"
            placeholder="Número interior" />
        </div>
        <div class="form-group field-100">
          <label for="address_ext_num">Número exterior</label>
          <input
            class="field-100"
            :disabled="!isEditActive && !typeAction"
            type="text"
            v-model="address.address_ext_num"
            placeholder="Número exterior" />
        </div>
        <div class="form-group field-100">
          <label for="address_zip">Código Postal</label>
          <input
            class="field-100"
            @keypress="isNumber($event)"
            :disabled="!isEditActive && !typeAction"
            type="number"
            v-model="address.address_zip"
            placeholder="Código Postal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filiationAppointmentsMixin from "@/mixin/filiationAppointments.mixin";
import { states } from "@/constants/location";
import { sameAs, not, or } from "vuelidate/lib/validators";

import Form from "@/helpers/form";

export default {
  name: "AddressData",
  mixins: [filiationAppointmentsMixin],
  data() {
    return {
      states,
      country: "",
      address: new Form({
        address_country: "",
        address_state: "",
        address_county: "",
        address_suburb: "",
        address_street: "",
        address_ext_num: "",
        address_int_num: "",
        address_zip: "",
      }),
    };
  },
  mounted() {
    if (!this.typeAction) {
      this.updateFormInfo(this.filiationInfo);
      this.country = this.filiationInfo.address_country;
    }
  },
  validations: {
    address: {
      address_country: or(sameAs(""), not(sameAs(""))),
    },
  },
  watch: {
    country(newVal) {
      if (newVal === "México") {
        this.address.address_country = newVal;
      } else {
        this.address.address_country = "";
      }
    },
  },
  methods: {
    getFormData(data) {
      const payload = data;
      const formData = new FormData();
      Object.keys(payload).forEach((key) =>
        formData.append(key, payload[key] || "")
      );
      return formData;
    },
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode !== 8 && charCode !== 0 && charCode < 48) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
  },
};
</script>
