<template>
  <div>
    <div class="fields py-0">
      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{ error: $v.general.medic_id.$error }">
          <label for="medic_id">
            <span class="blueCitmer">*</span>Médico Disponibles</label
          >
          <select
            v-model="general.medic_id"
            :disabled="medicID !== 'all'"
            class="form-select inputsGeneralClass w-100">
            <option value="all" disabled>Seleccionar un Medico</option>
            <option
              v-for="medic in medicList"
              :key="`${medic.id}-${medic.name}`"
              :value="medic.id">
              {{ medic.name }}
            </option>
          </select>
          <div
            class="field-100 error pl-4"
            v-if="$v.general.medic_id.$error && !$v.general.medic_id.required">
            El campo es requisito
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Form from "@/helpers/form";

export default {
  name: "General",
  data() {
    return {
      medicListData: [],
      general: new Form({
        medic_id: "",
      }),
    };
  },
  validations() {
    return {
      general: {
        medic_id: {
          required,
          isNotAll: (value) => value !== "all",
        },
      },
    };
  },
  mounted() {
    this.validMedicsAvailable();
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "getCalendarFilterMedic",
      "getClinic",
      "getCalendarData",
      "getCalendarFilterMedicList",
    ]),
    medicList: {
      get() {
        return this.medicListData;
      },
      set(value) {
        this.medicListData = value;
      },
    },
    medicID: {
      get() {
        return this.getCalendarFilterMedic;
      },
      set(value) {
        this.general.medic_id = value;
        this.setCalendarFilterMedic(value);
      },
    },
  },
  methods: {
    ...mapMutations(["setCalendarFilterMedic", "setCalendarFilterMedicList"]),
    ...mapActions(["updateAlerts"]),
    validMedicsAvailable() {
      let agenda_type = null;
      if (this.getUserType !== "medic") {
        agenda_type = this.getUserType === "nursing" ? "both" : null;
      }
      this.medicListData = this.getCalendarFilterMedicList;
      const payload = {
        clinic_id: this.getClinic,
        date: this.getCalendarData.dateEdit,
        time: this.getCalendarData.time,
        agenda_type,
      };
      this.$api.appointments.getAvailableMedics(payload).then((res) => {
        if (res.data.medics.length === 0) {
          this.$parent.$parent.$parent.cancelAction();
          this.updateAlerts({
            status: "warning",
            content: "No hay médicos disponibles en el horario seleccionado.",
          });
          return;
        }
        this.medicList = res.data.medics;
        this.general.medic_id =
          this.medicID === "all" ? this.medicList[0].id : this.medicID;
      });
    },
    isFormDataValidByComponentName() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
  },
};
</script>

<style></style>
