export default {
  language: "",
  modals: {},
  toogleBMenu: false,
  routesConfiguration: {
    patients: {
      title: "Pacientes",
      hasReturnRoute: false,
      childRoutes: {
        detail: {
          title: "Detalle del Paciente",
          hasReturnRoute: true,
          returnRoute: "patients",
        },
      },
    },
    historyappointments: {
      title: "Historial de Citas",
      hasReturnRoute: false,
    },
    surgery: {
      title: "Quirófano",
      hasReturnRoute: false,
    },
    calendar: {
      title: "Calendario",
      hasReturnRoute: false,
    },
    agenda: {
      title: "Agenda",
      hasReturnRoute: false,
    },
    users: {
      title: "Usuarios",
      hasReturnRoute: false,
    },
    laboratories: {
      title: "Laboratorios",
      hasReturnRoute: false,
    },
    filiations: {
      title: "Filiación",
      hasReturnRoute: false,
    },
    metrics: {
      title: "Métricas",
      hasReturnRoute: false,
    },
    migrations: {
      title: "Migraciones",
      hasReturnRoute: false,
    },
    settings: {
      title: "Configuración",
      hasReturnRoute: false,
    },
    logOut: {
      title: "Salir",
      hasReturnRoute: false,
    },
  },
};
