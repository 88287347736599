<template>
  <div class="fields" :class="{ 'pt-1': !typeAction }">
    <div class="titles" v-if="typeAction">Datos de Paciente</div>
    <div>
      <!-- SERVICES SELECT INPUT -->
      <div class="d-100" v-if="typeAction">
        <div
          class="form-group field-100"
          :class="{ error: $v.patient.services.$error }">
          <label for="services">
            <span class="blueCitmer">*</span>Servicio
          </label>
          <select v-model="patient.services" class="field-100">
            <option selected disabled hidden value="">Servicio</option>
            <option
              v-for="service in services"
              :key="`${service.id}-${service.name}`"
              :value="service.id">
              {{ service.name }}
            </option>
          </select>
        </div>
        <div
          class="field-100 error pl-4"
          v-if="$v.patient.services.$error && !$v.patient.services.required">
          El campo es requisito
        </div>
      </div>
      <!-- SERVICES SELECT INPUT /-->
      <!-- NAME INPUT -->
      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{ error: $v.patient.name.$error }">
          <label for="name"><span class="blueCitmer">*</span>Nombre</label>
          <input
            class="field-100"
            v-model="patient.name"
            type="text"
            :disabled="!isEditActive && !typeAction"
            placeholder="Nombres" />
        </div>
        <div
          class="error ms-3"
          v-if="$v.patient.name.$error && !$v.patient.name.required">
          El campo es requisito
        </div>
      </div>
      <!-- NAME INPUT /-->
      <!-- LASTNAME INPUT -->
      <div class="d-100">
        <div
          class="form-group field-100"
          :class="{ error: $v.patient.last_name.$error }">
          <label for="last_name">
            <span class="blueCitmer">*</span>Apellidos
          </label>
          <input
            v-model="patient.last_name"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text"
            placeholder="Apellidos" />
        </div>
        <div
          class="error ms-3"
          v-if="$v.patient.last_name.$error && !$v.patient.last_name.required">
          El campo es requisito
        </div>
      </div>
      <!-- LASTNAME INPUT /-->
      <div class="d-100">
        <!-- BIRTHDATE INPUT -->
        <div class="d-50 me-3">
          <div class="form-group field-100">
            <label for="date_birth"> Nacimiento </label>
            <date-picker
              v-model="patient.date_birth"
              :disabled="!isEditActive && !typeAction"
              class="field-100"
              :format="'DD/MM/YYYY'"
              placeholder="Nacimiento"
              @change="
                patient.age = $moment().diff(patient.date_birth, 'years', false)
              "
              :disabled-date="(date) => date >= $moment().subtract(18, 'years')"
              :default-value="$moment().subtract(18, 'years')"></date-picker>
          </div>
        </div>
        <!-- BIRTHDATE INPUT /-->
        <!-- AGE INPUT -->
        <div class="d-50 ms-3">
          <div class="form-group field-100">
            <label for="age">Edad</label>
            <input
              v-model="patient.age"
              :disabled="!isEditActive && !typeAction"
              class="field-100"
              type="number"
              placeholder="Edad" />
          </div>
        </div>
        <!-- AGE INPUT /-->
      </div>

      <div class="d-100">
        <!-- GENDER INPUT -->
        <div class="d-50 me-3">
          <div class="gender">
            <label
              class="me-3 d-block"
              :class="{ error: $v.patient.sex.$error }"
              ><span class="blueCitmer">*</span>Sexo</label
            >
            <span class="me-4"
              ><input
                :disabled="!isEditActive && !typeAction"
                v-model="patient.sex"
                type="radio"
                name="sex"
                value="male" />
              M</span
            >
            <input
              :disabled="!isEditActive && !typeAction"
              v-model="patient.sex"
              type="radio"
              name="sex"
              value="female" />
            F
          </div>
          <div
            class="error ms-3"
            v-if="$v.patient.sex.$error && !$v.patient.sex.required">
            El campo es requisito
          </div>
        </div>
        <!-- GENDER INPUT /-->
        <!-- PREVIOUS TREATMENT INPUT -->
        <div class="d-50 ms-3">
          <div class="gender field-100">
            <label
              class="me-3 d-block"
              :class="{ error: $v.patient.previous_treatment.$error }"
              ><span class="blueCitmer">*</span>¿Tratamientos previos?</label
            >
            <span class="me-4">
              <input
                v-model="patient.previous_treatment"
                :disabled="!isEditActive && !typeAction"
                type="radio"
                name="previous_treatment"
                value="true" />
              Sí
            </span>

            <input
              v-model="patient.previous_treatment"
              :disabled="!isEditActive && !typeAction"
              type="radio"
              name="previous_treatment"
              value="false" />
            No
          </div>
          <div
            class="error ms-3"
            v-if="
              $v.patient.previous_treatment.$error &&
              !$v.patient.previous_treatment.required
            ">
            El campo es requisito
          </div>
        </div>
        <!-- PREVIOUS TREATMENT INPUT /-->
      </div>
      <div class="d-100">
        <!-- SECOND OPINION INPUT -->
        <div class="d-50 me-3">
          <div class="gender field-100">
            <label
              class="me-3 d-block"
              :class="{ error: $v.patient.has_second_opinion.$error }"
              ><span class="blueCitmer">*</span>¿Segundas opiniones?</label
            >
            <span class="me-4">
              <input
                v-model="patient.has_second_opinion"
                :disabled="!isEditActive && !typeAction"
                type="radio"
                name="has_second_opinion"
                :value="true" />
              Sí
            </span>

            <input
              v-model="patient.has_second_opinion"
              :disabled="!isEditActive && !typeAction"
              type="radio"
              name="has_second_opinion"
              :value="false" />
            No
          </div>
          <div
            class="error ms-3"
            v-if="
              $v.patient.has_second_opinion.$error &&
              !$v.patient.has_second_opinion.required
            ">
            El campo es requisito
          </div>
        </div>
        <!-- SECOND OPINION INPUT /-->
        <!-- SECOND OPINION SELECT -->
        <div class="d-50 ms-3">
          <div
            class="form-group field-100"
            v-if="patient.has_second_opinion === true"
            :class="{ error: $v.patient.second_opinion.$error }">
            <label for="second_opinion">
              <span class="blueCitmer">*</span>Segunda Opción
            </label>
            <select
              :disabled="!isEditActive && !typeAction"
              v-model="patient.second_opinion"
              class="field-100">
              <option selected disabled hidden :value="null">Servicio</option>
              <option
                v-for="optionSE in sdaOpinionList"
                :key="`${optionSE}-`"
                :value="optionSE">
                {{ optionSE }}
              </option>
            </select>
          </div>
          <div
            class="error ms-3"
            v-if="
              $v.patient.second_opinion.$error &&
              !$v.patient.second_opinion.required
            ">
            El campo es requisito
          </div>
        </div>
        <!-- SECOND OPINION SELECT /-->
      </div>
      <div class="d-100" v-if="patient.has_second_opinion === true">
        <!-- SECOND OPINION SELECT INPUT -->
        <div class="form-group field-100">
          <label class="me-3 d-block">
            <span class="blueCitmer">*</span>Comprobante de segunda opinion
          </label>
          <PreviewFile
            v-if="
              !validateSecondOpinionAction &&
              isPreviewSecondOpinionActive &&
              this.filiationInfo.second_opinion_doc.length !== 0
            "
            @deleteUploadedFile="deleteSecondOpinionDocuments"
            :collapseName="'previewSecondOpinion'"
            :fileType="
              this.filiationInfo.second_opinion_doc
                ? this.filiationInfo.second_opinion_doc.length > 0
                  ? this.filiationInfo.second_opinion_doc[0].file_type ===
                    'file'
                    ? 'pdf'
                    : 'img'
                  : 'empty'
                : 'empty'
            "
            :isEditActive="isEditActive"
            :previewFile="this.filiationInfo.second_opinion_doc" />
          <UploadContent
            ref="uploadContent"
            v-if="
              typeAction ||
              validateSecondOpinionAction ||
              this.filiationInfo.second_opinion_doc.length === 0
            "
            @changeFiles="changeFiles"
            @removeFiles="removeSecondOpinionDoc"
            :multiple="true" />
        </div>
        <div
          class="error ms-3"
          v-if="
            $v.patient.second_opinion_doc.$error &&
            !$v.patient.second_opinion_doc.required
          ">
          El campo es requisito
        </div>
        <!-- SECOND OPINION SELECT INPUT /-->
      </div>
      <div class="d-100">
        <!-- IDENTIFICATION SELECT INPUT -->
        <div class="form-group field-100">
          <label
            class="me-3 d-block"
            :class="{
              error:
                $v.patient.identification.$error &&
                !$v.patient.identification.required,
            }">
            <span class="blueCitmer">*</span>Identificación
          </label>
          <span class="fieldInputsFiliation">
            <select
              :disabled="!isEditActive && !typeAction"
              v-model="patient.identification">
              <option selected disabled hidden value="">Identificación</option>
              <option
                v-for="identification in identificationsFormat"
                :key="`${identification.key}-${identification.name}`"
                :value="identification.key">
                {{ identification.name }}
              </option>
            </select>
          </span>
          <PreviewFile
            v-if="
              !validateIDEAction &&
              isPreviewIDEActive &&
              (filiationInfo
                ? filiationInfo.identification_image
                  ? filiationInfo.identification_image.length > 0
                  : false
                : false)
            "
            class="mt-3"
            @deleteUploadedFile="deleteIdentificationImages"
            :fileType="
              filiationInfo
                ? filiationInfo.identification_image
                  ? filiationInfo.identification_image.length > 0
                    ? filiationInfo.identification_image[0].file_type === 'file'
                      ? 'pdf'
                      : 'img'
                    : 'empty'
                  : 'empty'
                : 'empty'
            "
            :collapseName="'previewIDE'"
            :isEditActive="isEditActive"
            :previewFile="filiationInfo.identification_image" />
          <UploadContent
            v-if="
              patient.identification !== 'present_on_clinic' &&
              patient.identification !== 'not_apply' &&
              patient.identification !== '' &&
              (filiationInfo
                ? filiationInfo.identification_image
                  ? filiationInfo.identification_image.length === 0
                  : true
                : true)
            "
            ref="uploadContentID"
            @changeFiles="changeFilesID"
            @removeFiles="removeSecondOpinionDocID"
            :multiple="true" />
          <!-- IDENTIFICATION INPUT FILE /-->
        </div>
        <div
          class="error ms-3"
          v-if="
            ($v.patient.identification.$error &&
              !$v.patient.identification.required) ||
            ($v.patient.identification_image.$error &&
              !$v.patient.identification_image.required)
          ">
          El campo es requisito
        </div>
        <!-- IDENTIFICATION SELECT INPUT /-->
      </div>
      <!-- OCCUPATION SELECT INPUT -->
      <div class="d-100">
        <div class="form-group field-100">
          <label
            class="me-3 d-block"
            :class="{ error: $v.patient.occupation_id.$error }">
            <span class="blueCitmer">*</span>Ocupación
          </label>
          <select
            :disabled="!isEditActive && !typeAction"
            v-model="patient.occupation_id"
            class="field-100">
            <option selected disabled hidden value="">Ocupación</option>
            <option
              v-for="occupation in getOccupations"
              :key="`${occupation.id}-${occupation.name}`"
              :value="occupation.id">
              {{ occupationNameByUserType(occupation) }}
            </option>
          </select>
        </div>
        <div
          class="error ms-3"
          v-if="
            $v.patient.occupation_id.$error &&
            !$v.patient.occupation_id.required
          ">
          El campo es requisito
        </div>
      </div>
      <!-- OCCUPATION SELECT INPUT /-->
      <!-- OCCUPATION OTHER INPUT -->
      <div class="d-100" v-if="patient.occupation_id === 29">
        <div
          class="form-group field-100"
          :class="{ error: $v.patient.occupation_other.$error }">
          <label for="occupation_other"
            ><span class="blueCitmer">*</span>Otra Ocupación</label
          >
          <input
            class="field-100"
            v-model="patient.occupation_other"
            type="text"
            :disabled="!isEditActive && !typeAction" />
        </div>
        <div
          class="error ms-3"
          v-if="
            $v.patient.occupation_other.$error &&
            !$v.patient.occupation_other.required
          ">
          El campo es requisito
        </div>
      </div>
      <!-- OCCUPATION OTHER INPUT /-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import filiationAppointmentsMixin from "@/mixin/filiationAppointments.mixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import "vue2-datepicker/index.css";
import { sdaOpinionList, identifications } from "@/constants/appointments";
import Form from "@/helpers/form";

export default {
  name: "PatientData",
  components: {
    DatePicker: () => import("vue2-datepicker"),
    PreviewFile: () =>
      import("@/components/patient/appointment/filiations/PreviewFile.vue"),
    UploadContent: () =>
      import("@/components/patient/filiation/modals/UploadContent.vue"),
  },
  watch: {
    "patient.has_second_opinion": {
      handler(value) {
        this.setSecondOpinionState(value);
      },
      immediate: true,
    },
    "filiationInfo": {
      handler(newValue) {
        this.updateFormInfo(newValue);
      },
    },
  },
  mixins: [filiationAppointmentsMixin],
  mounted() {
    this.getServices();
    if (!this.typeAction && this.filiationInfo) {
      this.updateFormInfo(this.filiationInfo);
    }
  },
  data() {
    return {
      sdaOpinionList,
      removeDocuments: false,
      isPreviewIDEActive: true,
      isPreviewSecondOpinionActive: true,
      filesLength: 0,
      filesLengthID: 0,
      patient: new Form({
        services: "",
        name: "",
        last_name: "",
        date_birth: null,
        age: "",
        sex: "",
        identification: "",
        occupation_id: "",
        occupation_other: "",
        identification_image: "",
        previous_treatment: "",
        has_second_opinion: "",
        second_opinion: null,
        second_opinion_doc: "",
      }),
      services: [],
      disabledAfter: this.$moment().subtract(18, "years"),
    };
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "getOccupations",
      "isCreateExternalPatient",
      "isDonorPatient",
      "getSecondOpinion",
    ]),
    getFileTypeIDE() {
      if (
        this.patient.identification !== "present_on_clinic" &&
        this.patient.identification !== "not_apply"
      ) {
        return this.patient.identification.filetype;
      }
      return "empty";
    },
    validateSecondOpinionAction() {
      if (
        !this.typeAction &&
        this.filiationInfo &&
        this.filiationInfo.second_opinion_doc
      ) {
        if (this.isPreviewSecondOpinionActive) {
          return false;
        }
      }
      return true;
    },
    validateIDEAction() {
      if (!this.typeAction && this.filiationInfo) {
        if (
          this.filiationInfo.identification !== "present_on_clinic" &&
          this.filiationInfo.identification !== "not_apply" &&
          this.isPreviewIDEActive
        ) {
          return false;
        }
      }
      return true;
    },
    identificationsFormat() {
      return identifications;
    },
    validPatient() {
      if (
        (this.patient.sex === "male" && this.patient.age >= 80) ||
        (this.patient.sex === "female" && this.patient.age >= 50)
      ) {
        return false;
      }
      return true;
    },
  },
  validations() {
    return {
      patient: {
        services: {
          required: requiredIf(function serviceReq() {
            return this.typeAction;
          }),
        },
        name: {
          required,
        },
        last_name: {
          required,
        },
        sex: {
          required,
        },
        occupation_id: {
          required,
        },
        previous_treatment: {
          required,
        },
        identification: {
          required,
        },
        identification_image: {
          required: requiredIf(function idImageReq() {
            return this.identification(this.patient.identification);
          }),
        },
        second_opinion_doc: {
          required: requiredIf(function idImageReq() {
            return this.patient.has_second_opinion;
          }),
        },
        has_second_opinion: {
          required,
        },
        second_opinion: {
          required: requiredIf(function idImageReq() {
            return this.patient.has_second_opinion;
          }),
        },
        occupation_other: {
          required: requiredIf(function occupationOtherReq() {
            return this.patient.occupation_id === 29;
          }),
        },
      },
    };
  },
  methods: {
    ...mapMutations(["setSecondOpinionState"]),
    deleteSecondOpinionDocuments() {
      this.$api.appointments
        .deleteSecondOpinionDocuments(this.filiationInfo.id)
        .then(() => {
          this.isPreviewIDEActive = true;
          this.isPreviewSecondOpinionActive = true;
          this.$parent.$parent.$parent.isEditFiliationActive = false;
          this.$parent.$parent.$parent.loadFiliationInfo(this.filiationInfo.id);
          this.$parent.$parent.$parent.$emit("refreshFiliationList");
          this.$parent.hideModal();
        });
    },
    deleteIdentificationImages() {
      this.patient.identification = "not_apply";
      this.patient.identification_image = "";
      this.filiationInfo.identification_image = "";
      this.saveUpdateFiliation();
    },
    identification(identification) {
      return (
        identification !== "present_on_clinic" && identification !== "not_apply"
      );
    },
    changeSecondOpinion() {
      this.patient.second_opinion = "";
      this.isPreviewSecondOpinionActive = false;
    },
    changeIDE() {
      this.patient.identification = "not_apply";
      this.isPreviewIDEActive = false;
    },
    getFormData(data) {
      const payload = data;
      delete payload.services;
      // if (
      //   this.filiationInfo.identification !== "present_on_clinic" &&
      //   this.filiationInfo.identification !== "not_apply" &&
      //   this.isPreviewIDEActive
      // ) {
      //   delete payload.identification;
      //   delete payload.identification_image;
      // }
      if (
        (this.isPreviewSecondOpinionActive && !payload.second_opinion_doc) ||
        typeof payload.second_opinion_doc === "string"
      ) {
        delete payload.second_opinion_doc;
      }
      const formData = new FormData();
      Object.keys(payload).forEach((key) =>
        formData.append(key, payload[key] || "")
      );
      return formData;
    },
    occupationNameByUserType(data) {
      if (this.getUserType === "superadmin") {
        return `Clase ${data.class.toUpperCase()} - ${data.name}`;
      }
      return data.name;
    },
    getServices() {
      const payload = {
        agenda_id: !this.isCreateExternalPatient ? 1 : 6,
      };
      payload.agenda_id = this.isDonorPatient ? 3 : payload.agenda_id;
      this.$api.settings.getServices(payload).then((res) => {
        this.services = res.data.services;
      });
    },
    removeSecondOpinionDoc() {
      this.patient.second_opinion_doc = "";
      this.filesLength = 0;
    },
    removeSecondOpinionDocID() {
      this.patient.identification_image = "";
      this.filesLengthID = 0;
    },
    changeFiles() {
      const arrayFiles = [];
      const { files } = this.$refs.uploadContent.uploadData;
      this.filesLength = files.length;
      files.forEach((file) => {
        arrayFiles.push(file);
      });
      this.patient.second_opinion_doc = arrayFiles;
    },
    changeFilesID() {
      const arrayFiles = [];
      const { files } = this.$refs.uploadContentID.uploadData;
      this.filesLengthID = files.length;
      files.forEach((file) => {
        arrayFiles.push(file);
      });
      this.patient.identification_image = arrayFiles;
    },
  },
};
</script>
