<template>
  <div>
    <div v-if="load" class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
    <div v-if="load" class="modal-backdrop best_zind"></div>
  </div>
</template>
<script>
export default {
  name: "loading",
  data() {
    return {
      load: true,
    };
  },
};
</script>
