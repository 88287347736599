<template>
  <div class="headerFiliation">
    <p v-if="getFiliationInfo">
      Filiación: <span class="value">{{ getFiliationInfo.filiation_num }}</span>
    </p>
    <div v-if="getCalendarData">
      <p>
        Fecha:
        <span class="value">{{ getCalendarData.date }}</span>
      </p>
      <p>
        Horario: <span class="value">{{ getCalendarData.time }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderFiliation",
  computed: {
    ...mapGetters(["getCalendarData", "getFiliationInfo"]),
  },
};
</script>
