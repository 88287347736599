import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions(["updateCalendarDataAppointments"]),
    getTime(date) {
      const hour = date.format("HH");
      let minutes = date.format("mm");
      minutes = parseInt(minutes, 10) >= 30 ? "30" : "00";
      return `${hour}:${minutes}`;
    },
    defineDateTimeAppointment(data = null, time = null) {
      const date = data ? this.$moment(data) : this.$moment();
      const thisMoment = this.$moment();
      const afterLine = date > thisMoment;
      const minutesDiff = thisMoment.diff(date, "minutes");
      const validTime = afterLine || minutesDiff <= 30;
      if (validTime || this.getUserType === "superadmin") {
        this.updateCalendarDataAppointments({
          dateEdit: date.toDate(),
          originalDate: date.format("DD-MM-YYYY"),
          date: date.format("DD MMMM"),
          time: time || this.getTime(date),
        });
      }
      return validTime;
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
};
