<template>
  <div class="fields" :class="{ 'pt-1': !typeAction }">
    <div class="titles" v-if="typeAction">Datos de Pareja</div>
    <div>
      <!-- COUPLE NAME -->
      <div class="d-100">
        <div class="form-group field-100">
          <label for="partner_name">Nombre</label>
          <input
            class="field-100"
            :disabled="!isEditActive && !typeAction"
            v-model="couple.partner_name"
            type="text"
            placeholder="Nombres" />
        </div>
      </div>
      <!-- COUPLE NAME /-->
      <!-- COUPLE LASTNAME -->
      <div class="d-100">
        <div class="form-group field-100">
          <label for="partner_last_name">Apellidos</label>
          <input
            v-model="couple.partner_last_name"
            :disabled="!isEditActive && !typeAction"
            class="field-100"
            type="text"
            placeholder="Apellidos" />
        </div>
      </div>
      <!-- COUPLE LASTNAME / -->
      <div class="d-100">
        <!-- COUPLE BIRTHDATE -->
        <div class="d-50 me-3">
          <div class="form-group field-100">
            <label for="partner_date_birth">Nacimiento</label>
            <date-picker
              v-model="couple.partner_date_birth"
              :disabled="!isEditActive && !typeAction"
              class="field-100"
              :format="'DD/MM/YYYY'"
              placeholder="Nacimiento"
              @change="
                couple.partner_age = $moment().diff(
                  couple.partner_date_birth,
                  'years',
                  false
                )
              "
              :disabled-date="(date) => date >= $moment().subtract(18, 'years')"
              :default-value="$moment().subtract(18, 'years')"></date-picker>
          </div>
        </div>
        <!-- COUPLE BIRTHDATE / -->
        <!-- COUPLE AGE -->
        <div class="d-50 ms-3">
          <div class="form-group field-100">
            <label for="partner_age">Edad</label>
            <input
              v-model="couple.partner_age"
              :disabled="!isEditActive && !typeAction"
              class="field-100"
              type="number"
              placeholder="Edad" />
          </div>
        </div>
        <!-- COUPLE AGE /-->
      </div>
      <!-- COUPLE GENDER -->
      <div class="d-100">
        <div class="gender">
          <label class="me-3 d-block"> Sexo </label>
          <span class="me-4"
            ><input
              v-model="couple.partner_sex"
              :disabled="!isEditActive && !typeAction"
              type="radio"
              name="sex"
              value="male" />
            M</span
          >
          <input
            v-model="couple.partner_sex"
            :disabled="!isEditActive && !typeAction"
            type="radio"
            name="sex"
            value="female" />
          F
        </div>
      </div>
      <!-- COUPLE GENDER /-->
      <!-- COUPLE IDENTIFICATION -->
      <div class="d-100">
        <div class="form-group field-100">
          <label
            class="me-3 d-block"
            :class="{
              error:
                ($v.couple.partner_identification.$error &&
                  !$v.couple.partner_identification.required) ||
                ($v.couple.partner_identification_image.$error &&
                  !$v.couple.partner_identification_image.required),
            }">
            <span class="blueCitmer">*</span>Identificación
          </label>
          <span class="fieldInputsFiliation">
            <!-- COUPLE IDENTIFICATION SELECT TYPE -->
            <select
              :disabled="!isEditActive && !typeAction"
              v-model="couple.partner_identification"
              class="field-100">
              <option selected disabled hidden value="">Identificación</option>
              <option
                v-for="identification in identifications"
                :key="`${identification.key}-${identification.name}`"
                :value="identification.key">
                {{ identification.name }}
              </option>
            </select>
            <!-- COUPLE IDENTIFICATION SELECT TYPE -->
          </span>
          <PreviewFile
            v-if="
              !validateIDEAction &&
              isPreviewIDEActive &&
              (filiationInfo
                ? filiationInfo.partner_identification_image
                  ? filiationInfo.partner_identification_image.length > 0
                  : false
                : false)
            "
            class="mt-3"
            @changeUploadedFile="changeIDE"
            @deleteUploadedFile="deleteIdentificationImages"
            :fileType="
              filiationInfo
                ? filiationInfo.partner_identification_image
                  ? filiationInfo.partner_identification_image.length > 0
                    ? filiationInfo.partner_identification_image[0]
                        .file_type === 'file'
                      ? 'pdf'
                      : 'img'
                    : 'empty'
                  : 'empty'
                : 'empty'
            "
            multiple
            :collapseName="'previewIDE'"
            :isEditActive="isEditActive"
            :previewFile="filiationInfo.partner_identification_image" />
          <!-- COUPLE IDENTIFICATION INPUT FILE -->
          <UploadContent
            v-if="
              couple.partner_identification !== 'present_on_clinic' &&
              couple.partner_identification !== 'not_apply' &&
              couple.partner_identification !== '' &&
              (filiationInfo
                ? filiationInfo.partner_identification_image
                  ? filiationInfo.partner_identification_image.length === 0
                  : true
                : true)
            "
            ref="uploadContentID"
            @changeFiles="changeFilesID"
            @removeFiles="removeSecondOpinionDocID"
            :multiple="true" />
          <!-- COUPLE IDENTIFICATION INPUT FILE /-->
        </div>
        <div
          class="error ms-3"
          v-if="
            ($v.couple.partner_identification.$error &&
              !$v.couple.partner_identification.required) ||
            ($v.couple.partner_identification_image.$error &&
              !$v.couple.partner_identification_image.required)
          ">
          El campo es requisito
        </div>
      </div>
      <!-- COUPLE IDENTIFICATION /-->
      <!-- COUPLE OCUPATION -->
      <div class="d-100">
        <div class="form-group field-100">
          <label :class="{ error: $v.couple.partner_occupation_id.$error }">
            <span class="blueCitmer">*</span>Ocupación
          </label>
          <select
            :disabled="!isEditActive && !typeAction"
            v-model="couple.partner_occupation_id"
            class="field-100">
            <option selected disabled hidden value="">Ocupación</option>
            <option
              v-for="occupation in getOccupationsPartner"
              :key="`${occupation.id}-${occupation.name}`"
              :value="occupation.id">
              {{ occupationNameByUserType(occupation) }}
            </option>
          </select>
        </div>
        <div
          class="error ms-3"
          v-if="
            $v.couple.partner_occupation_id.$error &&
            !$v.couple.partner_occupation_id.required
          ">
          El campo es requisito
        </div>
      </div>
      <!-- COUPLE OCUPATION /-->
      <!-- COUPLE OCCUPATION OTHER INPUT -->
      <div class="d-100" v-if="couple.partner_occupation_id === 57">
        <div
          class="form-group field-100"
          :class="{ error: $v.couple.partner_occupation_other.$error }">
          <label for="partner_occupation_other"
            ><span class="blueCitmer">*</span>Otra Ocupación</label
          >
          <input
            class="field-100"
            v-model="couple.partner_occupation_other"
            type="text"
            :disabled="!isEditActive && !typeAction" />
        </div>
        <div
          class="error ms-3"
          v-if="
            $v.couple.partner_occupation_other.$error &&
            !$v.couple.partner_occupation_other.required
          ">
          El campo es requisito
        </div>
      </div>
      <!-- COUPLE OCCUPATION OTHER INPUT /-->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
/* eslint-disable no-unused-vars */
import { identifications } from "@/constants/appointments";
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import filiationAppointmentsMixin from "@/mixin/filiationAppointments.mixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Form from "@/helpers/form";
import PreviewFile from "@/components/patient/appointment/filiations/PreviewFile.vue";

export default {
  name: "CoupleData",
  components: {
    DatePicker,
    PreviewFile,
    UploadContent: () =>
      import("@/components/patient/filiation/modals/UploadContent.vue"),
  },
  mixins: [filiationAppointmentsMixin],
  data() {
    return {
      identifications,
      isPreviewIDEActive: true,
      filesLengthID: 0,
      couple: new Form({
        partner_name: "",
        partner_last_name: "",
        partner_date_birth: "",
        partner_age: "",
        partner_sex: "",
        partner_identification: "",
        partner_identification_image: "",
        partner_occupation_id: "",
        partner_occupation_other: "",
      }),
    };
  },
  validations() {
    return {
      couple: {
        partner_identification: {
          required,
        },
        partner_occupation_id: {
          required,
        },
        partner_identification_image: {
          required: requiredIf(function idImageReq() {
            return (
              this.couple.partner_identification &&
              this.couple.partner_identification !== "present_on_clinic" &&
              this.couple.partner_identification !== "not_apply"
            );
          }),
        },
        partner_occupation_other: {
          required: requiredIf(function partnerOccupationOtherReq() {
            return this.couple.partner_occupation_id === 57;
          }),
        },
      },
    };
  },
  mounted() {
    if (!this.typeAction) {
      this.updateFormInfo(this.filiationInfo);
    }
  },
  computed: {
    ...mapGetters(["getOccupations", "getOccupationsPartner"]),
    validPatient() {
      if (
        (this.couple.partner_sex === "male" && this.couple.partner_age >= 80) ||
        (this.couple.partner_sex === "female" && this.couple.partner_age >= 50)
      ) {
        return false;
      }
      return true;
    },
    getFileTypeIDE() {
      if (
        this.couple.partner_identification === "present_on_clinic" ||
        this.couple.partner_identification === "not_apply"
      ) {
        return "empty";
      }
      if (
        this.couple.partner_identification !== "present_on_clinic" &&
        this.couple.partner_identification !== "not_apply" &&
        this.couple.partner_identification_image &&
        typeof this.couple.partner_identification_image === "string"
      ) {
        if (
          this.couple.partner_identification_image.includes("jpg") ||
          this.couple.partner_identification_image.includes("svg") ||
          this.couple.partner_identification_image.includes("jpeg")
        ) {
          return "img";
        }
      }
      return "pdf";
    },
    validateIDEAction() {
      if (
        !this.typeAction &&
        this.filiationInfo &&
        this.filiationInfo.partner_identification_image
      ) {
        if (
          this.filiationInfo.partner_identification !== "present_on_clinic" &&
          this.filiationInfo.partner_identification !== "not_apply" &&
          this.isPreviewIDEActive
        ) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    getFormData(data) {
      const payload = data;
      const formData = new FormData();
      Object.keys(payload).forEach((key) =>
        formData.append(key, payload[key] || "")
      );
      return formData;
    },
    changeIDE() {
      this.couple.partner_identification = "not_apply";
      this.isPreviewIDEActive = false;
    },
    deleteIdentificationImages() {
      this.couple.partner_identification = "not_apply";
      this.couple.partner_identification_image = "";
      this.filiationInfo.partner_identification_image = "";
      this.saveUpdateFiliation();
    },
    changeFilesID() {
      const arrayFiles = [];
      const { files } = this.$refs.uploadContentID.uploadData;
      this.filesLengthID = files.length;
      files.forEach((file) => {
        arrayFiles.push(file);
      });
      this.couple.partner_identification_image = arrayFiles;
    },
    removeSecondOpinionDocID() {
      this.couple.partner_identification_image = "";
      this.filesLengthID = 0;
    },
    occupationNameByUserType(data) {
      if (this.getUserType === "superadmin") {
        return `Clase ${data.class.toUpperCase()} - ${data.name}`;
      }
      return data.name;
    },
    removeImage() {
      this.couple.partner_identification = "";
      this.couple.partner_identification_image = "";
      this.$refs.fileInputIDCouple.value = "";
    },
    onFileChange() {
      const { files } = this.$refs.fileInputIDCouple;
      [this.couple.partner_identification_image] = files;
    },
  },
};
</script>
