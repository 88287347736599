export default (axios) => ({
  getData(payload) {
    return axios.get("v1/appointments", { params: payload });
  },
  getConfig(id) {
    return axios.get("v1/calendar_configuration", { params: id });
  },
  updateConfig(id, payload) {
    return axios.put(`v1/calendar_configuration/${id}`, payload);
  },
});
