export default (axios) => ({
  getABCInfo(payload) {
    return axios.get("v1/patient_abc_info", { params: payload });
  },
  getPatientsABC(payload) {
    return axios.get("v1/patient_abcs", { params: payload });
  },
  getPatientABC(id) {
    return axios.get(`v1/patient_abc/${id}`);
  },
  postPatientABC(payload) {
    return axios.post("v1/patient_abc", payload);
  },
  postFollowUp(payload) {
    return axios.post("v1/follow_up_tasks", payload);
  },
  postFollowUpStatus(id, payload) {
    return axios.post(`v1/follow_up_task_note/${id}`, payload);
  },
  showFollowUp(id) {
    return axios.get(`v1/follow_up_tasks/${id}`);
  },
  getFollowUp(payload) {
    return axios.get("v1/follow_up_tasks", { params: payload });
  },
});
