export default (axios) => ({
  //   Presurgical
  getPresurgical(payload) {
    return axios.get("v1/operation_room_presurgicals", { params: payload });
  },
  savePresurgical(payload) {
    return axios.put("v1/operation_room_presurgicals", payload);
  },
  //   Presurgical ---
  //   Surgical
  getSurgical(payload) {
    return axios.get("v1/operation_room_surgicals", { params: payload });
  },
  saveSurgical(payload) {
    return axios.put("v1/operation_room_surgicals", payload);
  },
  //   Surgical ---
  //   Follicular Puncture
  getFollicularPuncture(payload) {
    return axios.get("v1/operation_room_follicular_punctures", {
      params: payload,
    });
  },
  saveFollicularPuncture(payload) {
    return axios.put("v1/operation_room_follicular_punctures", payload);
  },
  //   Follicular Puncture ---
  //   Embryo Transfer
  getEmbryoTransfer(payload) {
    return axios.get("v1/operation_room_embryo_transfers", {
      params: payload,
    });
  },
  saveEmbryoTransfer(payload) {
    return axios.put("v1/operation_room_embryo_transfers", payload);
  },
  //   Embryo Transfer ---
});
