import { authGuard } from "@/helpers/auth";

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

export default [
  {
    path: "detalle/:id",
    name: "patients.detail",
    beforeEnter: authGuard,
    component: loadView("patients/Detail"),
  },
];
