import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

const fasIcons = [
  fas.faTimes,
  fas.faPrint,
  fas.faChartBar,
  fas.faCaretUp,
  fas.faEye,
  fas.faEyeSlash,
  fas.faPhoneAlt,
  fas.faPencilAlt,
  fas.faKey,
  fas.faUsers,
  fas.faCaretRight,
  fas.faCaretDown,
  fas.faCog,
  fas.faSave,
  fas.faEdit,
  fas.faTimesCircle,
  fas.faMinus,
  fas.faPlus,
  fas.faUserCircle,
  fas.faSignOutAlt,
  fas.faSearch,
  fas.faFolderOpen,
  fas.faFolderPlus,
  fas.faFileAlt,
  fas.faFilePen,
  fas.faFileMedicalAlt,
  fas.faFileMedical,
  fas.faClipboardCheck,
  fas.faClipboardList,
  fas.faFileSignature,
  fas.faMicroscope,
  fas.faFileArchive,
  fas.faFileUpload,
  fas.faBrain,
  fas.faWeight,
  fas.faCloudUploadAlt,
  fas.faCheck,
  fas.faCheckCircle,
  fas.faCircle,
  fas.faMoneyBill,
  fas.faPaperclip,
  fas.faFilePdf,
  fas.faTrashAlt,
  fas.faIdBadge,
  fas.faUserMd,
  fas.faClinicMedical,
  fas.faFlag,
  fas.faInfoCircle,
  fas.faChevronRight,
  fas.faChevronLeft,
  fas.faProcedures,
  fas.faFileWaveform,
  fas.faHospitalUser,
  fas.faHospital,
  fas.faExclamationTriangle,
  fas.faCashRegister,
  fas.faUserDoctor,
  fas.faUserGroup,
  fas.faEllipsis,
  fas.faFileCircleExclamation,
  fas.faCircleCheck,
  fas.faCircleExclamation,
  fas.faFileExport,
  fas.faToggleOn,
  fas.faToggleOff,
];

const farIcons = [
  far.faUser,
  far.faCalendar,
  far.faCalendarCheck,
  far.faIdBadge,
  far.faClock,
  far.faFolderOpen,
  far.faWindowClose,
];

const fabIcons = [fab.faFacebookSquare, fab.faTwitter, fab.faInstagram];

export default function registerComponents() {
  fasIcons.forEach((icon) => {
    library.add(icon);
  });
  fabIcons.forEach((icon) => {
    library.add(icon);
  });
  farIcons.forEach((icon) => {
    library.add(icon);
  });
}
