<template>
  <b-modal
    ref="menuAppointment"
    id="menuAppointment"
    size="lg"
    @hide="hideModal"
    hide-header
    hide-footer>
    <div class="container">
      <div class="titles my-4 mx-3">Menú de Acciones</div>
      <div class="row mx-3 mb-5">
        <div
          class="col"
          v-for="(option, index) in menuOptionsFilter"
          :key="index + option.title">
          <div
            class="card card-no-shadow pointer menuCard"
            role="button"
            @click="option.action">
            <div class="card-img-top text-secondary">
              <font-awesome-icon
                size="xl"
                class="my-5 card-img-top"
                :class="option.colorIcon ? option.colorIcon : ''"
                :icon="[option.iconType, option.icon]" />
            </div>
            <hr />
            <div class="card-body text-center">
              <h6>{{ option.title }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "MenuCE",
  props: {
    type: {
      type: String,
      required: true,
      default: "edit",
    },
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "menuAppointment",
      ref: this.$refs.menuAppointment,
    });
  },
  data() {
    return {
      menuOptions: {
        create: {
          search: {
            icon: "search",
            iconType: "fas",
            title: "Buscar paciente",
            action: this.searchPatient,
          },
          externalPatient: {
            icon: "hospital-user",
            iconType: "fas",
            colorIcon: "text-primary",
            title: "Paciente Externo",
            action: this.externalPatient,
          },
          donorPatient: {
            icon: "hospital-user",
            iconType: "fas",
            colorIcon: "text-danger",
            title: "Paciente Donante",
            action: this.donorPatient,
          },
          newFiliation: {
            icon: "user",
            iconType: "far",
            title: "Nueva Filiación",
            action: this.newFiliation,
          },
        },
        edit: {
          cancelar: {
            icon: "window-close",
            iconType: "far",
            title: "Cancelar",
            action: this.cancelAppointment,
          },
          reschedule: {
            icon: "edit",
            iconType: "fas",
            title: "Editar/Reagendar",
            action: this.reschedule,
          },
          preview: {
            icon: "info-circle",
            iconType: "fas",
            title: "Vista previa",
            action: this.openPreview,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getUserType"]),
    menuOptionsFilter() {
      const options = this.menuOptions[this.type];
      if (this.getUserType === "nursing") {
        delete options.newFiliation;
        return options;
      }
      delete options.externalPatient;
      delete options.donorPatient;
      return options;
    },
  },
  methods: {
    ...mapActions(["updateFiliationState"]),
    ...mapMutations(["setIsDonorPatient"]),
    hideModal() {
      this.$store.getters.getModal("menuAppointment").hide();
    },
    searchPatient() {
      this.$emit("createBySearchPatient");
    },
    newFiliation() {
      this.updateFiliationState();
      this.$emit("createByNewFiliation");
    },
    cancelAppointment() {
      this.$emit("cancelAppointment");
    },
    reschedule() {
      this.$emit("openEditAppointment");
    },
    openPreview() {
      this.$emit("openPreview");
    },
    externalPatient() {
      this.$emit("createByExternalPatient");
    },
    donorPatient() {
      this.setIsDonorPatient(true);
      this.$emit("createByExternalPatient");
    },
    searchUser(payload) {
      this.$api.patient.getPatientList(payload).then((res) => {
        this.isSearchTrigger = true;
        this.patients = res.data.patients;
      });
    },
  },
};
</script>
