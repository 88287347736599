export default {
  setAlerts(state, alert = null) {
    if (!alert) state.alerts = {};
    const id = Date.now().toString() + Math.random().toString();
    const newAlert = { [id]: alert };
    state.alerts = Object.assign({}, state.alerts, newAlert);
  },
  removeAlertByKey(state, key) {
    if (!state.alerts[key]) return;
    const update = Object.assign({}, state.alerts);
    delete update[key];
    state.alerts = Object.assign({}, update);
  },
  setConfirmData(state, newConfirmData) {
    const stateData = state;
    stateData.confirmData = newConfirmData;
  },
};
