import store from "@/store";

export default {
  getCalendarData(state) {
    return state.calendarData;
  },
  getCalendarConfig(state) {
    if (!state.calendarConfig) {
      const calendarConfig = JSON.parse(
        localStorage.getItem("calendar-config")
      );
      if (calendarConfig)
        store.dispatch("updateCalendarConfig", calendarConfig);
    }
    return state.calendarConfig;
  },
  getCalendarFilterMedic(state) {
    return state.calendarFilter.medic_id;
  },
  getCalendarFilterMedicList(state) {
    return state.calendarFilter.medicList;
  },
  getCalendarFilterSchedule(state) {
    return state.calendarFilter.schedule_id;
  },
  ceAppointmentEditID(state) {
    return state.ceAppointmentEditID;
  },
  isCEAppointmentMenu(state) {
    return state.ceAppointmentState === "menu";
  },
  isCEAppointmentCreate(state) {
    return state.ceAppointmentState === "create";
  },
  isCreateExternalPatient(state) {
    return state.isCreateExternalPatient;
  },
  isDonorPatient(state) {
    return state.isDonorPatient;
  },
  isCEFiliationCreate(state) {
    return state.ceFiliationState === "create";
  },
  getFiliationInfo(state) {
    return state.filiationInfo;
  },
  getFiliationState(state) {
    return state.filiationState;
  },
  getSelectedPatientID(state) {
    return state.selectedPatientID;
  },
  getSelectedPatientNextAppointment(state) {
    return state.selectedPatientNextAppointment;
  },
  getInfoAppointmentPayment(state) {
    return state.infoAppointmentPayment;
  },
};
