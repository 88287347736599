export default {
  updateAlerts({ commit }, alert) {
    commit("setAlerts", alert);
  },
  removeAlertByKey({ commit }, key) {
    commit("removeAlertByKey", key);
  },
  updateConfirmData(
    state,
    newConfirmData = { title: "", description: "", confirmFunction: null }
  ) {
    state.commit("setConfirmData", newConfirmData);
  },
};
