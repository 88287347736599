<!-- Create or Edit Appointment -->
<!-- Via vuex the states of actions create or edit -->
<template>
  <b-modal
    ref="ceAppointment"
    id="ceAppointment"
    content-class="px-3 pb-3"
    size="xl"
    @shown="getAppointmentInfo"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer>
    <form class="ceAppointment" @submit.stop.prevent="onSubmit">
      <div class="m-3 pt-2">
        <div class="titles mb-4">{{ titleByAction }}</div>
        <hr />
      </div>
      <transition appear name="fade" mode="out-in">
        <SelectPatient
          :isPatientSelected="isPatientSelected"
          @switchSelectedPatient="switchSelectedPatient"
          ref="selectPatient" />
      </transition>
      <transition appear name="fade" mode="out-in">
        <hr v-if="isPatientSelected" />
      </transition>
      <transition appear name="fade" mode="out-in">
        <LocationAndDate
          :isMedicSelected="isMedicSelected"
          @switchSelectedMedic="switchSelectedMedic"
          v-show="isPatientSelected"
          ref="locationAndDate" />
      </transition>
      <transition appear name="fade" mode="out-in">
        <hr v-if="lastOptions" />
      </transition>
      <transition appear name="fade" mode="out-in">
        <GeneralInputs
          :medicType="medicType"
          :isMainGeneralInputSelected="isMainGeneralInputSelected"
          @switchSelectedGeneral="switchSelectedGeneral"
          ref="generalInputs"
          v-show="lastOptions" />
      </transition>

      <!-- Footer Actions -->
      <div class="d-flex">
        <div class="d-flex flex-column col-12 mb-5 pe-4">
          <div class="align-self-end">
            <button
              type="button"
              @click="$emit('resetComponent')"
              class="btn btn-secondary py-3 px-5 radiusButton">
              Cancelar
            </button>
            <transition appear name="fade" mode="out-in">
              <button
                v-show="lastOptions && isMainGeneralInputSelected"
                type="submit"
                class="btn btn-primary ms-4 py-3 px-5 radiusButton">
                Guardar
              </button>
            </transition>
          </div>
        </div>
      </div>
      <!-- Footer Actions / -->
    </form>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import LocationAndDate from "@/components/appointments/LocationAndDate.vue";
import SelectPatient from "@/components/appointments/SelectPatient.vue";
import GeneralInputs from "@/components/appointments/GeneralInputs.vue";

export default {
  name: "CEAppointment",
  components: {
    LocationAndDate,
    SelectPatient,
    GeneralInputs,
  },
  data() {
    return {
      agenda: null,
      isPatientSelected: false,
      isMedicSelected: false,
      isMainGeneralInputSelected: false,
      medicType: "medic",
    };
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "ceAppointment",
      ref: this.$refs.ceAppointment,
    });
  },
  computed: {
    ...mapGetters([
      "isCEAppointmentCreate",
      "isCEAppointmentMenu",
      "ceAppointmentEditID",
      "isMedicUser",
      "getUserType",
      "isABCUserType",
      "getUserID",
    ]),
    lastOptions() {
      if (
        (this.isMedicUser ||
          this.getUserType === "nutrition" ||
          this.getUserType === "psychology" ||
          this.isABCUserType) &&
        this.isPatientSelected
      )
        return true;
      if (this.isPatientSelected && this.isMedicSelected) {
        return true;
      }
      return false;
    },
    titleByAction() {
      if (this.isCEAppointmentCreate) return "Crear Evento";
      return "Editar Evento";
    },
  },
  methods: {
    ...mapMutations(["setSelectedPatientID"]),
    switchSelectedPatient(state) {
      this.isPatientSelected = state;
    },
    switchSelectedMedic(state, medicType = "medic") {
      this.isMedicSelected = state;
      this.medicType = medicType;
    },
    switchSelectedGeneral(state) {
      this.isMainGeneralInputSelected = state;
    },
    isValidRequiredValues() {
      if (
        this.$refs?.locationAndDate.isFormDataValid() &&
        this.$refs?.selectPatient.isFormDataValid() &&
        this.$refs?.generalInputs.isFormDataValid()
      ) {
        return true;
      }
      return false;
    },
    getAppointmentInfo() {
      if (
        this.isCEAppointmentCreate ||
        this.isCEAppointmentMenu ||
        !this.ceAppointmentEditID
      )
        return;
      const id = this.ceAppointmentEditID;
      this.$api.appointments.getInfo(id).then((response) => {
        this.isPatientSelected = true;
        this.$refs.selectPatient.filiation_comments =
          response.data.filiation_comments;
        this.$refs.locationAndDate.timeBeforeEdit = response.data.time.slice(
          0,
          -3
        );
        this.$refs.locationAndDate.dateBeforeEdit = this.$moment(
          response.data.date
        ).toDate();
        this.$refs.locationAndDate.clinicBeforeEdit = response.data.clinic_id;
        this.$refs.locationAndDate.medicBeforeEdit = {
          id: response.data.medic_id,
          name: response.data.medic_name,
          user_type: response.data.user_type,
        };
        this.$refs.locationAndDate.selectMedic(true, {
          item: {
            id: response.data.medic_id,
            name: response.data.medic_name,
            user_type: response.data.medic_user_type,
          },
        });
        this.setSelectedPatientID(response.data.patient_id);
        this.$refs.selectPatient.searchPatientByID();
        this.$refs.generalInputs.servicesListSelected = response.data.services;
        this.$refs.generalInputs.comments = response.data.comments;
      });
    },
    onSubmit() {
      if (this.isCEAppointmentCreate) {
        this.saveNewAppointment();
      } else {
        this.saveUpdateAppointment();
      }
    },
    createPayload() {
      const calendarData = this.$refs.locationAndDate.getCalendarData;
      const patientData = this.$refs.selectPatient.patientSelected;
      const payload = {
        date: calendarData.originalDate,
        time: calendarData.time,
        patient_id: patientData?.patient_id
          ? patientData?.patient_id
          : patientData?.id,
        comments: this.$refs.generalInputs.comments,
        services: this.$refs.generalInputs.servicesListSelected,
      };
      payload.medic_id = this.$refs.locationAndDate.medic_id;
      // Todo user_type doctor
      if (
        this.isMedicUser ||
        this.getUserType === "nutrition" ||
        this.getUserType === "psychology" ||
        this.isABCUserType
      ) {
        payload.medic_id = this.getUserID;
      }
      payload.clinic_id = this.$refs.locationAndDate.clinic_id;
      return payload;
    },
    saveUpdateAppointment() {
      if (!this.isValidRequiredValues()) return;
      const appointmentId = this.ceAppointmentEditID;
      const payload = this.createPayload();
      this.$api.appointments.update(appointmentId, payload).then(() => {
        if (this.$route.name === "calendar") {
          this.$emit("refreshCalendarDataByFilter", {
            medicID: payload.medic_id,
            agendaID: "all",
          });
        } else {
          this.$emit("refreshCalendarData");
        }

        this.$emit("resetComponent");
      });
    },
    saveNewAppointment() {
      if (!this.isValidRequiredValues()) return;
      const payload = this.createPayload();
      this.$api.appointments.create(payload).then(() => {
        if (this.$route.name === "calendar") {
          this.$emit("refreshCalendarDataByFilter", {
            medicID: payload.medic_id,
            agendaID: "all",
          });
        } else {
          this.$emit("refreshCalendarData");
        }
        this.$emit("resetComponent");
      });
    },
  },
};
</script>
