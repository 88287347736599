<template>
  <div class="ceAppointment__header my-5">
    <div
      class="row m-3 ms-4 mb-4"
      v-if="!isMedicSelected || isTypeUserAutoSelected">
      <h4 class="titles">{{ titleText }}.</h4>
      <!-- Clinic Info -->
      <div
        class="form-group col"
        v-if="!(isMedicUser || getUserType === 'nursing')">
        <label for="clinic" class="d-block">Sucursal</label>
        <select
          v-model="clinic_id"
          class="form-select w-auto inputsGeneralClass"
          @change="fillMedicList">
          <option
            v-for="clinic in getClinicList"
            :key="`${clinic.id}-${clinic.name}`"
            :value="clinic.id">
            {{ clinic.name }}
          </option>
        </select>
      </div>
      <!-- Clinic Info /-->
      <!-- Date Info -->
      <div class="form-group col">
        <label for="date" class="d-block">Fecha</label>
        <date-picker
          v-model="calendarData.dateEdit"
          @change="updateCalendarData"
          :input-class="'form-control inputsGeneralClass w-100'"
          :format="'DD/MM/YYYY'"
          :disabled-date="disabledDates"
          placeholder="Fecha" />
      </div>
      <!-- Date Info /-->
      <!-- Hour Info -->
      <div class="form-group col">
        <label for="hour" class="d-block">Hora</label>
        <select
          v-model="calendarData.time"
          class="form-select w-auto inputsGeneralClass"
          @change="updateCalendarDataTime">
          <option
            v-for="hour in getHours"
            :key="`${hour}-${hour}`"
            :value="hour">
            {{ hour }}
          </option>
        </select>
      </div>
      <!-- Hour Info /-->
    </div>
    <div
      v-if="
        !(
          isMedicUser ||
          getUserType === 'nutrition' ||
          getUserType === 'psychology' ||
          isABCUserType
        )
      "
      class="fadeIn">
      <div class="row m-3 ms-4">
        <template v-if="!isMedicSelected">
          <h4 class="titles">Disponibilidad de Médicos.</h4>
          <p class="text-primary">
            *Se muestran los médicos disponibles en base a
            <strong>sucursal, fecha y hora</strong>
            seleccionados.
          </p>
          <p v-if="!isCEAppointmentCreate && this.medicBeforeEdit">
            Médico seleccionado antes de editar:
            <span class="text-primary">{{ this.medicBeforeEdit.name }}</span>
          </p>
        </template>
        <h4 class="titles" v-else>Médico seleccionado.</h4>
        <transition name="fade" mode="out-in">
          <b-table
            :items="medicListBySelection"
            :fields="medicsFields"
            class="align-middle"
            emptyText="No hay médicos disponibles"
            show-empty
            borderless
            striped>
            <template v-slot:cell(user_type)="medic">
              <font-awesome-icon
                class="tableSizeIconIdUser"
                :title="titleByType(medic.item.user_type)"
                :class="colorIconByType(medic.item.user_type)"
                :icon="['fa', 'user-doctor']" />
            </template>
            <template v-slot:cell(actions)="medic">
              <button
                type="button"
                @click="
                  selectMedic(true, medic);
                  return false;
                "
                class="btn btnSelect"
                v-if="!isMedicSelected">
                Seleccionar
              </button>
              <button
                type="button"
                @click="
                  selectMedic(false, medic);
                  return false;
                "
                class="btn btnSelect"
                v-else>
                Deseleccionar
              </button>
            </template>
          </b-table>
        </transition>
        <div
          class="mx-auto grow"
          v-if="$v.medic_id.$error && $v.medic_id.required">
          <p class="text-center text-danger">
            Seleccionar un paciente es requerido
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { medicsFields } from "@/constants/appointments";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import clinicAndMedic from "@/mixin/clinicAndMedic.mixin";
import generalDateDefinition from "@/mixin/generalDateDefinition.mixin";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "LocationAndDate",
  mixins: [clinicAndMedic, generalDateDefinition],
  components: { DatePicker },
  props: {
    isMedicSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      timeBeforeEdit: null,
      dateBeforeEdit: null,
      clinicBeforeEdit: null,
      medicBeforeEdit: {},
      medicsFields,
    };
  },
  mounted() {
    if (this.getUserType !== "medic" && !this.isMedicSelected) {
      this.updateMedicListOnMounted(
        null,
        this.getUserType === "nursing" ? "both" : null
      );
    }
  },
  computed: {
    ...mapGetters([
      "getCalendarConfig",
      "getCalendarData",
      "isMedicUser",
      "isCEAppointmentCreate",
      "getUserType",
      "isABCUserType",
    ]),
    getHours() {
      // format('HH:mm:ss') 06:00

      const addHour = new Date(Date.now()).getMinutes() > 30;
      const actualHour = new Date(Date.now()).getHours();

      let initTime = new Date(
        `2023-01-01T${this.getCalendarConfig?.hour_ini}:00`
      );

      if (
        this.calendarData.dateEdit.toDateString() ===
        new Date(Date.now()).toDateString()
      ) {
        initTime = new Date(
          `2023-01-01T${addHour ? actualHour + 1 : actualHour}:${
            addHour ? "00" : "30"
          }:00`
        );
      }

      const endTime = new Date(
        `2023-01-01T${this.getCalendarConfig?.hour_end}:00`
      );

      // Inicializar la lista de horas
      const hours = [initTime];

      // Añadir todas las horas y medias horas entre init y end
      let currentTime = new Date(initTime);
      while (currentTime < endTime) {
        currentTime = new Date(currentTime.getTime() + 30 * 60000);
        hours.push(currentTime);
      }

      return hours.map((time) =>
        time.toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    },
    isTypeUserAutoSelected() {
      const usersWithAutoSelect = [
        "medic",
        "nutrition",
        "psychology",
        "abc",
        "manager_abc",
      ];
      return usersWithAutoSelect.includes(this.getUserType);
    },
    getMinDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    getMaxDate() {
      return this.getCalendarConfig?.date_end
        ? this.getCalendarConfig?.date_end
        : this.$moment().format("YYYY-MM-DD");
    },
    medicListBySelection() {
      if (!this.isMedicSelected) {
        return this.medicList;
      }
      return [this.medicList.find((medic) => medic.id === this.medic_id)];
    },
    calendarData: {
      get() {
        return this.getCalendarData;
      },
      set(newValues) {
        this.updateCalendarDataAppointments({ ...newValues });
      },
    },
    isSameFilterBeforeEdit() {
      return (
        this.clinicBeforeEdit === this.clinic_id &&
        this.dateBeforeEdit?.getTime() ===
          this.calendarData.dateEdit?.getTime() &&
        this.timeBeforeEdit === this.calendarData.time
      );
    },
    isSomeFilterDiffBeforeEdit() {
      return (
        this.clinicBeforeEdit !== this.clinic_id &&
        this.dateBeforeEdit?.getTime() !==
          this.calendarData.dateEdit?.getTime() &&
        this.timeBeforeEdit !== this.calendarData.time
      );
    },
    isOtherMedicAvailableBeforeEditData() {
      return !(
        (this.medicList.length === 0 && this.isSameFilterBeforeEdit) ||
        (this.medicList.length === 1 &&
          this.medicList.some((medic) => medic.id === this.medicBeforeEdit?.id))
      );
    },
    isNotMedicAvailableWithOtherEditData() {
      if (this.isCEAppointmentCreate) {
        return this.medicList.length === 0;
      }
      return this.medicList.length === 0 && this.isSomeFilterDiffBeforeEdit;
    },
    titleText() {
      if (this.isMedicUser) return "Horario";
      return "Lugar y Horario";
    },
  },
  validations: {
    clinic_id: {
      required,
    },
    medic_id: {
      required: requiredIf(function medicId() {
        return (
          !!this.medicBeforeEdit &&
          !(
            this.isMedicUser ||
            this.getUserType === "nutrition" ||
            this.getUserType === "psychology" ||
            this.isABCUserType
          )
        );
      }),
    },
    calendarData: {
      dateEdit: {
        required,
      },
      time: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["updateCalendarDataAppointments"]),
    disabledDates(date) {
      const noDateEndUserTypes = ["superadmin", "medic", "nursing"];
      if (noDateEndUserTypes.includes(this.getUserType)) {
        return date < new Date(this.getMinDate);
      }
      return (
        date < new Date(this.getMinDate) || date > new Date(this.getMaxDate)
      );
    },
    titleByType(userType) {
      if (userType === "medic") return "Médico";
      return "Médico Externo";
    },
    colorIconByType(userType) {
      if (userType === "medic") return "text-primary";
      return "text-danger";
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    // eslint-disable-next-line no-unused-vars
    generatePayload(info = null, agendaType = null) {
      const payload = {
        clinic_id: this.clinic_id,
        date: this.calendarData.dateEdit,
        time: this.calendarData.time,
        agenda_type: agendaType,
      };
      return payload;
    },
    selectMedic(isSelect, medicSelected) {
      this.$emit("switchSelectedMedic", isSelect, medicSelected.item.user_type);
      if (!this.isCEappointmentCreate && isSelect) {
        this.medicList = [medicSelected.item];
      } else if (!this.isCEappointmentCreate && !isSelect) {
        this.fillMedicList(isSelect);
      }
      if (isSelect) {
        this.medic_id = medicSelected.item.id;
      }
    },
    async updateCalendarData(data) {
      await this.defineDateTimeAppointment(data, this.calendarData.time);
      if (
        this.isMedicUser ||
        this.getUserType === "nutrition" ||
        this.getUserType === "psychology" ||
        this.isABCUserType
      )
        return;

      if (this.getUserType !== "medic") {
        this.fillMedicList();
      }
    },
    async updateCalendarDataTime() {
      await this.defineDateTimeAppointment(
        this.calendarData.dateEdit,
        this.calendarData.time
      );
      if (
        this.isMedicUser ||
        this.getUserType === "nutrition" ||
        this.getUserType === "psychology" ||
        this.isABCUserType
      )
        return;
      this.fillMedicList();
    },
  },
};
</script>
