import Vue from "vue";
import Vuex from "vuex";
import state from "./global/state";
import mutations from "./global/mutations";
import actions from "./global/actions";
import getters from "./global/getters";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
});
