export default (axios) => ({
  getHistoricals(payload) {
    return axios.get("v1/patient_historicals", { params: payload });
  },
  getHistoricalLast(payload) {
    return axios.get("v1/patient_historical_last", { params: payload });
  },
  getCompleteHistoryDetail(id) {
    return axios.get(`v1/complete_history_detail/${id}`);
  },
  postHistorical(payload) {
    return axios.post(`v1/patient_historical`, payload);
  },
  getHistorical(id) {
    return axios.get(`v1/patient_historical/${id}`);
  },
  putHistorical(id, payload) {
    return axios.put(`v1/patient_historical/${id}`, payload);
  },
});
