export default (axios) => ({
  getRecord(id) {
    return axios.get(`v1/patient_record/${id}`);
  },
  putRecord(id, payload) {
    return axios.put(`v1/patient_record/${id}`, payload);
  },
  getRecordPartner(id) {
    return axios.get(`v1/patient_record_partner/${id}`);
  },
  putRecordPartner(id, payload) {
    return axios.put(`v1/patient_record_partner/${id}`, payload);
  },
  getRecordGynecology(id) {
    return axios.get(`v1/patient_record_gynecology/${id}`);
  },
  putRecordGynecology(id, payload) {
    return axios.put(`v1/patient_record_gynecology/${id}`, payload);
  },
  getRecordMenstrual(id) {
    return axios.get(`v1/patient_record_menstrual/${id}`);
  },
  putRecordMenstrual(id, payload) {
    return axios.put(`v1/patient_record_menstrual/${id}`, payload);
  },
});
