import { mapGetters } from "vuex";
import VueCal from "vue-cal";
import "vue-cal/dist/i18n/es";
import "vue-cal/dist/vuecal.css";
import CalendarFilter from "@/components/calendar/Filter.vue";
import EventCard from "@/components/calendar/EventCard.vue";

export default {
  components: {
    VueCal,
    CalendarFilter,
    EventCard,
  },
  data() {
    return {
      scheduleByUserSelected: {},
      disableViews: ["years", "year"],
      activeView: "week",
      tabletSize: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.sizeWindow);
    this.sizeWindow();
  },
  computed: {
    ...mapGetters(["getCalendarConfig", "getCalendarData", "getUserType"]),
    disableViewsData() {
      return this.disableViews;
    },
    activeViewData() {
      return this.activeView;
    },
    tabletSizeData() {
      return this.tabletSize;
    },
    getMinDate() {
      return this.getCalendarConfig?.date_ini
        ? this.getCalendarConfig?.date_ini
        : this.$moment().format("YYYY-MM-DD");
    },
    getMaxDate() {
      const noDateEndUserTypes = ["superadmin", "medic", "nursing"];
      if (noDateEndUserTypes.includes(this.getUserType)) {
        return false;
      }
      return this.getCalendarConfig?.date_end
        ? this.getCalendarConfig?.date_end
        : this.$moment().format("YYYY-MM-DD");
    },
    getTimeInit() {
      const isScheduleDefine =
        Object.keys(this.scheduleByUserSelected).length > 0;
      if (!isScheduleDefine) {
        const timeInit = this.getCalendarConfig?.hour_ini?.split(":");
        if (timeInit) {
          return (
            parseFloat(
              `${timeInit[0]}${timeInit[1].includes("3") ? ".5" : ""}`
            ) * 60
          );
        }
      } else {
        const arrInitHours = Object.keys(this.scheduleByUserSelected).map(
          (key) => {
            return this.scheduleByUserSelected[key].from;
          }
        );
        return Math.min(...arrInitHours);
      }

      return 9 * 60;
    },
    getTimeEnd() {
      const isScheduleDefine =
        Object.keys(this.scheduleByUserSelected).length > 0;
      if (!isScheduleDefine) {
        const timeEnd = this.getCalendarConfig?.hour_end?.split(":");
        if (timeEnd) {
          return (
            parseFloat(`${timeEnd[0]}${timeEnd[1].includes("3") ? ".5" : ""}`) *
            60
          );
        }
      } else {
        const arrEndHours = Object.keys(this.scheduleByUserSelected).map(
          (key) => {
            return this.scheduleByUserSelected[key].to;
          }
        );
        return Math.max(...arrEndHours);
      }

      return 20 * 60;
    },
  },
  methods: {
    sizeWindow() {
      if (window.innerWidth <= 768) {
        this.disableViews = ["years", "year", "month", "week"];
        this.activeView = "day";
        this.tabletSize = true;
      } else {
        this.disableViews = ["years", "year"];
        this.activeView = "week";
        this.tabletSize = false;
      }
    },
    getNumberMinute(hour) {
      return parseInt(hour.split(":")[1], 10);
    },
    getNumberHour(hour) {
      return parseInt(hour.split(":")[0], 10);
    },
    generateAvailableHoursByUser(schedule) {
      let hourInit;
      let hourEnd;
      this.scheduleByUserSelected = {};
      for (let index = 1; index <= 7; index += 1) {
        hourInit = {
          hour: schedule[index].from
            ? this.getNumberHour(schedule[index].from)
            : null,
          minute: schedule[index].from
            ? this.getNumberMinute(schedule[index].from)
            : null,
        };
        hourEnd = {
          hour: schedule[index].to
            ? this.getNumberHour(schedule[index].to)
            : null,
          minute: schedule[index].to
            ? this.getNumberMinute(schedule[index].to)
            : null,
        };
        hourInit = this.validateAvailableHoursByUser(hourInit);
        hourEnd = this.validateAvailableHoursByUser(hourEnd);
        if (hourInit && hourEnd) {
          this.scheduleByUserSelected[index] = {
            from: hourInit ? hourInit.hour * 60 + hourInit.minute : 0,
            to: hourEnd ? hourEnd.hour * 60 + hourEnd.minute : 0,
            class: "business-hours",
          };
        }
      }
    },
    validateAvailableHoursByUser(hour) {
      if (hour.hour === null || !hour.minute === null) return null;
      return hour;
    },
    generateAvailableHours(schedule) {
      const hourInitWD = {
        hour: this.getNumberHour(schedule.weekday.hour_ini),
        minute: this.getNumberMinute(schedule.weekday.hour_ini),
      };
      const hourEndWD = {
        hour: this.getNumberHour(schedule.weekday.hour_end),
        minute: this.getNumberMinute(schedule.weekday.hour_end),
      };
      const hourInitWE = {
        hour: this.getNumberHour(schedule.weekend.hour_ini),
        minute: this.getNumberMinute(schedule.weekend.hour_ini),
      };
      const hourEndWE = {
        hour: this.getNumberHour(schedule.weekend.hour_end),
        minute: this.getNumberMinute(schedule.weekend.hour_end),
      };

      const dailyHours = {
        weekday: {
          from: hourInitWD.hour * 60 + hourInitWD.minute,
          to: hourEndWD.hour * 60 + hourInitWD.minute,
          class: "business-hours",
        },
        weekend: {
          from: hourInitWE.hour * 60 + hourInitWE.minute,
          to: hourEndWE.hour * 60 + hourInitWE.minute,
          class: "business-hours",
        },
      };
      this.scheduleByUserSelected = {
        1: dailyHours.weekday,
        2: dailyHours.weekday,
        3: dailyHours.weekday,
        4: dailyHours.weekday,
        5: dailyHours.weekday,
        6: dailyHours.weekend,
        7: dailyHours.weekend,
      };
    },
    isInsideTheAvailableHours() {
      const date = this.getCalendarData.dateEdit;
      const hourSelected = parseInt(
        this.getCalendarData.time.split(":")[0],
        10
      );
      const minuteSelected = parseInt(
        this.getCalendarData.time.split(":")[1],
        10
      );
      const timeSelected = hourSelected * 60 + minuteSelected;
      const start =
        this.scheduleByUserSelected[date.getDay() === 0 ? 7 : date.getDay()]
          .from;
      const end =
        this.scheduleByUserSelected[date.getDay() === 0 ? 7 : date.getDay()].to;
      return start <= timeSelected && timeSelected < end;
    },
    updateAvailableHours(data) {
      if (data === "clinic") {
        this.updateAvailableHoursByClinic();
      } else {
        this.updateAvailableHoursByMedicSchedule(data);
      }
    },
    updateAvailableHoursByClinic() {
      if (!this.getCalendarConfig) return;
      const availableHoursTemplate = {
        weekday: {
          hour_ini: this.getCalendarConfig.hour_ini,
          hour_end: this.getCalendarConfig.hour_end,
        },
        weekend: {
          hour_ini: this.getCalendarConfig.hour_ini,
          hour_end: this.getCalendarConfig.hour_end,
        },
      };
      this.generateAvailableHours(availableHoursTemplate);
    },
    updateAvailableHoursByMedicSchedule(medicID) {
      if (!medicID) return;
      this.$api.appointments.getMedicSchedule(medicID).then((response) => {
        this.generateAvailableHoursByUser(response.data.days);
      });
    },
  },
};
