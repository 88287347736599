const states = [
  { key: "Baja California", name: "Baja California" },
  { key: "Aguascalientes", name: "Aguascalientes" },
  { key: "Baja California Sur", name: "Baja California Sur" },
  { key: "Campeche", name: "Campeche" },
  { key: "Ciudad de México", name: "Ciudad de México" },
  { key: "Coahuila", name: "Coahuila" },
  { key: "Colima", name: "Colima" },
  { key: "Chiapas", name: "Chiapas" },
  { key: "Chihuahua", name: "Chihuahua" },
  { key: "Durango", name: "Durango" },
  { key: "Estado de México", name: "Estado de México" },
  { key: "Guanajuato", name: "Guanajuato" },
  { key: "Guerrero", name: "Guerrero" },
  { key: "Hidalgo", name: "Hidalgo" },
  { key: "Jalisco", name: "Jalisco" },
  { key: "Michoacán", name: "Michoacán" },
  { key: "Morelos", name: "Morelos" },
  { key: "Nayarit", name: "Nayarit" },
  { key: "Nuevo León", name: "Nuevo León" },
  { key: "Oaxaca", name: "Oaxaca" },
  { key: "Puebla", name: "Puebla" },
  { key: "Querétaro", name: "Querétaro" },
  { key: "Quintana Roo", name: "Quintana Roo" },
  { key: "San Luis Potosí", name: "San Luis Potosí" },
  { key: "Sinaloa", name: "Sinaloa" },
  { key: "Sonora", name: "Sonora" },
  { key: "Tabasco", name: "Tabasco" },
  { key: "Tamaulipas", name: "Tamaulipas" },
  { key: "Tlaxcala", name: "Tlaxcala" },
  { key: "Veracruz", name: "Veracruz" },
  { key: "Yucatán", name: "Yucatán" },
  { key: "Zacatecas", name: "Zacatecas" },
];

export { states };
