<template>
  <div class="row" v-if="!getCalendarData.length">
    <div class="col-6 col-lg-4 my-3">
      <label class="me-3">Sucursal</label>
      <span class="filterSearch text-center px-5 py-3">{{
        clinicNameFormat
      }}</span>
    </div>
    <div class="col-6 col-lg-4 my-3">
      <label class="me-3">Fecha</label>
      <span class="filterSearch text-center px-5 py-3">{{
        getCalendarData.date
      }}</span>
    </div>
    <div class="col-6 col-lg-4 my-3">
      <label class="me-3">Hora</label>
      <span class="filterSearch text-center px-5 py-3">{{
        getCalendarData.time
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import generalDateDefinition from "@/mixin/generalDateDefinition.mixin";

export default {
  name: "HeaderAppointment",
  mixins: [generalDateDefinition],
  props: {
    clinicName: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getCalendarData"]),
    clinicNameFormat() {
      if (this.clinicName === "Ciudad de México") {
        return "CDMX";
      }
      return this.clinicName;
    },
  },
};
</script>
