export default (axios) => ({
  history(payload) {
    return axios.get("v1/appointment_history_search", { params: payload });
  },
  rejectSecondOpinion(id) {
    return axios.post(`v1/appointment_paid_refused/${id}`);
  },
  removePaymentEvidence(id) {
    return axios.delete("v1/appointment_paid", {
      params: { appointment_id: id },
    });
  },
  update(id, payload) {
    return axios.put(`v1/appointments/${id}`, payload);
  },
  authConfirmation(id) {
    return axios.post(`v1/appointment_cash_register_confirmation/${id}`);
  },
  currentAppointment(payload) {
    return axios.get("v1/current_appointment", { params: payload });
  },
  create(payload) {
    return axios.post("v1/appointments", payload);
  },
  cancel(id, payload) {
    return axios.post(`v1/appointment_cancel/${id}`, payload);
  },
  confirmation(id) {
    return axios.post(`v1/appointment_confirmation/${id}`);
  },
  getInfo(id) {
    return axios.get(`v1/appointment/${id}`);
  },
  getAppointmentMedic(id) {
    return axios.get(`v1/appointment_medic/${id}`);
  },
  getAvailableMedics(payload) {
    return axios.get("v1/available_medics", { params: payload });
  },
  getMedicSchedule(id) {
    return axios.get(`v1/medic_schedule/${id}`);
  },
  createFiliationExternal(payload) {
    return axios.post("v1/appointment_external", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  createFiliation(payload) {
    return axios.post("v1/appointments", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateFiliation(payload, id) {
    return axios.put(`v1/filiation/${id}`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteSecondOpinionDocuments(id) {
    return axios.put(`v1/delete_second_opinion_documents/${id}`);
  },
  updateContactMedium(payload, id) {
    return axios.put(`v1/contact_medium/${id}`, payload);
  },
  getFiliation(id) {
    return axios.get(`v1/filiation/${id}`);
  },
  createAppointmentPaid(payload) {
    return axios.post("v1/appointment_paid", payload);
  },
  updateAppointmentPaid(payload) {
    return axios.put(`v1/appointment_paid`, payload);
  },
  newFiliation() {
    return axios.get("v1/new_filiation");
  },
  deleteFiliation(patientID) {
    return axios.post("v1/delete_filiation", patientID);
  },
  getOccupations() {
    return axios.get("v1/occupations");
  },
  getOccupationsCouple() {
    return axios.get("v1/occupations_partner");
  },
  getHistoryPatient(id, payload) {
    return axios.get(`v1/appointment_history/${id}`, { params: payload });
  },
});
