const authHeaderBlackList = [];

const loaderBlackList = [];

const publicRoutes = ["login"];

const userTypes = {
  superadmin: "Super admin",
  admin: "Admin",
  manager: "Manager",
  abc: "ABC",
  manager_abc: "ABC",
  control: "Control",
  reception: "Recepción",
  cash_register: "Caja registradora",
  external: "Externo",
  operating_room: "Quirófano",
  medic: "Médico",
  nursing: "Enfermería",
  nutrition: "Nutrición",
  psychology: "Psicología",
  laboratory: "Laboratorio",
  manager_cacit: "Líder Cacit",
  cacit: "Cacit",
  external_medic: "Médico externo",
  external_anesthesiologist: "Anestesiólogo externo",
  external_gestational_gynecologist: "G.G. externo",
};

export { loaderBlackList, publicRoutes, authHeaderBlackList, userTypes };
