export default (axios) => ({
  getDataMedic(payload) {
    return axios.get("v1/graphics_medic", { params: payload });
  },
  getDataBoxesMedic(payload) {
    return axios.get("v1/graphics_boxes_medic", { params: payload });
  },
  getDataCacit(payload) {
    return axios.get("v1/graphics_cacit", { params: payload });
  },
  getDataBoxesCacit(payload) {
    return axios.get("v1/graphics_boxes_cacit", { params: payload });
  },
  getDataMedicConfirms(payload) {
    return axios.get("v1/graphics_medic_confirm", { params: payload });
  },
  getDataMedicStatus(payload) {
    return axios.get("v1/graphics_medic_status", { params: payload });
  },
  getDataABCTasks(payload) {
    return axios.get("v1/graphics_abc_status", { params: payload });
  },
});
