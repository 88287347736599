var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'Patient',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('Patient')}}},[_vm._v(" Datos de Paciente ")])]),_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'Couple',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('Couple')}}},[_vm._v(" Datos de Pareja ")])]),_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link pointer",class:{
        active: _vm.type === 'Address',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },on:{"click":function($event){return _vm.changeActiveTab('Address')}}},[_vm._v(" Dirección ")])]),_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"nav-link",class:{
        active: _vm.type === 'Contact',
        pointer: !_vm.isEditActive,
        disabled: _vm.isEditActive,
      },attrs:{"disabled":!_vm.isEditActive},on:{"click":function($event){return _vm.changeActiveTab('Contact')}}},[_vm._v(" Contacto ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }