var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('CalendarFilter',{ref:"CalendarFilter",attrs:{"tabletSize":_vm.tabletSizeData},on:{"searchByFilter":_vm.getAppointments,"updateAvailableHours":_vm.updateAvailableHours}}),_c('vue-cal',{ref:"calendar",staticStyle:{"min-height":"80vh"},attrs:{"today-button":"","editable-events":{
      title: true,
      drag: false,
      resize: false,
      delete: false,
      create: true,
    },"min-date":_vm.getMinDate,"max-date":_vm.getMaxDate,"time-cell-height":90,"time-from":_vm.getTimeInit,"time-to":_vm.getTimeEnd,"time-step":30,"special-hours":_vm.scheduleByUserSelected,"disable-views":_vm.disableViewsData,"active-view":_vm.activeViewData,"events":_vm.events,"selected-date":_vm.selectedDate,"on-event-click":_vm.openEventEdit,"drag-to-create-event":false,"drag-to-edit-event":false,"cell-click-hold":false,"locale":"es"},on:{"view-change":_vm.navigateCalendar,"cell-click":_vm.openEventCreate},scopedSlots:_vm._u([{key:"event",fn:function(ref){
    var event = ref.event;
return [_c('EventCard',{attrs:{"event":event}})]}},{key:"cell-content",fn:function(ref){
    var view = ref.view;
    var events = ref.events;
return [(['week', 'day'].includes(view.id) && !events.length)?_c('span',{staticClass:"vuecal__no-event"},[_vm._v(" No hay eventos ")]):_vm._e()]}}])}),_c('Preview',{key:_vm.keyPreview,on:{"resetComponent":function($event){return _vm.resetComponent('preview')}}}),_c('MenuCE',{key:_vm.keyMenu,attrs:{"type":_vm.typeMenu},on:{"resetComponent":function($event){return _vm.resetComponent('menu')},"openEditAppointment":_vm.openEditAppointment,"openPreview":_vm.openPreview,"createBySearchPatient":_vm.createBySearchPatient,"createByNewFiliation":_vm.createByNewFiliation,"createByExternalPatient":_vm.createByExternalPatient,"cancelAppointment":_vm.cancelAppointment}}),_c('CancelAppointment',{key:_vm.keyCancelappointment,ref:"cancelAppointment",on:{"refreshCalendarData":_vm.getAppointments,"refreshCalendarDataByFilter":_vm.getAppointmentsByFilter,"resetComponent":function($event){return _vm.resetComponent('cancelappointment')}}}),_c('CEAppointment',{key:_vm.keyCeappointment,ref:"ceAppointmentParent",on:{"refreshCalendarData":_vm.getAppointments,"refreshCalendarDataByFilter":_vm.getAppointmentsByFilter,"resetComponent":function($event){return _vm.resetComponent('ceappointment')}}}),(_vm.isCacitType || _vm.isSuperAdmin || _vm.isNurse)?_c('CEFiliation',{key:_vm.keyCefiliation,attrs:{"typeAction":""},on:{"refreshCalendarData":_vm.getAppointments,"refreshCalendarDataByFilter":_vm.getAppointmentsByFilter,"resetComponent":function($event){return _vm.resetComponent('cefiliation')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }