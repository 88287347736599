export default (axios) => ({
  getFiles(payload) {
    return axios.get("v1/patient_files", { params: payload });
  },
  getFile(id) {
    return axios.get(`v1/patient_file/${id}`);
  },
  postFile(payload) {
    return axios.post("v1/patient_file", payload);
  },
  getPDF(id) {
    return axios.get(`/v1/patient_pdf/${id}`);
  },
});
