<template>
  <div class="bMenuSide" :class="{ active: getToogleBMenu }">
    <div class="bMenuSide__overlay"></div>
    <div class="bMenuSide__list">
      <div class="bMenuSide__list--logo">
        <img
          width="50"
          height="50"
          :src="require('@/assets/images/sidebarlogo.png')"
          alt="" />
      </div>
      <div class="bMenuSide__list--user">
        <font-awesome-icon
          class="headerIcon col-2"
          :icon="['fas', 'user-circle']" />
        <div class="me-4 fs-5">
          <p class="mb-0">{{ userName }}</p>
          <p class="mb-0">{{ userTypes[userType] }}</p>
        </div>
      </div>
      <div class="bMenuSide__list--options">
        <template v-for="(option, index) in menuOptions">
          <div
            class="bOption pointer"
            v-if="validateUserPermission(index)"
            @click="changeRoute(option.route)"
            :key="index + option.title">
            <div
              class="bOption__content"
              :class="{ selectedItem: validateIsSelected(option.route) }">
              <font-awesome-icon
                class="headerIcon col-2"
                :icon="[option.logoSource, option.logo]" />
              <p class="title">{{ option.title }}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import menuOptions from "@/constants/sidebar";
import { logOut } from "@/helpers/auth";
import { mapGetters, mapMutations } from "vuex";
import { userTypes } from "@/constants/auth";

export default {
  name: "BurgerMenu",
  data() {
    return {
      menuOptions,
      userTypes,
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getToogleBMenu",
      "getPermissionRoutesByUserType",
    ]),
    userName() {
      const userInfo = this.getUserProfile;
      return `${userInfo?.name} ${userInfo?.last_name}`;
    },
    userType() {
      const userInfo = this.getUserProfile;
      return `${userInfo?.user_type}`;
    },
    toogle: {
      get() {
        return this.getToogleBMenu;
      },
      set(value) {
        this.bMenuChangeState(value);
      },
    },
  },
  methods: {
    ...mapMutations(["bMenuChangeState"]),
    validateIsSelected(parentRoute) {
      const route = this.$route.name;
      return route ? route.includes(parentRoute) : false;
    },
    changeRoute(routeToGo) {
      const body = document.getElementsByTagName("body")[0];
      if (routeToGo === this.$route.name) return;
      if (routeToGo === "logOut") {
        this.toogle = !this.toogle;
        body.removeAttribute("class", "overflow-hidden");
        logOut();
        return;
      }
      if (routeToGo === "patient") {
        this.toogle = !this.toogle;
        body.removeAttribute("class", "overflow-hidden");
        this.$router.push({
          name: routeToGo,
          params: { id: 1 },
        });
      } else {
        this.toogle = !this.toogle;
        body.removeAttribute("class", "overflow-hidden");
        this.$router.push({
          name: routeToGo,
        });
      }
    },
    validateUserPermission(index) {
      const menuOption = this.menuOptions[index];
      if (menuOption.route === "logOut") return true;
      return this.getPermissionRoutesByUserType.includes(menuOption.route);
    },
  },
};
</script>

<style></style>
