<template>
  <div class="d-flex justify-content-between align-items-end calendarFilter">
    <div class="row">
      <div class="col" v-if="hasPermission.toCalendarFilterByMedic">
        <!-- Medic Select -->
        <label for="medicID"> Médico </label>
        <select
          v-model="medicID"
          class="form-select inputsGeneralClass"
          @change="$emit('searchByFilter')">
          <option value="all" v-if="medicList.length > 1">Todos</option>
          <option
            v-for="medic in medicList"
            :key="`${medic.id}-${medic.name}`"
            :value="medic.id">
            {{ medic.name }}
          </option>
        </select>
        <!-- Medic Select / -->
      </div>
      <div class="col" v-if="hasPermission.toCalendarFilterByAgenda">
        <!-- Schedule Select -->
        <label for="schedule"> Agenda </label>
        <select
          v-model="scheduleID"
          class="form-select inputsGeneralClass"
          @change="$emit('searchByFilter')">
          <option value="all" v-if="validSchedulesLen">Todo</option>
          <option
            v-for="schedule in getUserSchedules"
            :key="`${schedule.id}-${schedule.name}`"
            :value="schedule.id">
            {{ schedule.name }}
          </option>
        </select>
        <!-- Schedule Select / -->
      </div>
      <div class="col">
        <!-- Status Select -->
        <label for="status"> Estado de la Cita </label>
        <select
          v-model="status"
          class="form-select inputsGeneralClass"
          @change="$emit('searchByFilter')">
          <option value="untaken">Pendiente</option>
          <option value="taken">Completada</option>
          <option value="cancelled">Cancelada</option>
        </select>
        <!-- Status Select / -->
      </div>
    </div>
    <div v-if="tabletSize">
      <date-picker
        v-model="date"
        @change="changeDateCalendar"
        :format="'DD/MM/YYYY'"
        :input-class="'form-control inputsGeneralClass w-100'"
        placeholder="Seleccione una fecha" />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { hasPermissionCEAppointments } from "@/constants/permissions";

export default {
  name: "CalendarFilter",
  props: {
    tabletSize: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      date: new Date(),
      status: "untaken",
      hasPermissionCEAppointments,
    };
  },
  mounted() {
    this.getMedicListByClinicID();
    if (!this.getUserSchedules) return;
    this.scheduleID = "all";
  },
  computed: {
    ...mapGetters([
      "getCalendarFilterMedic",
      "getCalendarFilterMedicList",
      "getCalendarFilterSchedule",
      "getUserSchedules",
      "getUserType",
      "getClinic",
      "isCacitType",
      "isMedicUser",
    ]),
    validSchedulesLen() {
      return this.getUserSchedules?.length > 1;
    },
    hasPermission() {
      return this.hasPermissionCEAppointments[this.getUserType] || false;
    },
    medicList: {
      get() {
        return this.getCalendarFilterMedicList;
      },
      set(value) {
        this.setCalendarFilterMedicList(value);
      },
    },
    medicID: {
      get() {
        return this.getCalendarFilterMedic;
      },
      set(value) {
        if (value !== "all" && !this.isMedicUser) {
          this.$emit("updateAvailableHours", value);
        } else {
          this.$emit("updateAvailableHours", "clinic");
        }
        this.setCalendarFilterMedic(value);
      },
    },
    scheduleID: {
      get() {
        return this.getCalendarFilterSchedule;
      },
      set(value) {
        this.setCalendarFilterSchedule(value);
      },
    },
  },
  watch: {
    getClinic() {
      this.getMedicListByClinicID();
    },
  },
  methods: {
    ...mapMutations([
      "setCalendarFilterMedic",
      "setCalendarFilterSchedule",
      "setCalendarFilterMedicList",
    ]),
    ...mapActions(["updateCalendarFilterMedic"]),
    getMedicListByClinicID() {
      this.medicID = "all";
      if (!this.hasPermission.toCalendarFilterByMedic) return;
      const payload = {
        clinic_id: this.getClinic,
      };
      if (this.getUserType === "nursing" || this.getUserType === "reception") {
        payload.agenda_type = "both";
      }
      this.$api.settings.getMedicsByClinic(payload).then((res) => {
        this.updateCalendarFilterMedic(res.data.medics);
        if (!this.isMedicUser) {
          this.$emit("updateAvailableHours", "clinic");
        }
        this.$emit("searchByFilter");
      });
    },
    generatePayload() {
      const payload = {
        clinic_id: this.clinic_id,
      };
      return payload;
    },
    onChangeSearch() {
      this.$emit("searchByFilter");
    },
    changeDateCalendar(data) {
      this.$parent.selectedDate = data;
    },
  },
};
</script>
