<template>
  <b-modal
    ref="patientAppointmentPreview"
    id="patientAppointmentPreview"
    content-class="px-5 py-4"
    size="xl"
    @shown="getAppointmentInfo"
    hide-header-close
    hide-footer
    hide-header>
    <template #default>
      <div class="d-flex justify-content-between">
        <div class="col">
          <h4 class="titles blueCitmer">Información Cita</h4>
        </div>
        <div class="d-flex justify-content-end align-items-center col text-end">
          <span class="me-3" v-text="translateStatus(status)"></span>
          <div class="statusPoint" :class="colorStatus(status)"></div>
        </div>
      </div>
      <hr />
      <!-- HEADER -->
      <HeaderAppointment class="my-4" :clinicName="clinicName" />
      <hr />
      <!-- HEADER / -->
      <h5>Paciente</h5>
      <b-table
        :items="patients"
        :fields="fieldsPreviewPatients"
        class="align-middle"
        :table-class="'patientsHeadClass'"
        borderless
        striped>
      </b-table>
      <b-table
        :items="[medicBeforeEdit]"
        :fields="medicFields"
        class="align-middle"
        :table-class="'patientsHeadClass'"
        borderless
        striped>
      </b-table>
      <b-table
        :items="patients"
        :fields="commentFields"
        class="align-middle"
        :table-class="'patientsHeadClass'"
        emptyText="No se encontró el comentario"
        borderless
        striped>
        <template v-slot:cell(filiation_comments)="row">
          <div>
            <pre
              style="white-space: pre-wrap"
              v-text="row.item.filiation_comments"></pre>
          </div>
        </template>
      </b-table>
      <hr />
      <h5>Servicios</h5>
      <b-table
        :items="services"
        :fields="servicesFields"
        :table-class="'patientsHeadClass'"
        class="mt-1 align-middle"
        borderless
        striped>
      </b-table>
      <hr />
      <h5>Extras</h5>
      <b-table
        :items="comments"
        :fields="commentsFields"
        :table-class="'patientsHeadClass'"
        class="mt-1 align-middle"
        borderless
        striped>
        <template v-slot:cell(comments)="row">
          <div>
            <pre style="white-space: pre-wrap" v-text="row.item.comments"></pre>
          </div>
        </template>
      </b-table>
    </template>
  </b-modal>
</template>

<script>
import {
  previewPatientsFields,
  servicesFields,
  clinicsNames,
  commentsFields,
} from "@/constants/appointments";
import { mapGetters } from "vuex";
import HeaderAppointment from "@/components/shared/appointments/HeaderDateTime.vue";

export default {
  name: "AppointmentPreview",
  components: {
    HeaderAppointment,
  },
  data() {
    return {
      commentFields: [
        {
          key: "filiation_comments",
          label: "Comentario de Filiación",
        },
      ],
      medicFields: [
        {
          key: "name",
          label: "Nombre del medico asignado",
        },
      ],
      patients: [],
      services: [],
      previewPatientsFields,
      medicBeforeEdit: {},
      servicesFields,
      commentsFields,
      clinicsNames,
      clinic_id: null,
      comments: [],
      status: "",
    };
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "patientAppointmentPreview",
      ref: this.$refs.patientAppointmentPreview,
    });
  },
  computed: {
    ...mapGetters([
      "getCalendarData",
      "ceAppointmentEditID",
      "hasPermissionToViewContactTel",
    ]),
    isAgendaProspeccion() {
      return this.services.some((service) => service.agenda_id === 1);
    },
    fieldsPreviewPatients() {
      if (
        !this.hasPermissionToViewContactTel &&
        !this.patients.nec &&
        this.isAgendaProspeccion
      ) {
        return this.previewPatientsFields.filter(
          (field) => field.key !== "contact_tel"
        );
      }
      return this.previewPatientsFields;
    },
    clinicName() {
      return this.clinicsNames.find((clinic) => clinic.id === this.clinic_id)
        ?.name;
    },
  },
  methods: {
    getAppointmentInfo() {
      const id = this.ceAppointmentEditID;
      this.$api.appointments.getInfo(id).then((response) => {
        this.clinic_id = response.data.clinic_id;
        this.patients = [
          {
            nec: response.data.patient_nec,
            name: response.data.patient_name,
            contact_tel: response.data.patient_contact_tel,
            filiation_comments: response.data.filiation_comments,
          },
        ];
        this.status = response.data.status;
        this.comments = [{ comments: response.data.comments }];
        this.services = response.data.services;
        this.medicBeforeEdit = {
          id: response.data.medic_id,
          name: response.data.medic_name,
        };
      });
    },
    translateStatus(status) {
      switch (status) {
        case "confirmed":
          return "Confirmada";
        case "on_hold":
          return "En espera";
        case "expired":
          return "Expirada";
        default:
          return "";
      }
    },
    colorStatus(status) {
      switch (status) {
        case "confirmed":
          return "confirmedCitmer";
        case "on_hold":
          return "onHoldCitmer";
        case "expired":
          return "expiredCitmer";
        default:
          return "";
      }
    },
  },
};
</script>

<style></style>
