/** User Permissions
 * By user type
 * ---- routes: routes that the user type key has access.
 * ---- routes: first route is the home for the user type key.
 */
const userPermission = {
  superadmin: {
    routes: [
      "users",
      "calendar",
      "metrics",
      "filiations",
      "patients",
      "patients.detail",
      "laboratories",
      "migrations",
      "settings",
      "surgery",
      "historyappointments",
    ],
  },
  admin: {
    routes: [
      "calendar",
      "metrics",
      "filiations",
      "patients",
      "patients.detail",
      "laboratories",
      "surgery",
    ],
  },
  manager: {
    routes: [
      "calendar",
      "metrics",
      "patients",
      "patients.detail",
      "laboratories",
      "surgery",
      "historyappointments",
    ],
  },
  abc: {
    routes: [
      "agenda",
      "patients",
      "metrics",
      "patients.detail",
      "historyappointments",
    ],
  },
  manager_abc: {
    routes: [
      "agenda",
      "patients",
      "metrics",
      "patients.detail",
      "historyappointments",
    ],
  },
  control: {
    routes: ["patients", "patients.detail"],
  },
  reception: {
    routes: ["calendar", "filiations", "patients"],
  },
  cash_register: {
    routes: [
      "filiations",
      "patients",
      "patients.detail",
      "historyappointments",
    ],
  },
  external: {
    routes: [],
  },
  operating_room: {
    routes: ["surgery"],
  },
  medic: {
    routes: [
      "agenda",
      "metrics",
      "patients",
      "patients.detail",
      "surgery",
      "historyappointments",
    ],
  },
  nursing: {
    routes: [
      "calendar",
      "patients",
      "patients.detail",
      "surgery",
      "historyappointments",
    ],
  },
  nutrition: {
    routes: ["agenda", "patients", "patients.detail"],
  },
  psychology: {
    routes: ["calendar", "patients", "patients.detail"],
  },
  laboratory: {
    routes: [
      "historyappointments",
      "patients",
      "patients.detail",
      "laboratories",
    ],
  },
  manager_cacit: {
    routes: ["calendar", "metrics", "filiations", "historyappointments"],
  },
  cacit: {
    routes: ["calendar", "metrics", "filiations"],
  },
  external_medic: {
    routes: [],
  },
  external_anesthesiologist: {
    routes: [],
  },
  external_gestational_gynecologist: {
    routes: [],
  },
};

export { userPermission };
