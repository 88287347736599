<template>
  <transition
    name="fade"
    :enter-class="transition"
    :leave-to-class="transition">
    <div v-if="show" :class="['alert', status]" :style="{ width: width }">
      <slot name="content" :content="content"></slot>
      <font-awesome-icon :icon="['fa', 'times']" size="lg" @click="hideMe" />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    permanent: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
    },
    status: {
      type: String,
      default: "success",
    },
    timeout: {
      type: Number,
      default: 4000,
    },
    transition: {
      type: String,
      default: "fade-right",
    },
    width: {
      type: String,
      default: "350px",
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    hideMe() {
      this.$emit("hide");
    },
  },
  created() {
    if (!this.permanent) {
      setTimeout(() => {
        this.hideMe();
      }, this.timeout);
    }
  },
};
</script>
