export default {
  paymentEvidenceSrc: "",
  paymentTypeAction: true,
  appointmentTypeAction: true,
  patientSelected: null,
  folderOption: "historical",
  folderComponents: ["Historical", "CreateMedicNote"],
  occupations: [],
  occupationsPartner: [],
  viewMode: false,
  owe: null,
  hasPartner: false,
  planName: null,
  completeModeBanner: false,
  hidenSecondaryComponent: false,
  newFiliationSecondOpinionState: false,
};
