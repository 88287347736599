<template>
  <div class="alerts-container">
    <notification
      v-for="(item, key) in getAlerts"
      :key="key"
      v-bind="item"
      :content="item.content"
      :status="item.status"
      @hide="hideChild(key)">
      <template #content="{ content }">
        <div class="container">
          <p v-html="content"></p>
        </div>
      </template>
    </notification>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Notification from "./Notification.vue";

export default {
  components: {
    Notification,
  },
  computed: {
    // Se manda a llamar directamente.
    ...mapGetters(["getAlerts"]),
  },
  methods: {
    // Se manda a llamar a través de un método.
    ...mapActions(["removeAlertByKey"]),
    hideChild(key) {
      this.removeAlertByKey(key);
    },
  },
};
</script>
