<template>
  <div
    class="event-content"
    @mouseover="event.hover = true"
    @mouseleave="event.hover = false"
    :style="{
      'border-top': `.5rem solid ${event.color}`,
    }">
    <transition name="fade" mode="out-in">
      <p
        v-if="event.hover"
        class="fw-lighter fst-italic text-white pt-0 pb-1"
        :style="{
          background: `${event.color}`,
        }">
        {{ agendaName(event) }}
      </p>
    </transition>
    <p class="pt-1">{{ patientName(event) }}</p>
    <p class="fw-light my-0 py-0" v-if="event.patient_nec">
      NEC: {{ event.patient_nec }}
    </p>
    <p v-if="getCalendarFilterMedic === 'ALL'" class="fw-lighter fst-italic">
      {{ medicName(event) }}
    </p>
    <div class="vuecal__event-title" v-html="event.service"></div>

    <p>
      {{
        `${event.start.formatTime("HH:mm")}-${event.end.formatTime("HH:mm")}`
      }}
    </p>
    <div
      class="alert alert-warning alert-calendar"
      role="alert"
      v-if="event.status === 'untaken'">
      Pendiente
    </div>
    <div
      class="alert alert-success alert-calendar"
      role="alert"
      v-if="event.status === 'taken'">
      {{
        event.agenda_name === "ABC" && event.service === "Seguimiento a Px"
          ? "Completada"
          : "Completada"
      }}
    </div>
    <div
      class="alert alert-success alert-calendar"
      role="alert"
      v-if="event.status === 'accepted'">
      {{
        event.agenda_name === "ABC" && event.service === "Seguimiento a Px"
          ? "Completada"
          : "Acepto"
      }}
    </div>
    <div
      class="alert alert-danger alert-calendar"
      role="alert"
      v-if="event.status === 'cancelled'">
      Cancelada
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EventCard",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getCalendarFilterMedic"]),
  },
  methods: {
    agendaName(event) {
      return event?.agenda_name ? event?.agenda_name : "Sin agenda";
    },
    medicName(event) {
      return event?.medic_name ? event?.medic_name : "Sin médico";
    },
    patientName(event) {
      return event?.patient_name ? event?.patient_name : "Sin paciente";
    },
  },
};
</script>

<style>
.alert-calendar {
  margin: 1rem 1.5rem 0 1.5rem;
  padding: 0.5rem;
}
</style>
