import abc from "./abc";
import historical from "./historical";
import laboratory from "./laboratory";
import psychology from "./psychology";
import nutrition from "./nutrition";
import record from "./record";
import files from "./files";

const details = {
  abc,
  historical,
  laboratory,
  psychology,
  nutrition,
  files,
  record,
};

export default details;
