export default {
  logout({ commit }, logout = null) {
    commit("setLoginTokens", logout);
    commit("setUserSchedules", logout);
    commit("setUserProfile", logout);
    commit("setSearchUser", logout);
    commit("setClinic", logout);
    commit("setClinicList", logout);
    commit("setMedic", logout);
    commit("setMedicList", logout);
    commit("setCalendarConfig", logout);
    commit("setCalendarData", logout);
  },
};
