<template>
  <div class="home">
    <CalendarFilter
      ref="CalendarFilter"
      :tabletSize="tabletSizeData"
      @searchByFilter="getAppointments"
      @updateAvailableHours="updateAvailableHours" />
    <vue-cal
      today-button
      :editable-events="{
        title: true,
        drag: false,
        resize: false,
        delete: false,
        create: true,
      }"
      :min-date="getMinDate"
      :max-date="getMaxDate"
      :time-cell-height="90"
      :time-from="getTimeInit"
      :time-to="getTimeEnd"
      :time-step="30"
      :special-hours="scheduleByUserSelected"
      :disable-views="disableViewsData"
      :active-view="activeViewData"
      :events="events"
      :selected-date="selectedDate"
      :on-event-click="openEventEdit"
      :drag-to-create-event="false"
      :drag-to-edit-event="false"
      :cell-click-hold="false"
      @view-change="navigateCalendar"
      @cell-click="openEventCreate"
      ref="calendar"
      locale="es"
      style="min-height: 80vh">
      <!-- EVENT CARD -->
      <template v-slot:event="{ event }">
        <EventCard :event="event" />
      </template>
      <!-- EVENT CARD / -->
      <!-- 0 EVENTS MESSAGE / -->
      <template v-slot:cell-content="{ view, events }">
        <span
          class="vuecal__no-event"
          v-if="['week', 'day'].includes(view.id) && !events.length">
          No hay eventos
        </span>
      </template>
      <!-- 0 EVENTS MESSAGE / -->
    </vue-cal>
    <!-- PREVIEW APPOINTMENT COMPONENT -->
    <Preview :key="keyPreview" @resetComponent="resetComponent('preview')" />
    <!-- PREVIEW APPOINTMENT COMPONENT / -->
    <!-- OPTIONS FOR CACIT -->
    <MenuCE
      :key="keyMenu"
      :type="typeMenu"
      @resetComponent="resetComponent('menu')"
      @openEditAppointment="openEditAppointment"
      @openPreview="openPreview"
      @createBySearchPatient="createBySearchPatient"
      @createByNewFiliation="createByNewFiliation"
      @createByExternalPatient="createByExternalPatient"
      @cancelAppointment="cancelAppointment" />
    <!-- OPTIONS FOR CACIT / -->
    <!-- CANCEL EVENT -->
    <CancelAppointment
      ref="cancelAppointment"
      :key="keyCancelappointment"
      @refreshCalendarData="getAppointments"
      @refreshCalendarDataByFilter="getAppointmentsByFilter"
      @resetComponent="resetComponent('cancelappointment')" />
    <!-- CANCEL EVENT /-->
    <!-- CREATE EDIT EVENT -->
    <CEAppointment
      ref="ceAppointmentParent"
      :key="keyCeappointment"
      @refreshCalendarData="getAppointments"
      @refreshCalendarDataByFilter="getAppointmentsByFilter"
      @resetComponent="resetComponent('ceappointment')" />
    <!-- CREATE EDIT EVENT /-->
    <!-- NEW FILIATION AND EVENT -->
    <CEFiliation
      typeAction
      v-if="isCacitType || isSuperAdmin || isNurse"
      :key="keyCefiliation"
      @refreshCalendarData="getAppointments"
      @refreshCalendarDataByFilter="getAppointmentsByFilter"
      @resetComponent="resetComponent('cefiliation')" />
    <!-- NEW FILIATION AND EVENT /-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MenuCE from "@/components/shared/appointments/MenuCE.vue";
import Preview from "@/components/shared/appointments/Preview.vue";
import CEAppointment from "@/components/appointments/CEAppointment.vue";
import CEFiliation from "@/components/patient/appointment/CEFiliation.vue";
import CancelAppointment from "@/components/appointments/CancelAppointment.vue";
import generalDateDefinition from "@/mixin/generalDateDefinition.mixin";
import calendarConfig from "@/mixin/calendarConfig.mixin";
import permissionAppointment from "@/mixin/permissionAppointment.mixin";

export default {
  name: "Home",
  components: {
    CEAppointment,
    CEFiliation,
    CancelAppointment,
    Preview,
    MenuCE,
  },
  mixins: [generalDateDefinition, calendarConfig, permissionAppointment],
  data() {
    return {
      typeMenu: "create",
      keyMenu: "menu-0",
      keyPreview: "preview-0",
      keyCeappointment: "ceappointment-0",
      keyCefiliation: "cefiliation-0",
      keyCancelappointment: "cancelappointment-0",
      events: [],
      eventDataSelected: {},
      selectedDate: new Date(),
    };
  },
  mounted() {
    this.getAppointments();
    this.$api.calendar.getConfig({ clinic_id: this.getClinic }).then((res) => {
      this.updateCalendarConfig(res.data);
      if (this.isMedicUser) {
        this.updateAvailableHoursByMedicSchedule(this.getUserID);
        return;
      }
      if (
        this.getCalendarFilterMedic &&
        this.getCalendarFilterMedic !== "all"
      ) {
        this.updateAvailableHoursByMedicSchedule(this.getCalendarFilterMedic);
        return;
      }
      this.updateAvailableHoursByClinic();
    });
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "getClinic",
      "getCalendarFilterMedic",
      "isMedicUser",
      "isCacitType",
      "isSuperAdmin",
      "isNurse",
      "getUserID",
      "getMedic",
      "getCalendarConfig",
    ]),
    calendarMedicFilterPermissionsByUserType() {
      return (
        this.getUserType === "superadmin" ||
        this.getUserType === "admin" ||
        this.getUserType === "cacit" ||
        this.getUserType === "manager_cacit" ||
        this.getUserType === "nursing" ||
        this.getUserType === "reception" ||
        this.getUserType === "manager"
      );
    },
  },
  methods: {
    ...mapActions(["updateAlerts", "updateCalendarConfig"]),
    ...mapMutations([
      "setCEAppointmentEditID",
      "setCEAppointmentState",
      "setFiliationInfo",
      "setSelectedPatientID",
      "setIsCreateExternalPatient",
    ]),
    newKeyByComponentName(component) {
      const keyNumber =
        parseInt(this[`key${component.capitalize()}`].split("-")[1], 10) + 1;
      this[`key${component.capitalize()}`] = `${component}-${keyNumber}`;
    },
    resetComponent(componentToReset) {
      this.setSelectedPatientID();
      this.setFiliationInfo();
      this.newKeyByComponentName(componentToReset);
      this.updateCalendarDataAppointments();
    },
    createPayload() {
      const { toCalendarFilterByMedic, toCalendarFilterByAgenda } =
        this.hasPermission;
      const { startDate, endDate, id } = this.$refs.calendar?.view;
      const { medicID, scheduleID, status } = this.$refs.CalendarFilter;
      const payload = {
        clinic_id: this.getClinic,
        filter: id,
        status,
        startDate,
        endDate,
      };
      if (toCalendarFilterByMedic && medicID !== "all") {
        payload.medic_id = medicID;
      }
      if (toCalendarFilterByAgenda && scheduleID !== "all") {
        payload.agenda_id = scheduleID;
      }
      return payload;
    },
    getAppointmentsByFilter({ medic = false, agendaID = false }) {
      this.events = [];
      const payload = this.createPayload();
      const { medicID } = this.$refs.CalendarFilter;
      if (medicID !== "all") {
        payload.medic_id = medic;
      }
      if (agendaID) {
        delete payload.agenda_id;
        this.$refs.CalendarFilter.scheduleID = "all";
      }
      this.$api.calendar.getData(payload).then((res) => {
        const formatEvents = res.data.appointments;
        formatEvents.forEach((appointment) => {
          // eslint-disable-next-line no-param-reassign
          appointment.deletable = false;
          // eslint-disable-next-line no-param-reassign
          appointment.hover = false;
        });
        this.events = res.data.appointments;
      });
    },
    getAppointments() {
      const payload = this.createPayload();
      this.$api.calendar.getData(payload).then((res) => {
        const formatEvents = res.data.appointments;
        formatEvents.forEach((appointment) => {
          // eslint-disable-next-line no-param-reassign
          appointment.deletable = false;
          // eslint-disable-next-line no-param-reassign
          appointment.hover = false;
        });
        this.events = res.data.appointments;
      });
    },
    navigateCalendar(calendarParams) {
      this.$refs.CalendarFilter.date = calendarParams.startDate;
      const { startDate, endDate, view } = calendarParams;
      this.getAppointments(view, startDate, endDate);
    },
    openPreview(eventData = null) {
      this.$store.getters.getModal("menuAppointment").hide();
      const eventDataConst = eventData || this.eventDataSelected;
      this.defineDateTimeAppointment(eventDataConst.start);
      this.setCEAppointmentEditID(eventDataConst.appointment_id);
      this.$store.getters.getModal("patientAppointmentPreview").show();
    },
    openEventEdit(eventData) {
      this.eventDataSelected = eventData;
      if (!this.canEdit(eventData)) return;
      this.setCEAppointmentEditID(eventData.appointment_id);
      this.defineDateTimeAppointment(eventData.start);
      const modal = this.modalToOpenByPermissions("edit", eventData);
      if (modal === "menuAppointment") {
        this.setCEAppointmentState("menu");
        this.$store.getters.getModal(modal).show();
        return;
      }
      this.setCEAppointmentState("edit");
      this.$refs.ceAppointmentParent.getAppointmentInfo();
      this.$store.getters.getModal(modal).show();
    },
    openEventCreate(startDateClick) {
      if (this.$refs.calendar.view.id === "month") return;
      if (startDateClick?.split) {
        const isClickOnCalendarHeading =
          startDateClick.split.currentTarget.className.includes("heading");
        if (isClickOnCalendarHeading) return;
      }
      const validTime = this.defineDateTimeAppointment(startDateClick);
      if (!validTime) {
        this.updateAlerts({
          status: "warning",
          content: "No se puede agendar antes de la hora actual.",
        });
        return;
      }
      this.setCEAppointmentState("create");
      this.modalToOpenByPermissions("create");
    },
    cancelAppointment() {
      this.$store.getters.getModal("menuAppointment").hide();
      this.$store.getters.getModal("cancelAppointmentModal").show();
    },
    openEditAppointment() {
      this.setCEAppointmentState("edit");
      this.$store.getters.getModal("menuAppointment").hide();
      this.$refs.ceAppointmentParent.getAppointmentInfo();
      this.$store.getters.getModal("ceAppointment").show();
    },
    createByExternalPatient() {
      this.setIsCreateExternalPatient(true);
      this.$store.getters.getModal("menuAppointment").hide();
      this.$store.getters.getModal("ceFiliation").show();
    },
    createBySearchPatient() {
      this.$store.getters.getModal("menuAppointment").hide();
      this.$store.getters.getModal("ceAppointment").show();
    },
    createByNewFiliation() {
      this.$store.getters.getModal("menuAppointment").hide();
      this.$api.appointments.newFiliation().then((res) => {
        this.setFiliationInfo(res.data);
        this.$store.getters.getModal("ceFiliation").show();
      });
    },
  },
};
</script>
