<template>
  <b-modal
    hide-footer
    hide-header
    size="lg"
    id="EditPassword"
    no-close-on-backdrop
    no-close-on-esc>
    <div class="px-5 pb-5 pt-4">
      <div>
        <!-- TITLE -->
        <h3>{{ title }}</h3>
        <!-- TITLE /-->
      </div>
      <div class="row">
        <!-- PASSWORD INPUT -->
        <div class="col form-group">
          <label class="form-label mt-4">Contraseña actual</label>
          <b-form-input
            v-model="user.old_password"
            :state="!$v.user.old_password.$invalid"
            type="password"
            class="form-control"
            placeholder="Contraseña" />
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.old_password.$invalid && !$v.user.old_password.required
            ">
            La contraseña actual es requisito
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.old_password.$invalid && !$v.user.old_password.minLength
            ">
            Mínimo 6 cáracters
          </b-form-invalid-feedback>
        </div>
        <!-- PASSWORD INPUT /-->
        <!-- PASSWORD CONFIRMATION INPUT -->
        <div class="col form-group">
          <label class="form-label mt-4">Confirmar contraseña actual</label>
          <b-form-input
            type="password"
            v-model="user.old_password_confirmation"
            :state="!$v.user.old_password_confirmation.$invalid"
            class="form-control"
            placeholder="Contraseña" />
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.old_password_confirmation.$invalid &&
              !$v.user.old_password_confirmation.required
            ">
            La contraseña actual es requisito
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.old_password_confirmation.$invalid &&
              !$v.user.old_password_confirmation.minLength
            ">
            Mínimo 6 cáracters
          </b-form-invalid-feedback>
        </div>
        <!-- PASSWORD CONFIRMATION INPUT /-->
      </div>
      <div class="row">
        <!-- PASSWORD INPUT -->
        <div class="col form-group">
          <label class="form-label mt-4">Contraseña</label>
          <b-form-input
            v-model="user.password"
            :state="!$v.user.password.$invalid"
            type="password"
            class="form-control"
            placeholder="Contraseña" />
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="$v.user.password.$invalid && !$v.user.password.required">
            La contraseña es requisito
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="$v.user.password.$invalid && !$v.user.password.minLength">
            Mínimo 6 cáracters
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.password.$invalid && !$v.user.password.notSameOldPassword
            ">
            No puede ser igual al password actual
          </b-form-invalid-feedback>
        </div>
        <!-- PASSWORD INPUT /-->
        <!-- PASSWORD CONFIRMATION INPUT -->
        <div class="col form-group">
          <label class="form-label mt-4">Confirmar contraseña</label>
          <b-form-input
            type="password"
            v-model="user.password_confirmation"
            :state="!$v.user.password_confirmation.$invalid"
            class="form-control"
            placeholder="Contraseña" />
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.password_confirmation.$invalid &&
              !$v.user.password_confirmation.required
            ">
            La contraseña es requisito
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.password_confirmation.$invalid &&
              !$v.user.password_confirmation.minLength
            ">
            Mínimo 6 cáracters
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="input-live-feedback"
            v-if="
              $v.user.password_confirmation.$invalid &&
              !$v.user.password_confirmation.notSameOldPassword
            ">
            No puede ser igual al password actual
          </b-form-invalid-feedback>
        </div>
        <!-- PASSWORD CONFIRMATION INPUT /-->
      </div>
      <div class="row mt-4">
        <!-- SUBMIT BUTTON -->
        <div class="col-12 form-group">
          <button
            @click="sendData"
            type="submit"
            class="form-control btn btn-primary">
            Enviar
          </button>
        </div>
        <!-- SUBMIT BUTTON /-->
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";
import { sameAs, required, minLength, not } from "vuelidate/lib/validators";

export default {
  name: "EditPassword",
  data() {
    return {
      user: {
        password: "",
        password_confirmation: "",
      },
    };
  },
  validations() {
    const validations = {
      user: {
        old_password: {
          minLength: minLength(6),
          required,
          sameAsPassword: sameAs("old_password_confirmation"),
        },
        old_password_confirmation: {
          minLength: minLength(6),
          required,
          sameAsPassword: sameAs("old_password"),
        },
        password: {
          minLength: minLength(6),
          required,
          sameAsPassword: sameAs("password_confirmation"),
          notSameOldPassword: not(sameAs("old_password")),
        },
        password_confirmation: {
          minLength: minLength(6),
          required,
          sameAsPassword: sameAs("password"),
          notSameOldPassword: not(sameAs("old_password")),
        },
      },
    };
    return validations;
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "EditPassword",
      ref: this.$refs.EditPassword,
    });
  },
  computed: {
    title() {
      return this.isFirstPwdChange
        ? "Cambiar contraseña"
        : "Editar contraseña de primer inicio de sesión";
    },
  },
  methods: {
    ...mapMutations(["setUserProfile", "setUserSchedules"]),
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    sendData() {
      if (!this.isFormDataValid()) return;
      this.$api.user
        .pwdChangeFirstLogin({
          password: this.user.password,
          old_password: this.user.old_password,
        })
        .then(() => {
          this.reLogin();
        });
    },
    reLogin() {
      // eslint-disable-next-line consistent-return
      this.$api.user.userProfile().then((response) => {
        this.setUserProfile(response.data);
        this.$store.dispatch("updateClinic", response.data.clinics_ids[0].id);
        this.$api.settings.getScheduleByUser().then((res) => {
          this.setUserSchedules(res.data.agendas);
        });
        this.user.password = "";
        this.user.password_confirmation = "";
        this.$bvModal.hide("EditPassword");
        window.location.reload(true);
      });
    },
  },
};
</script>
