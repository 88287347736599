<template>
  <b-modal
    ref="confirmationModal"
    id="confirmationModalId"
    hide-footer
    hide-header
    size="lg">
    <h4 class="titles my-3 text-start blueHeader">
      <b>{{ confirmData.title }}</b>
    </h4>
    <div class="my-5 text-center">
      <h5 class="" v-html="confirmData.description"></h5>
    </div>
    <div class="row justify-content-between mt-3">
      <div class="col">
        <b-button @click="close()" class="col-5 radiusButton w-100">
          {{ cancelBtnTxt }}
        </b-button>
      </div>
      <div class="col">
        <b-button
          @click="confirm()"
          class="col-5 radiusButton w-100"
          variant="primary">
          {{ okBtnTxt }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ConfirmationModal",
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "confirmationModal",
      ref: this.$refs.confirmationModal,
    });
  },
  watch: {
    confirmData(newVal) {
      if (newVal.title !== "" && newVal.title !== "Titulo") {
        this.$refs.confirmationModal.show();
      }
    },
  },
  computed: {
    cancelBtnTxt() {
      const btnTxt = this.confirmData?.cancelText;
      return !btnTxt ? "Cancelar" : btnTxt;
    },
    okBtnTxt() {
      const btnTxt = this.confirmData?.okText;
      return !btnTxt ? "Proceder" : btnTxt;
    },
    confirmData() {
      return this.getConfirmData();
    },
  },
  methods: {
    ...mapGetters(["getConfirmData"]),
    ...mapActions(["updateConfirmData"]),
    close() {
      this.updateConfirmData();
      this.$refs.confirmationModal.hide();
    },
    confirm() {
      this.confirmData.confirmFunction(this.confirmData.params);
      this.close();
    },
  },
};
</script>
