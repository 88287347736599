export default {
  setUserSchedules(state, schedules = null) {
    state.schedules = schedules;
    if (schedules) {
      localStorage.setItem("user-schedules", JSON.stringify(schedules));
    } else {
      localStorage.removeItem("user-schedules");
    }
  },
  setUserProfile(state, profile = null) {
    state.profile = profile;
    if (profile) {
      localStorage.setItem("user-profile", JSON.stringify(profile));
    } else {
      localStorage.removeItem("user-profile");
    }
  },
  // TODO: move to his own module SEARCH
  setSearchUser(state, searchUser = "") {
    state.searchUser = searchUser;
    if (searchUser !== "") {
      localStorage.setItem("searchUser", JSON.stringify(searchUser));
    } else {
      localStorage.removeItem("searchUser");
    }
  },
};
