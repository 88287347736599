import { hasPermissionCEAppointments } from "@/constants/permissions";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      hasPermissionCEAppointments,
    };
  },
  computed: {
    ...mapGetters(["isMedicUser", "getUserType"]),
    hasPermission() {
      return this.hasPermissionCEAppointments[this.getUserType] || false;
    },
  },
  methods: {
    canCreate() {
      if (!this.isInsideTheAvailableHours()) {
        this.updateAlerts({
          status: "warning",
          content: "Selecciona un horario disponible.",
        });
        return false;
      }
      if (!this.hasPermission.toCreate) {
        this.updateAlerts({
          status: "warning",
          content: "Este usuario no puede crear un evento.",
        });
        return false;
      }
      return true;
    },
    canEdit(eventData) {
      if (eventData.status === "cancelled") return false;
      if (this.getUserType === "reception") {
        this.openPreview(eventData);
        return false;
      }
      if (
        eventData.agenda_name === "ABC" &&
        eventData?.service === "Seguimiento a Px"
      ) {
        this.openPreview(eventData);
        return false;
      }
      if (eventData.agenda_name === "Prospección" && this.isMedicUser) {
        this.openPreview(eventData);
        return false;
      }
      if (!this.hasPermission || !this.hasPermission.toEdit) {
        this.updateAlerts({
          status: "warning",
          content: "Este usuario no puede editar eventos.",
        });
        return false;
      }
      if (
        this.hasPermission.toEditOnlySelfCreated &&
        !this.isHisPatientOrCreatedByHim(eventData)
      ) {
        this.updateAlerts({
          status: "warning",
          content: "Este usuario solo puede editar eventos creados por el.",
        });
        this.openPreview(eventData);
        return false;
      }
      return true;
    },
    isHisPatientOrCreatedByHim(eventData) {
      const createdByUsersTypesThatNurseCanEdit = [
        "medic",
        "external_medic",
        "nursing",
      ];
      if (this.isMedicUser) {
        return eventData.medic_id === this.getUserID;
      }
      if (this.getUserType === "nursing") {
        return createdByUsersTypesThatNurseCanEdit.includes(
          eventData.user_type
        );
      }
      return eventData.user_id === this.getUserID;
    },
    modalToOpenByPermissions(type, eventData = null) {
      if (type === "create" && this.canCreate()) {
        this.modalCreate();
      } else if (type === "edit") {
        return this.modalEdit(eventData);
      }
      return false;
    },
    modalEdit(eventData) {
      if (
        this.hasPermission.toEnterMenuEdit ||
        (this.getUserType === "manager_cacit" &&
          eventData?.user_type === "cacit")
      ) {
        this.typeMenu = "edit";
        return "menuAppointment";
      }
      return "ceAppointment";
    },
    modalCreate() {
      if (this.hasPermission.toEnterMenuCreate) {
        this.typeMenu = "create";
        this.$store.getters.getModal("menuAppointment").show();
      } else {
        this.$store.getters.getModal("ceAppointment").show();
      }
    },
  },
};
