<template>
  <div class="header justify-content-between" v-if="getRouteConfig">
    <!-- Title Info -->
    <h1 class="heading-primary align-bottom col-3">
      {{ getRouteConfig.title }}
    </h1>
    <!-- Title Info / -->
    <div class="col-7 d-flex justify-content-end align-items-center pe-5 me-2">
      <!-- Print Button -->
      <div
        class="col-2 d-flex justify-content-end align-items-center me-3"
        v-if="false">
        <div class="card py-3 card__shadow roundedPrint">
          <font-awesome-icon
            class="headerIcon text-secondary"
            :icon="['fas', 'print']" />
        </div>
      </div>
      <!-- Print Button / -->
      <!-- Clinic Select -->
      <div v-if="getClinicListPerUser" class="text-end me-5 text-secondary">
        <select
          v-model="clinic_id"
          @change="reloadMedicList"
          class="form-select inputsGeneralClass w-100">
          <option
            v-for="clinic in getClinicListPerUser"
            :key="clinic.id + 'clinics'"
            :value="clinic.id">
            {{ clinic.name }}
          </option>
        </select>
      </div>
      <!-- Clinic Select / -->
      <!-- User Info -->
      <div class="text-end header__user ms-3">
        <div class="header__user--name text-center fs-5 text-black">
          {{ userName }}
        </div>
        <div class="text-center text-black">
          {{ userTypes[userType] }}
        </div>
      </div>
      <font-awesome-icon
        class="text-secondary header__user header__user--icon ms-3 me-2"
        :icon="['fas', 'user-circle']" />
      <!-- User Info / -->
      <!-- Burguer Menu -->
      <div class="burgerMenu" @click="actionBMenu">
        <span
          class="burgerMenu-global burgerMenu-top"
          :class="{ 'burgerMenu-top-click': toogle }"></span>
        <span
          class="burgerMenu-global burgerMenu-middle"
          :class="{ 'burgerMenu-middle-click': toogle }"></span>
        <span
          class="burgerMenu-global burgerMenu-bottom"
          :class="{ 'burgerMenu-bottom-click': toogle }"></span>
      </div>
      <!-- Burguer Menu -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { userTypes } from "@/constants/auth";

export default {
  name: "HeaderSection",
  data() {
    return {
      userTypes,
    };
  },
  mounted() {
    this.clinic_id = this.getClinic;
  },
  computed: {
    ...mapGetters([
      "getToogleBMenu",
      "getClinic",
      "getUserProfile",
      "getUserType",
      "getClinicList",
      "getRoutesConfiguration",
    ]),
    userName() {
      const userInfo = this.getUserProfile;
      return `${userInfo?.name} ${userInfo?.last_name}`;
    },
    userType() {
      const userInfo = this.getUserProfile;
      return `${userInfo?.user_type}`;
    },
    getClinicListPerUser() {
      if (this.getUserType === "superadmin") {
        return this.getClinicList;
      }
      if (this.getUserProfile?.clinics_ids.length > 0) {
        const listClinicsOfUser = this.getClinicList.filter((i) =>
          this.getUserProfile?.clinics_ids.includes(i.id)
        );
        this.updateClinicList(listClinicsOfUser);
        return listClinicsOfUser;
      }
      return null;
    },
    toogle: {
      get() {
        return this.getToogleBMenu;
      },
      set(value) {
        this.bMenuChangeState(value);
      },
    },
    clinic_id: {
      get() {
        return this.getClinic;
      },
      set(value) {
        this.updateClinic(value);
      },
    },
    medic_id: {
      get() {
        return this.getMedic;
      },
      set(value) {
        this.updateMedic(value);
      },
    },
    getRouteConfig() {
      if (!this.$route.name) return null;
      const isChildRoute = this.$route.name.includes(".");
      if (isChildRoute) {
        const splitRoute = this.$route.name.split(".");
        let configResponse = this.getRoutesConfiguration[splitRoute[0]];
        if (splitRoute.length >= 2) {
          splitRoute.forEach((routePart, index) => {
            if (index) {
              configResponse = configResponse.childRoutes[routePart];
            }
          });
        }
        return configResponse;
      }
      return this.getRoutesConfiguration[this.$route.name];
    },
    validateUserType() {
      return this.getUserProfile.user_type === "admin";
    },
  },
  beforeDestroy() {
    const body = document.getElementsByTagName("body")[0];
    body.removeAttribute("class", "overflow-hidden");
  },
  methods: {
    ...mapMutations(["bMenuChangeState"]),
    ...mapActions([
      "updateClinic",
      "updateMedic",
      "updateMedicList",
      "updateClinicList",
    ]),
    actionBMenu() {
      this.toogle = !this.toogle;
      const body = document.getElementsByTagName("body")[0];
      if (this.toogle) {
        body.setAttribute("class", "overflow-hidden");
      } else {
        body.removeAttribute("class", "overflow-hidden");
      }
    },
    reloadMedicList() {
      this.updateMedicList();
      this.medic_id = "";
      if (
        this.getUserType !== "superadmin" ||
        this.getUserType !== "admin" ||
        this.getUserType !== "cacit" ||
        this.getUserType !== "medic"
      )
        return;
      const payload = {
        clinic_id: this.clinic_id,
      };
      this.$api.settings.getMedicsByClinic(payload).then((res) => {
        this.updateMedicList(res.data.medics);
        this.medic_id = res.data.medics[0]?.id;
      });
    },
  },
};
</script>
