import Vue from "vue";
import store from "@/store";
import { publicRoutes } from "@/constants/auth";

store.getters.getToken; //eslint-disable-line

const refreshAccessToken = (payload) =>
  Vue.prototype.$api.auth.refreshToken(payload);

const logOut = () => {
  store.dispatch("logout");
  window.location.href = window.location.href.replace(
    window.location.pathname,
    "/login"
  );
};

const authGuard = (to, from, next) => {
  const { getPermissionRoutesByUserType } = store.getters;
  const toName = to.name.toLowerCase();
  const tokens = store.getters.getLoginTokens;
  const isLogged = !!tokens;
  const home = getPermissionRoutesByUserType[0];
  const userHasPermission = getPermissionRoutesByUserType.includes(toName);
  if (isLogged) {
    if (getPermissionRoutesByUserType.length === 0) {
      store.dispatch("updateAlerts", {
        content: `Usuario sin permisos para esta sección`,
        status: "error",
      });
      return logOut();
    }
    if (userHasPermission) return next();
    if (!userHasPermission) {
      store.dispatch("updateAlerts", {
        content: `Usuario sin permisos para esta sección`,
        status: "error",
      });
      return next({ name: home });
    }
  }
  if (publicRoutes.includes(toName)) return next();
  return next("/login");
};

export { refreshAccessToken, logOut, authGuard };
