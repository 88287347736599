const storage = window.localStorage;
export default {
  setCurrentLanguage: (state, data) => {
    storage.setItem("language", JSON.stringify(data));
    state.language = data;
  },
  bMenuChangeState: (state, data) => {
    state.toogleBMenu = data;
  },
  newModal: (state, modalData) => {
    state.modals[modalData.key] = modalData.ref;
  },
};
