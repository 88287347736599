<template>
  <div class="ceAppointment__patients">
    <div class="titles ps-3 m-3 pb-2">{{ title }}</div>
    <div class="ceAppointment__patients--search row px-3 m-3 ms-4 fadeIn">
      <!-- SEARCH -->
      <transition name="fade" mode="out-in">
        <Search
          v-if="isCEAppointmentCreate && !isPatientSelected"
          ref="searchPatient"
          @searchData="searchPatient"
          :autoSearch="false"
          :infoTypeBoth="isCacitUser" />
      </transition>
      <transition-group name="fade" mode="out-in">
        <b-table
          key="tablePatients"
          :items="patientsList"
          :fields="patientsFieldsByState"
          class="align-middle"
          emptyText="No se encontró el paciente"
          :show-empty="isSearchEmpty"
          borderless
          striped>
          <template v-slot:cell(patient_type)="row">
            <font-awesome-icon
              class="tableSizeIconIdUser"
              :title="titleByType(row.item.patient_type)"
              :class="colorIconByType(row.item.patient_type)"
              :icon="['fa', 'hospital-user']" />
          </template>
          <template v-slot:cell(actions)="patient">
            <button
              type="button"
              @click="
                actionPatient(true, patient.item);
                return false;
              "
              class="btn btnSelect"
              v-if="!isPatientSelected">
              Seleccionar
            </button>
            <button
              type="button"
              @click="
                actionPatient(false);
                return false;
              "
              class="btn btnSelect"
              v-else-if="isCEAppointmentCreate">
              Deseleccionar
            </button>
          </template>
        </b-table>
        <b-pagination
          v-if="!isPatientSelected"
          :total-rows="total"
          v-model="page"
          :per-page="limit"
          @change="changePage($event)"
          key="paginationPatients"
          align="right"
          aria-controls="my-table"></b-pagination>
      </transition-group>
      <transition name="fade" mode="out-in">
        <b-table
          :items="commentsPatient"
          :fields="commentFields"
          v-if="isPatientSelected"
          class="align-middle"
          :table-class="'patientsHeadClass'"
          emptyText="No se encontró el comentario"
          borderless
          striped>
          <template v-slot:cell(filiation_comments)="row">
            <div>
              <pre
                style="white-space: pre-wrap"
                v-text="row.item.filiation_comments"></pre>
            </div>
          </template>
        </b-table>
      </transition>
      <div
        class="mx-auto grow"
        v-if="$v.isPatientSelected.$error && $v.isPatientSelected.required">
        <p class="text-center text-danger">
          Seleccionar un paciente es requerido
        </p>
      </div>
      <!-- SEARCH /-->
    </div>
  </div>
</template>

<script>
import { patientsFields, patientsFieldsEdit } from "@/constants/appointments";
import { mapGetters } from "vuex";
import Search from "@/components/shared/Search.vue";
import { required } from "vuelidate/lib/validators";

const boleanTrue = (value) => value;

export default {
  name: "SelectPatient",
  components: {
    Search,
  },
  props: {
    isPatientSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      commentFields: [
        {
          key: "filiation_comments",
          label: "Comentario de Filiación",
        },
      ],
      patientsFields,
      patientsFieldsEdit,
      isSearchEmpty: false,
      patients: [],
      patientSelected: null,
      filiation_comments: "",
      total: 0,
      limit: 5,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "getClinic",
      "getSelectedPatientID",
      "isCEAppointmentCreate",
      "isCacitUser",
      "isSuperAdmin",
    ]),
    commentsPatient() {
      if (this.isCEAppointmentCreate) {
        return [{ filiation_comments: this.patientSelected.comments }];
      }
      return [{ filiation_comments: this.filiation_comments }];
    },
    title() {
      if (this.isCEAppointmentCreate && !this.isPatientSelected) {
        return "Selecciona un paciente.";
      }
      return "Paciente seleccionado";
    },
    patientsList() {
      if (this.isPatientSelected) {
        return [this.patientSelected];
      }
      return this.patients;
    },
    patientsFieldsByState() {
      if (this.isCEAppointmentCreate) {
        return this.patientsFields;
      }
      return this.patientsFieldsEdit;
    },
  },
  validations: {
    isPatientSelected: {
      required,
      boleanTrue,
    },
  },
  methods: {
    titleByType(type) {
      const titles = {
        internal: "Paciente interno",
        external: "Paciente externo",
        donor: "Donante",
      };
      return titles[type];
    },
    colorIconByType(type) {
      const colors = {
        internal: "text-success",
        external: "text-primary",
        donor: "text-danger",
      };
      return colors[type];
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    searchPatientByID() {
      const id = this.getSelectedPatientID;
      this.$api.patient.getPatient(id).then((res) => {
        this.patientSelected = {
          id,
          name: res.data.patient_name,
          nec: res.data.nec,
          contact_tel: res.data.patient_tel,
          patient_type: res.data.patient_type,
        };
        this.$emit("switchSelectedPatient", true);
      });
    },
    searchPatient() {
      this.page = 1;
      const { limit, page } = this;
      const payload = {
        ...this.$refs.searchPatient.payload,
        clinic_id: this.getClinic,
        search_only: true,
        limit,
        page,
      };
      if (this.isSuperAdmin || this.getUserType === "manager_cacit")
        payload.infoType = "both";
      this.$api.patient.getPatientList(payload).then((res) => {
        if (res.data.patients.length === 0) {
          this.isSearchEmpty = true;
        }
        this.patients = res.data.patients;
        this.total = res.data.total;
      });
    },
    changePage(page) {
      const { limit } = this;
      const payload = {
        ...this.$refs.searchPatient.payload,
        clinic_id: this.getClinic,
        search_only: true,
        limit,
        page,
      };
      if (this.isSuperAdmin) payload.infoType = "both";
      this.$api.patient.getPatientList(payload).then((res) => {
        if (res.data.patients.length === 0) {
          this.isSearchEmpty = true;
        }
        this.patients = res.data.patients;
        this.total = res.data.total;
      });
    },
    /**
     * actionPatient
     * * función para seleccionar o deseleccionar paciente.
     * @param action la acción que se va a realizar, seleccionar o deseleccionar paciente.
     * @param patient la información del paciente seleccionado o vació en caso de deselección.
     */
    actionPatient(action, patient = {}) {
      this.$emit("switchSelectedPatient", action);
      this.patientSelected = patient;
    },
  },
};
</script>
