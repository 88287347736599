<template>
  <div class="wrapperLogin fadeInDown loginGradient h-login">
    <div id="formContent" class="loginCard px-3 py-2">
      <!-- Icon -->
      <div class="fadeInLogin first row p-4 pt-5 mb-2">
        <div class="d-flex align-items-center justify-content-center">
          <div
            class="
              d-flex
              flex-column
              col-12
              justify-content-center
              align-items-center
            ">
            <div class="col-12 text-center ms-4 ps-2 text-secondary">
              <img
                :src="require('@/assets/images/logo_citmediaWeb.png')"
                class="img-fluid mb-0"
                alt="" />
            </div>
            <div class="d-flex justify-content-end col-12 text-secondary">
              <div class="col"></div>
              <div class="col-4">
                <span class="fs-7 txtAppVersion">{{ getAppVersion }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 class="title fadeInLogin">Accede a tu cuenta</h3>
      <!-- Login Form -->
      <form class="py-3 pr-5 px-5" @submit.prevent="onLogin">
        <div v-if="warning" class="alert alert-warning" role="alert">
          {{ warningTxt }}
        </div>
        <div class="d-flex flex-column form-group mt-3 mb-4">
          <div class="text-start mb-2">
            <label for="login" class="fs-5 bold text-secondary fadeInLogin"
              >Nombre de Usuario</label
            >
          </div>
          <div>
            <b-form-input
              type="text"
              id="login"
              v-model="$v.login.username.$model"
              :state="!$v.login.username.$invalid"
              class="form-control fadeInLogin second"
              @keyup.enter="onLogin"
              placeholder="" />
            <b-form-invalid-feedback
              id="input-live-feedback"
              v-if="$v.login.username.$error && !$v.login.username.required">
              El nombre de usuario es requisito
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="input-live-feedback"
              v-if="$v.login.username.$error">
              El nombre de usuario es inválido
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group mt-3 mb-2">
          <div class="text-start mb-2">
            <label class="fs-5 bold text-secondary fadeInLogin"
              >Contraseña</label
            >
          </div>
          <div>
            <b-form-input
              type="password"
              id="password"
              v-model="$v.login.password.$model"
              :state="!$v.login.password.$invalid"
              class="form-control fadeInLogin third"
              @keyup.enter="onLogin"
              placeholder="contraseña" />
            <b-form-invalid-feedback
              id="input-live-feedback"
              v-if="$v.login.password.$error && !$v.login.password.required">
              La contraseña es requisito
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="input-live-feedback"
              v-if="$v.login.password.$error && !$v.login.password.minLength">
              La contraseña debe tener al menos 6 caracteres
            </b-form-invalid-feedback>
          </div>
        </div>
        <button
          type="submit"
          class="fadeInLogin fourth input_login w-100 mt-5 mb-5">
          Acceder
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Form from "@/helpers/form";

export default {
  data() {
    return {
      warning: false,
      warningTxt: "",
      login: new Form({
        username: "",
        password: "",
      }),
      show: true,
    };
  },
  validations: {
    login: {
      username: {
        required,
      },
      password: {
        minLength: minLength(6),
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["getPermissionRoutesByUserType"]),
    getAppVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  beforeDestroy() {
    const body = document.getElementsByTagName("body")[0];
    body.removeAttribute("class", "bg");
  },
  mounted() {
    this.back_login();
  },
  methods: {
    ...mapActions(["updateLoginTokens"]),
    ...mapMutations(["setUserProfile", "setUserSchedules"]),
    validateInputClass(input) {
      const inputIsValid = !this.$v.login[input].$error;
      if (inputIsValid) return "is-valid";
      return "is-invalid";
    },
    back_login() {
      const root = document.getElementsByTagName("html")[0];
      const body = document.getElementsByTagName("body")[0];
      root.setAttribute("class", "h-100");
      body.setAttribute("class", "h-100 bg");
    },
    getUserInfo() {
      // eslint-disable-next-line consistent-return
      this.$api.user.userProfile().then((response) => {
        if (
          this.getPermissionRoutesByUserType.includes(response.data.user_type)
            .length === 0
        ) {
          this.$store.dispatch("updateAlerts", {
            content: "Este usuario no tiene permisos a esta plataforma",
            status: "warning",
          });
          this.$store.dispatch("logout");
          return false;
        }
        this.setUserProfile(response.data);
        this.$store.dispatch("updateClinic", response.data.clinics_ids[0]);
        this.$api.settings.getScheduleByUser().then((res) => {
          this.setUserSchedules(res.data.agendas);
        });
        this.$api.settings.getClinics().then((res) => {
          this.$store.dispatch("updateClinicList", res.data.clinics);
          this.$router.push({
            name: this.getPermissionRoutesByUserType[0],
          });
        });
      });
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    onLogin() {
      const payload = this.login.data();
      if (this.isFormDataValid()) {
        this.$api.auth
          .login(payload.username, payload.password)()
          .then((response) => {
            this.updateLoginTokens(response);
            this.getUserInfo();
          })
          .catch(() => {
            this.warning = true;
            this.warningTxt = "Usuario o Contraseña Incorrecta";
          });
      }
    },
  },
};
</script>
