export default (axios) => ({
  getAssistants(payload) {
    return axios.get("/v1/assistants", { params: payload });
  },
  postLaboratory(payload) {
    return axios.post("/v1/laboratory", payload);
  },
  getLaboratories(payload) {
    return axios.get("v1/laboratories", { params: payload });
  },
  updateLaboratory(id, payload) {
    return axios.put(`/v1/patient_laboratory/${id}`, payload);
  },
  getPatientLaboratories(payload) {
    return axios.get("v1/patient_laboratories", { params: payload });
  },
  getPatientLaboratory(id) {
    return axios.get(`/v1/patient_laboratory/${id}`);
  },
});
