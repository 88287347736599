const storage = window.localStorage;
export default {
  getToogleBMenu: (state) => state.toogleBMenu,
  getCurrentLanguage: (state) => {
    if (!state.language) {
      state.language =
        storage.getItem("language") !== null
          ? JSON.parse(storage.getItem("language"))
          : "";
    }
    return state.language;
  },
  getModal: (state) => (indice) => {
    const dataState = state;
    return dataState.modals[indice];
  },
  getRoutesConfiguration(state) {
    return state.routesConfiguration;
  },
};
