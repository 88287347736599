const succesfullAlertsByUrl = {
  "/oauth/token": "Se inicio sesión con exito",
  "v1/users": "Usuario actualizado con exito",
  "v1/user_password": "Contraseña actualizada con exito",
  "v1/appointments": "Cita actualizada con exito",
  "v1/appointment_confirmation": "Cita actualizada con exito",
  "v1/agendas": "Agenda actualizada con exito",
  "v1/services": "Servicio actualizado con exito",
  "v1/appointment_payment": "Pago de cita actualizado",
  "v1/patient_file": "Archivos actualizados",
  "v1/patient_historical": "Historial del paciente actualizado",
  "v1/calendar_configuration": "Configuracion del calendario actualizada",
  "v1/patient_record": "Información del paciente actualizado",
  "v1/patient_record_gynecology": "Información ginecologica actualizada",
  "v1/patient_record_menstrual": "Información menstrual actualizada",
};

const blackList = [];

export { succesfullAlertsByUrl, blackList };
