export default (axios) => ({
  getPatientList(payload) {
    return axios.get("v1/patients", { params: payload });
  },
  getPatient(id) {
    return axios.get(`v1/patient/${id}`);
  },
  getPatientAppointmentPass(id) {
    return axios.get(`v1/patient_appointment_pass/${id}`);
  },
  getDiagnostics() {
    return axios.get("v1/diagnostics");
  },
  getPlans() {
    return axios.get("v1/plans");
  },
  getMedicines() {
    return axios.get("v1/medicines");
  },
  uploadEvidence(payload, id) {
    return axios.post(`v1/appointment_payment/${id}`, payload);
  },
  getSurgeries(payload) {
    return axios.get("v1/operation_rooms", { params: payload });
  },
  createSurgery(payload) {
    return axios.post("v1/operation_rooms", payload);
  },
  getSurgeriesPreanesthetic(payload) {
    return axios.get("v1/operation_room_preanesthetics", { params: payload });
  },
  putSurgeriesPreanesthetic(payload) {
    return axios.put("v1/operation_room_preanesthetics", payload);
  },
  searchUsers(payload) {
    return axios.get("v1/search_users", { params: payload });
  },
});
