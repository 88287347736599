export default {
  setSecondOpinionState(state, value) {
    state.newFiliationSecondOpinionState = value;
  },
  setPaymentTypeAction(state, value) {
    state.paymentTypeAction = value;
  },
  setPaymentEvidenceSrc(state, value) {
    state.paymentEvidenceSrc = value;
  },
  setAppointmentTypeAction(state, value) {
    state.appointmentTypeAction = value;
  },
  setPatientSelected(state, value) {
    state.patientSelected = value;
  },
  setFolderOption(state, value) {
    state.folderOption = value;
  },
  setFolderComponents(state, value) {
    state.folderComponents = value;
  },
  setOccupations(state, value = []) {
    state.occupations = value;
  },
  setOccupationsPartner(state, value = []) {
    state.occupationsPartner = value;
  },
  setViewMode(state, value = false) {
    state.viewMode = value;
  },
  setOwe(state, value = null) {
    state.owe = value;
  },
  setPlanName(state, value = null) {
    state.planName = value;
  },
  setHasPartner(state, value = false) {
    state.hasPartner = value;
  },
  setCompleteModeBanner(state, value = false) {
    state.completeModeBanner = value;
  },
  setHidenSecondaryComponent(state, value = false) {
    state.hidenSecondaryComponent = value;
  },
};
