export default {
  setSelectedPatientNextAppointment(state, value = null) {
    state.selectedPatientNextAppointment = value;
  },
  setCalendarConfig(state, value = null) {
    state.calendarConfig = value;
    if (value) {
      localStorage.setItem("calendar-config", JSON.stringify(value));
    } else {
      localStorage.removeItem("calendar-config");
    }
  },
  setCalendarData(state, value = null) {
    state.calendarData = value;
  },
  setCalendarFilterMedic(state, value = "all") {
    state.calendarFilter.medic_id = value;
  },
  setCalendarFilterMedicList(state, value = []) {
    state.calendarFilter.medicList = value;
  },
  setCalendarFilterSchedule(state, value = "all") {
    state.calendarFilter.schedule_id = value;
  },
  setCEAppointmentState(state, value = "create") {
    state.ceAppointmentState = value;
  },
  setIsCreateExternalPatient(state, value = false) {
    state.isCreateExternalPatient = value;
  },
  setIsDonorPatient(state, value = false) {
    state.isDonorPatient = value;
  },
  setCEAppointmentEditID(state, value = null) {
    state.ceAppointmentEditID = value;
  },
  setCEFiliationState(state, value = "create") {
    state.ceFiliationState = value;
  },
  setFiliationInfo(state, value = null) {
    state.filiationInfo = value;
  },
  setFiliationState(state, value = null) {
    state.filiationState = value;
  },
  setSelectedPatientID(state, value = null) {
    state.selectedPatientID = value;
  },
  setAppointmentInfo(state, value = null) {
    state.infoAppointmentPayment = value;
  },
};
