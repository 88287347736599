import store from "@/store";
import { usersPermissions } from "@/constants/permissions";

export default {
  getUserSchedules(state) {
    if (!state.schedules) {
      const schedules = JSON.parse(localStorage.getItem("user-schedules"));
      if (schedules) store.commit("setUserSchedules", schedules);
    }
    return state.schedules;
  },
  getUserProfile(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile;
  },
  getPermissionRoutesByUserType(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    if (!state.profile?.user_type) {
      return [];
    }
    return usersPermissions[state.profile.user_type].routes;
  },
  getUserType(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.user_type;
  },
  isABCUserType(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return (
      state.profile?.user_type === "abc" ||
      state.profile?.user_type === "manager_abc"
    );
  },
  getUserPasswordChanged(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.password_changed;
  },
  hasPermissionToViewContactTel(state) {
    const usersTypes = ["superadmin", "cacit", "manager_cacit"];
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }

    return usersTypes.includes(state.profile?.user_type);
  },
  hasMultipleClinics(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.clinics_ids.length >= 2;
  },
  getUserID(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.id;
  },
  hasPermissionToFiliationInfoEdit(state) {
    const usersTypes = [
      "superadmin",
      "admin",
      "manager",
      "reception",
      "cacit",
      "manager_cacit",
    ];
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return usersTypes.includes(state.profile?.user_type);
  },
  isNurse(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.user_type === "nursing";
  },
  isSuperAdmin(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.user_type === "superadmin";
  },
  isMedicUser(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.user_type === "medic";
  },
  isCacitUser(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return state.profile?.user_type === "cacit";
  },
  isCacitType(state) {
    if (!state.profile) {
      const profile = JSON.parse(localStorage.getItem("user-profile"));
      if (profile) store.dispatch("updateUserProfile", profile);
    }
    return (
      state.profile?.user_type === "cacit" ||
      state.profile?.user_type === "manager_cacit"
    );
  },
  // TODO: move to his own module SEARCH
  getSearchUser(state) {
    if (!state.searchUser) {
      const searchUser = JSON.parse(localStorage.getItem("searchUser"));
      if (searchUser) store.dispatch("updateSearchUser", searchUser);
    }
    return state.searchUser;
  },
};
