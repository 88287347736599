import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InputColorPicker from "vue-native-color-picker";
import { instance, factories } from "@/api";
import registerComponents from "@/fontawesome/icons";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import Moment from "moment";
import VueCountryCode from "vue-country-code";
import VueGtag from "vue-gtag";
import vSelect from "vue-select";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import i18n from "./i18n";
import router from "./routes";
import store from "./store";
import App from "./App.vue";
import "moment/locale/es";
import "vue-search-select/dist/VueSearchSelect.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

Moment.locale("es");

const isProd = process.env.NODE_ENV === "production";

Vue.use(
  VueGtag,
  {
    config: { id: "G-1M6E42198X" },
  },
  router
);

if (isProd) {
  Sentry.init({
    Vue,
    dsn: "https://1b0524b51dc7414cb472dae7e5362ec2@o1366441.ingest.sentry.io/6663090",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "citmer-panel-staging.web.app", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.prototype.$moment = Moment;

Vue.component("v-select", vSelect);
Vue.use(InputColorPicker);
Vue.use(VueCountryCode);
Moment.locale("es");

Vue.prototype.$moment = Moment;

registerComponents();
Vue.component("font-awesome-icon", FontAwesomeIcon);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexchart", VueApexCharts);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);

Vue.prototype.$api = factories(instance);

// TO - DO: if you want to add a new language set it by vuex
// if (!store.getters.getCurrentLanguage) {
//   store.commit('setCurrentLanguage', navigator.language.split('-')[0])
// }

Object.defineProperty(String.prototype, "capitalize", {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "stringToDate", {
  value() {
    const dateSplit = this.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    const dateClean = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
    return dateClean;
  },
  enumerable: false,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
