<template>
  <b-modal
    ref="cancelAppointmentModal"
    id="cancelAppointmentModal"
    hide-header
    @ok="cancelAppointmentActions"
    @cancel="hideModal"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    @shown="getAppointmentInfo"
    ok-variant="danger"
    cancel-variant="primary"
    size="lg">
    <div class="container">
      <h4 class="titles my-4 mx-3">Cancelar Evento</h4>
      <div class="row mx-3 mb-2" v-if="!isConfirmationActive">
        <div class="form-group">
          <label for="cancelReason">Motivo de Cancelación</label>
          <textarea
            class="form-control"
            id="cancelReason"
            rows="3"
            v-model="appointment.cancelReason"></textarea>
        </div>
      </div>
      <div class="row mx-3 mb-5" v-if="isConfirmationActive">
        <div class="col">
          <div class="card pointer menuCard" role="button">
            <div class="card-img-top text-secondary">
              <font-awesome-icon
                size="xl"
                class="my-5 card-img-top text-danger"
                icon="exclamation-triangle" />
            </div>
            <hr />
            <div class="card-body text-justify" v-if="appointmentInfo">
              <h5 class="mb-2">
                <b>¿Estás seguro que deseas cancelar este evento?</b>
              </h5>
              <div class="text-justify">
                <p class="mb-1">
                  <b>Paciente:</b> {{ appointmentInfo.patient_name }}.
                </p>
                <p class="mb-1">
                  <b>Médico asignado:</b> {{ appointmentInfo.medic_name }}.
                </p>
                <p class="mb-1"><b>Fecha:</b> {{ appointmentInfo.date }}.</p>
                <p class="mb-1"><b>Hora:</b> {{ appointmentInfo.time }}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "@/helpers/form";

export default {
  name: "CancelAppointment",
  mounted() {
    this.$store.commit("newModal", {
      key: "cancelAppointmentModal",
      ref: this.$refs.cancelAppointmentModal,
    });
  },
  data() {
    return {
      appointmentInfo: null,
      isConfirmationActive: false,
      appointment: new Form({
        cancelReason: "",
      }),
    };
  },
  computed: {
    ...mapGetters(["ceAppointmentEditID"]),
    okTitle() {
      return this.isConfirmationActive ? "Confirmar" : "Cancelar";
    },
    cancelTitle() {
      return this.isConfirmationActive ? "Cancelar" : "Cerrar";
    },
  },
  methods: {
    cancelAppointmentActions(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.isConfirmationActive) {
        this.cancelAppointment();
      } else {
        this.isConfirmationActive = true;
      }
    },
    getAppointmentInfo() {
      if (!this.ceAppointmentEditID) return;
      const id = this.ceAppointmentEditID;
      this.$api.appointments.getInfo(id).then((response) => {
        this.appointmentInfo = response.data;
      });
    },
    hideModal() {
      this.$emit("resetComponent");
      this.$store.getters.getModal("cancelAppointmentModal").hide();
    },
    cancelAppointment() {
      const payload = {
        reason: this.appointment.cancelReason,
      };
      this.$api.appointments
        .cancel(this.ceAppointmentEditID, payload)
        .then(() => {
          if (this.$route.name === "calendar") {
            this.$emit("refreshCalendarDataByFilter", {
              medicID: this.appointmentInfo.medic_id,
              agendaID: "all",
            });
          } else {
            this.$emit("refreshCalendarData");
          }
          this.hideModal();
        });
    },
  },
};
</script>

<style></style>
