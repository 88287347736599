<template>
  <div class="ceAppointment__general">
    <div class="row m-3 ms-4">
      <h4 class="titles">Datos Generales.</h4>
      <div class="col">
        <!-- Agenda Select -->
        <div class="form-group w-50 mt-2">
          <label for="agenda_id" class="d-block">Agenda</label>
          <select
            v-model="agenda_id"
            @change="getServices"
            class="form-select w-auto inputsGeneralClass">
            <option
              v-for="agenda in agendasList"
              :key="`${agenda.id}-${agenda.name}`"
              :value="agenda.id">
              {{ agenda.name }}
            </option>
          </select>
        </div>
        <!-- Agenda Select /-->
        <!-- Servicio Select -->
        <div class="form-group w-50 mt-2">
          <label for="service" class="d-block">Servicio</label>
          <div role="group" class="input-group">
            <select v-model="service" class="form-select inputsGeneralClass">
              <option
                v-for="serv in servicesList"
                :key="`${serv.id}-${serv.name}`"
                :value="serv">
                {{ serv.name }}
              </option>
            </select>
            <div class="input-group-append">
              <button
                @click="pushService"
                :disabled="!service"
                type="button"
                class="btn h-100 btn-outline-primary btn-outline-primary-add">
                Agregar
              </button>
            </div>
          </div>
        </div>
        <!-- Servicio Select /-->
        <b-table
          v-if="servicesListSelected.length > 0"
          :items="servicesListSelected"
          :fields="servicesFields"
          class="mt-4 align-middle fadeIn"
          borderless
          striped>
          <template v-slot:cell(actions)="row">
            <button
              type="button"
              @click="popService(row.index)"
              class="btn btnSearch">
              <font-awesome-icon
                class="svg-inline--fa fa-search fa-w-16"
                :icon="['fas', 'minus']" />
            </button>
          </template>
        </b-table>
        <div
          class="w-100 mx-auto mt-5 grow"
          v-if="$v.servicesListSelected.$error">
          <p class="text-center text-danger">
            Seleccionar un servicio y presiona el botón "agregar" es requerido
          </p>
        </div>
      </div>
      <div class="col">
        <textarea
          class="col-12 form-control mb-4 inputsGeneralClass w-100"
          v-model="comments"
          placeholder="Notas..."
          name=""
          id=""
          cols="30"
          rows="10"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { servicesFields } from "@/constants/appointments";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "GeneralInputs",
  props: {
    medicType: {
      type: String,
      required: true,
      default: "medic",
    },
    isMainGeneralInputSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      servicesFields,
      servicesListSelected: [],
      agendas: [],
      agenda_id: "",
      services: [],
      service: "",
      comments: "",
    };
  },
  watch: {
    medicType() {
      this.agenda_id = this.agendasList[0].id;
    },
    servicesListSelected() {
      if (this.servicesListSelected.length > 0) {
        this.$emit("switchSelectedGeneral", true);
      } else {
        this.$emit("switchSelectedGeneral", false);
      }
    },
  },
  mounted() {
    this.$api.settings.getScheduleServices().then((res) => {
      this.agendas = res.data.agendas;
      if (this.agendas !== []) {
        this.agenda_id = res.data.agendas[0].id;
        this.getServices();
      }
    });
  },
  validations() {
    return {
      servicesListSelected: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters(["isCEAppointmentCreate", "getUserType", "isABCUserType"]),
    agendasList() {
      if (this.medicType === "medic") {
        return this.agendas.filter((agenda) => agenda.id !== 6);
      }
      if (this.medicType === "external_medic") {
        return this.agendas.filter((agenda) => agenda.id !== 5);
      }
      return this.agendas;
    },
    servicesList() {
      if (this.isABCUserType) {
        return this.services.filter(
          (service) => service.id !== 383 && service.id !== 213
        );
      }
      const newServices = this.services.filter(
        (service) => !this.servicesListSelected.includes(service)
      );
      // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
      this.service = newServices[0];
      return newServices;
    },
  },
  methods: {
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    getServices() {
      this.$api.settings
        .getServices({ agenda_id: this.agenda_id })
        .then((res) => {
          this.services = res.data.services;
          this.service = "";
        });
    },
    pushService() {
      this.servicesListSelected.push(this.service);
    },
    popService(index) {
      this.servicesListSelected.splice(index, 1);
    },
  },
};
</script>

<style></style>
