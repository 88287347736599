<template>
  <b-modal
    ref="ceFiliation"
    id="ceFiliation"
    content-class="px-3 pb-3"
    size="xl"
    @shown="loadFiliationInfo"
    @hide="setSelectedPatientID(null)"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer>
    <div class="filiation-form m-3">
      <div class="titles mb-3" :class="{ 'mb-5': !typeAction }">
        {{ title }}
      </div>
      <HeaderFiliation v-if="typeAction" />
      <General v-if="typeAction" ref="generalForm" />
      <div class="row">
        <FiliationInfoTabs
          v-if="!typeAction"
          :isEditActive="isEditFiliationActive"
          @changeActiveComponent="changeActiveComponent" />
        <div class="col">
          <transition name="scale" mode="out-in">
            <KeepAlive v-if="typeAction">
              <component
                :ref="componentByStep"
                :is="componentByStep"
                :selectedComponent="componentByStep"
                :steps="step"
                :isEditActive="isEditFiliationActive"
                :typeAction="typeAction"
                :filiationInfo="filiationInfo"
                @validateForm="updatePayload"
                @refreshCalendarData="$emit('refreshAppointments')"
                @resetComponent="$emit('resetComponent')" />
            </KeepAlive>
            <component
              v-else
              :ref="componentByStep"
              :is="componentByStep"
              :selectedComponent="componentByStep"
              :steps="step"
              :isEditActive="isEditFiliationActive"
              :typeAction="typeAction"
              :filiationInfo="filiationInfo"
              @validateForm="updatePayload"
              @refreshCalendarData="$emit('refreshAppointments')"
              @resetComponent="$emit('resetComponent')" />
          </transition>
        </div>
      </div>

      <div class="btns_footer" v-if="typeAction">
        <button
          class="btn cancel"
          v-text="leftButtonText"
          @click="actionBack" />
        <button class="btn save" v-text="rightButtonText" @click="actionNext" />
      </div>
      <div class="btns_footer" v-else>
        <button
          class="btn cancel"
          v-text="cancelTxtBtn"
          @click="cancelAction" />
        <button
          v-if="!isEditFiliationActive"
          class="btn save"
          v-text="'Editar'"
          @click="isEditFiliationActive = true" />
        <button
          v-else
          class="btn save"
          v-text="'Guardar'"
          @click="updateFiliation" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { componentsFiliation } from "@/constants/appointments";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Patient from "@/components/patient/appointment/filiations/Patient.vue";
import Couple from "@/components/patient/appointment/filiations/Couple.vue";
import Address from "@/components/patient/appointment/filiations/Address.vue";
import Contact from "@/components/patient/appointment/filiations/Contact.vue";
import General from "@/components/patient/appointment/filiations/General.vue";
import FiliationInfoTabs from "@/components/patient/appointment/filiations/Tabs.vue";
import HeaderFiliation from "@/components/patient/appointment/filiations/Header.vue";

export default {
  name: "FiliationAppointment",
  props: {
    // To know if is "create filiation"(true) or "preview edit"(false) option.
    typeAction: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  components: {
    Patient,
    Couple,
    Address,
    Contact,
    HeaderFiliation,
    General,
    FiliationInfoTabs,
  },
  data() {
    return {
      isEditFiliationActive: false,
      componentsFiliation,
      stateLoadedInfo: [false, false, false, false],
      filiationInfo: null,
      leftButtonText: "Cancelar",
      rightButtonText: "Siguiente",
      id: 0,
      step: 0,
      payload: {},
      selectedComponent: "Patient",
    };
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "getFiliationInfo",
      "getCalendarData",
      "getClinic",
      "getCalendarFilterMedic",
      "isCacitUser",
      "getFiliationState",
      "getSelectedPatientID",
      "isCreateExternalPatient",
      "isDonorPatient",
    ]),
    cancelTxtBtn() {
      if (this.isEditFiliationActive) {
        return "Cancelar Edición";
      }
      return "Salir";
    },
    title() {
      if (this.typeAction) {
        return "Nueva Filiación";
      }
      return this.isEditFiliationActive
        ? "Actualizar Filiación"
        : "Información de Filiación";
    },
    componentByStep() {
      if (this.typeAction) {
        return this.componentsFiliation[this.step];
      }
      return this.selectedComponent;
    },
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "ceFiliation",
      ref: this.$refs.ceFiliation,
    });
    this.$api.appointments.getOccupations().then((res) => {
      this.updateOccupations(res.data.occupations);
    });
    this.$api.appointments.getOccupationsCouple().then((res) => {
      this.updateOccupationsPartner(res.data.occupations);
    });
  },
  methods: {
    ...mapActions([
      "updateAlerts",
      "updateFiliationState",
      "updateOccupations",
      "updateOccupationsPartner",
    ]),
    ...mapMutations(["setIsDonorPatient", "setSelectedPatientID"]),
    hideModalNewFiliation() {
      if (
        this.isCacitUser ||
        this.getUserType === "superadmin" ||
        this.getUserType === "admin" ||
        this.getUserType === "manager_cacit"
      ) {
        if (this.getFiliationState === "created") {
          this.$store.getters.getModal("ceFiliation").hide();
          this.updateFiliationState();
          this.$emit("resetComponent");
          return;
        }
        if (this.getFiliationState === "new") {
          this.updateFiliationState();
          this.cancelFiliation();
        }
      }
    },
    cancelFiliation() {
      const payload = { patient_id: this.getFiliationInfo.patient_id };
      this.$api.appointments.deleteFiliation(payload).then(() => {
        this.$emit("resetComponent");
        this.$store.getters.getModal("ceFiliation").hide();
      });
    },
    cancelAction() {
      if (this.isEditFiliationActive) {
        this.isEditFiliationActive = false;
        if (this.componentByStep === "Patient") {
          this.$refs.Patient.isPreviewIDEActive = true;
          this.$refs.Patient.isPreviewSecondOpinionActive = true;
        }
        if (this.componentByStep === "Couple") {
          this.$refs.Couple.isPreviewIDEActive = true;
        }
        this.loadFiliationInfo(this.filiationInfo.id);
      } else {
        this.updateFiliationState("created");
        this.setSelectedPatientID(null);
        this.hideModal();
      }
    },
    changeActiveComponent(activeTab) {
      this.selectedComponent = activeTab;
    },
    loadFiliationInfo() {
      if (this.getFiliationState !== "edit" && !this.getSelectedPatientID)
        return;
      const id = this.getSelectedPatientID;
      this.$api.appointments.getFiliation(id).then((res) => {
        const hasPartnerBirth = Boolean(res.data.partner_date_birth);
        this.filiationInfo = res.data;
        this.filiationInfo.id = id;
        this.filiationInfo.date_birth = res.data.date_birth
          ? res.data.date_birth.stringToDate()
          : "";
        this.filiationInfo.partner_date_birth = hasPartnerBirth
          ? res.data.partner_date_birth.stringToDate()
          : "";
        this.$refs[this.componentByStep].updateFormInfo(this.filiationInfo);
      });
    },
    updatePayload(payload) {
      this.payload = { ...this.payload, ...payload.data() };
    },
    hideModal() {
      if (this.typeAction && !this.isCreateExternalPatient) {
        this.hideModalNewFiliation();
      } else {
        this.$store.getters.getModal("ceFiliation").hide();
        this.$emit("resetComponent");
      }
    },
    validationForm(componentPosition) {
      const { validPatient } = this.$refs[this.componentByStep];
      let isValid = false;
      isValid =
        this.$refs.ceFiliation.$children[0].$children[
          componentPosition
        ].validateData(componentPosition);
      const isValidGeneral =
        this.$refs.ceFiliation.$children[0].$children[
          componentPosition - 1
        ].isFormDataValidByComponentName();
      isValid = isValid && isValidGeneral;

      if (validPatient !== undefined) {
        if (!validPatient) {
          this.updateAlerts({
            status: "error",
            content: "La edad del paciente/pareja no permite el proceso.",
          });
          return false;
        }
      }
      if (isValid) return true;
      this.updateAlerts({
        status: "error",
        content: "Faltan campos requeridos en el formulario.",
      });
      return false;
    },
    generateButtonContent() {
      this.leftButtonText = this.step > 0 ? "Atrás" : "Cancelar";
      this.rightButtonText = this.step >= 3 ? "Guardar" : "Siguiente";
      if (
        this.rightButtonText === "Guardar" &&
        !this.typeAction &&
        this.isEditFiliationActive
      ) {
        this.rightButtonText = "Salir";
      }
    },
    actionBack() {
      if (this.step === 0) {
        this.hideModal();
      } else {
        this.step -= 1;
        this.generateButtonContent();
      }
    },
    actionNext() {
      let isValid = false;
      if (this.isEditFiliationActive && !this.typeAction) {
        isValid = this.validationForm(0);
      } else {
        isValid = this.validationForm(2);
      }
      if (!isValid) return false;
      if (this.step < 3) {
        this.step += 1;
        this.generateButtonContent();
      } else {
        this.saveData();
      }
      return true;
    },
    createPayload() {
      const formData = new FormData();
      Object.keys(this.payload).forEach((key) => {
        formData.append(key, this.payload[key]);
      });
      formData.delete("services");
      formData.append("service_id", this.payload.services);
      formData.append("date", this.getCalendarData.dateEdit);
      formData.append("time", this.getCalendarData.time);
      formData.append("clinic_id", this.getClinic);
      const isMedicFilterAll = this.getCalendarFilterMedic === "all";
      if (this.typeAction && isMedicFilterAll) {
        formData.append("medic_id", this.$refs.generalForm.general.medic_id);
      } else {
        formData.append("medic_id", this.getCalendarFilterMedic);
      }
      if (!this.isCreateExternalPatient) {
        formData.append("patient_id", this.getFiliationInfo.patient_id);
      }
      formData.append(
        "isPresentOnClinic",
        this.$children[1]?.general.isPresentOnClinic
      );
      return formData;
    },
    saveData() {
      const payload = this.createPayload();
      if (this.isCreateExternalPatient) {
        this.createFiliationExternal(payload);
        return;
      }
      this.createFiliation(payload);
    },
    createFiliationExternal(payload) {
      if (this.isDonorPatient) {
        payload.append("patient_type", "donor");
      }
      this.$api.appointments.createFiliationExternal(payload).then((res) => {
        this.setIsDonorPatient(false);
        const payloadPaid = new FormData();
        let isPaid = "";
        switch (this.payload.paidOut) {
          case "yes":
            isPaid = "paid_out";
            break;
          case "no":
            isPaid = "not_payed";
            break;
          case "second_opinion":
            isPaid = "free_of_charge";
            break;
          default:
            isPaid = "not_payed";
        }
        payloadPaid.append("is_paid", isPaid);
        this.payload.paidOut_image.forEach((voucher) => {
          payloadPaid.append("vouchers[]", voucher);
        });
        if (Array.isArray(this.payload.second_opinion_doc)) {
          if (this.payload.second_opinion_doc.length > 0) {
            this.payload.second_opinion_doc.forEach((secondOpinion) => {
              payloadPaid.append("second_opinion_doc[]", secondOpinion);
            });
          }
        }
        payloadPaid.append("file_type", this.payload.file_type);
        payloadPaid.append("appointment_id", res.data.appointment_id);
        this.updateFiliationState("created");
        if (this.$route.name === "calendar") {
          this.$emit("refreshCalendarDataByFilter", {
            medic: this.getCalendarFilterMedic,
            agendaID: "all",
          });
        } else {
          this.$emit("refreshCalendarData");
        }
        this.$api.appointments.updateAppointmentPaid(payloadPaid).then(() => {
          this.hideModal();
        });
      });
    },
    createFiliation(payload) {
      if (Array.isArray(this.payload.identification_image)) {
        if (this.payload.identification_image.length > 0) {
          this.payload.identification_image.forEach((identification) => {
            payload.append("identification_image[]", identification);
          });
        }
      }
      payload.delete("identification_image");

      if (Array.isArray(this.payload.partner_identification_image)) {
        if (this.payload.partner_identification_image.length > 0) {
          this.payload.partner_identification_image.forEach(
            (identification) => {
              payload.append("partner_identification_image[]", identification);
            }
          );
        }
      }
      payload.delete("partner_identification_image");
      if (Array.isArray(this.payload.second_opinion_doc)) {
        if (this.payload.second_opinion_doc.length > 0) {
          this.payload.second_opinion_doc.forEach((secondOpinion) => {
            payload.append("second_opinion_doc[]", secondOpinion);
          });
        }
      }
      payload.delete("second_opinion_doc");
      this.$api.appointments.createFiliation(payload).then((res) => {
        const payloadPaid = new FormData();
        let isPaid = "";
        switch (this.payload.paidOut) {
          case "yes":
            isPaid = "paid_out";
            break;
          case "no":
            isPaid = "not_payed";
            break;
          case "second_opinion":
            isPaid = "free_of_charge";
            break;
          default:
            isPaid = "not_payed";
        }
        payloadPaid.append("is_paid", isPaid);
        this.payload.paidOut_image.forEach((voucher) => {
          payloadPaid.append("vouchers[]", voucher);
        });
        payloadPaid.append("file_type", this.payload.file_type);
        payloadPaid.append("appointment_id", res.data.appointment_id);
        this.updateFiliationState("created");
        if (this.$route.name === "calendar") {
          this.$emit("refreshCalendarDataByFilter", {
            medic: this.getCalendarFilterMedic,
            agendaID: "all",
          });
        } else {
          this.$emit("refreshCalendarData");
        }
        this.$api.appointments.updateAppointmentPaid(payloadPaid).then(() => {
          this.hideModal();
        });
      });
    },
    updateFiliation() {
      this.$refs[this.componentByStep].saveUpdateFiliation();
    },
  },
};
</script>
