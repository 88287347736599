export default (axios) => ({
  getPatientNutritions(payload) {
    return axios.get("v1/patient_nutritions", { params: payload });
  },
  getPatientNutrition(id) {
    return axios.get(`v1/patient_nutrition/${id}`);
  },
  postPatientNutrition(payload) {
    return axios.post("v1/patient_nutrition", payload);
  },
  getPatientNutritionLast(payload) {
    return axios.get("v1/patient_nutritions_last", { params: payload });
  },
});
