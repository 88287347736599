export default {
  updateCalendarFilterMedic({ commit }, data) {
    commit("setCalendarFilterMedic", "all");
    commit("setCalendarFilterMedicList", data || []);
  },
  updateCalendarConfig({ commit }, data) {
    commit("setCalendarConfig", data);
  },
  updateCalendarDataAppointments({ commit }, data) {
    commit("setCalendarData", data);
  },
  updateFiliationState({ commit }, data = "new") {
    commit("setFiliationState", data);
  },
};
