export default {
  updateAppointmentTypeAction({ commit }, type) {
    commit("setAppointmentTypeAction", type);
  },
  updatePaymentTypeAction({ commit }, type) {
    commit("setPaymentTypeAction", type);
  },
  updatePaymentEvidenceSrc({ commit }, type) {
    commit("setPaymentEvidenceSrc", type);
  },
  updatePatientSelected({ commit }, patient) {
    commit("setPatientSelected", patient);
  },
  updateFolderOption({ commit }, payload) {
    commit("setFolderOption", payload);
  },
  updateFolderComponents({ commit }, payload) {
    commit("setFolderComponents", payload);
  },
  updateOccupations({ commit }, payload) {
    commit("setOccupations", payload);
  },
  updateOccupationsPartner({ commit }, payload) {
    commit("setOccupationsPartner", payload);
  },
  updateViewMode({ commit }, payload) {
    commit("setViewMode", payload);
  },
  updateOwe({ commit }, payload) {
    commit("setOwe", payload);
  },
  updateHasPartner({ commit }, payload) {
    commit("setHasPartner", payload);
  },
  updateBannerMode({ commit }, payload) {
    commit("setCompleteModeBanner", payload);
  },
  updateHidenSecondaryComponent({ commit }, payload) {
    commit("setHidenSecondaryComponent", payload);
  },
};
