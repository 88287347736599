import { userPermission } from "@/constants/permissions/routesByUserType";

const usersPermissions = userPermission;

const folderByTypeUsers = [
  {
    userType: "superadmin",
    value: ["historical", ["Historical", "CreateMedicNote"]],
  },
  {
    userType: "admin",
    value: ["historical", ["Historical", "CreateMedicNote"]],
  },
  {
    userType: "manager",
    value: ["historical", ["Historical", "CreateMedicNote"]],
  },
  {
    userType: "abc",
    value: ["historical", ["Historical", "CreateMedicNote"]],
  },
  {
    userType: "medic",
    value: ["historical", ["Historical", "CreateMedicNote"]],
  },
  {
    userType: "nursing",
    value: ["historical", ["Historical"]],
  },
  {
    userType: "nutrition",
    value: ["nutrition", ["Nutrition", "NutritionCard"]],
  },
  {
    userType: "psychology",
    value: ["psychology", ["Psychology", "PsychologyCard"]],
  },
  {
    userType: "control",
    value: ["historical", ["Historical"]],
  },
  {
    userType: "laboratory",
    value: ["laboratories", ["Laboratories", ""]],
  },
  {
    userType: "cash_register",
    value: ["historical", ["Historical"]],
  },
];

const filitiationAndPatientTableActions = {
  reschedule: [
    "superadmin",
    "admin",
    "external",
    "operating_room",
    "medic",
    "nutrition",
    "pyschology",
    "manager_cacit",
    "cacit",
  ],
  newAppointment: [
    "superadmin",
    "admin",
    "external",
    "operating_room",
    "medic",
    "nutrition",
    "pyschology",
    "manager_cacit",
    "cacit",
  ],
  confirmation: ["superadmin", "admin", "control", "reception"],
};

const hasPermissionCEAppointments = {
  superadmin: {
    toCreate: true,
    toEnterMenuCreate: true,
    toEdit: true,
    toEnterMenuEdit: true,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: true,
    toCalendarFilterByAgenda: true,
  },
  admin: {
    toCreate: true,
    toEnterMenuCreate: true,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: true,
    toCalendarFilterByAgenda: true,
  },
  manager: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: false,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: true,
    toCalendarFilterByAgenda: true,
  },
  abc: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  manager_abc: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  control: {
    toCreate: false,
    toEnterMenuCreate: false,
    toEdit: false,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  reception: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  cash_register: {
    toCreate: false,
    toEnterMenuCreate: false,
    toEdit: false,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  external: {
    toCreate: false,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  operating_room: {
    toCreate: false,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  medic: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  nursing: {
    toCreate: true,
    toEnterMenuCreate: true,
    toEdit: true,
    toEnterMenuEdit: true,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: true,
    toCalendarFilterByAgenda: true,
  },
  nutrition: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  psychology: {
    toCreate: true,
    toEnterMenuCreate: false,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  laboratory: {
    toCreate: false,
    toEnterMenuCreate: false,
    toEdit: false,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: false,
    toCalendarFilterByAgenda: false,
  },
  manager_cacit: {
    toCreate: true,
    toEnterMenuCreate: true,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: false,
    toCalendarFilterByMedic: true,
    toCalendarFilterByAgenda: false,
  },
  cacit: {
    toCreate: true,
    toEnterMenuCreate: true,
    toEdit: true,
    toEnterMenuEdit: false,
    toEditOnlySelfCreated: true,
    toCalendarFilterByMedic: true,
    toCalendarFilterByAgenda: false,
  },
};

const hasPermissionIcons = {
  superadmin: {
    viewPatientHistory: true,
    addExtraContactMedium: true,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: true,
    generatePDF: true,
    authCashRegister: true,
    viewAffiliationInfo: true,
  },
  admin: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: true,
    generatePDF: true,
    authCashRegister: false,
    viewAffiliationInfo: true,
  },
  manager: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: true,
    generatePDF: false,
    authCashRegister: true,
    viewAffiliationInfo: false,
  },
  abc: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  manager_abc: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    openPreviewInfo: false,
  },
  control: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  reception: {
    viewPatientHistory: false,
    addExtraContactMedium: true,
    editPatientDetail: false,
    viewPatientDetail: false,
    uploadPaymentEvidence: false,
    generatePDF: true,
    authCashRegister: false,
    viewAffiliationInfo: true,
  },
  cash_register: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: false,
    viewPatientDetail: false,
    uploadPaymentEvidence: true,
    generatePDF: false,
    authCashRegister: true,
    viewAffiliationInfo: false,
  },
  external: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  operating_room: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  medic: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  nursing: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  nutrition: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  psychology: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  laboratory: {
    viewPatientHistory: true,
    addExtraContactMedium: false,
    editPatientDetail: true,
    viewPatientDetail: true,
    uploadPaymentEvidence: false,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: false,
  },
  manager_cacit: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: false,
    viewPatientDetail: false,
    uploadPaymentEvidence: true,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: true,
  },
  cacit: {
    viewPatientHistory: false,
    addExtraContactMedium: false,
    editPatientDetail: false,
    viewPatientDetail: false,
    uploadPaymentEvidence: true,
    generatePDF: false,
    authCashRegister: false,
    viewAffiliationInfo: true,
  },
};

export {
  filitiationAndPatientTableActions,
  folderByTypeUsers,
  usersPermissions,
  hasPermissionCEAppointments,
  hasPermissionIcons,
};
