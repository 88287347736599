<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('Patient')"
        :class="{
          active: type === 'Patient',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Datos de Paciente
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('Couple')"
        :class="{
          active: type === 'Couple',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Datos de Pareja
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link pointer"
        @click="changeActiveTab('Address')"
        :class="{
          active: type === 'Address',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Dirección
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link"
        :disabled="!isEditActive"
        @click="changeActiveTab('Contact')"
        :class="{
          active: type === 'Contact',
          pointer: !isEditActive,
          disabled: isEditActive,
        }">
        Contacto
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FiliationTabs",
  props: {
    isEditActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      type: "Patient",
    };
  },
  methods: {
    changeActiveTab(activeTab) {
      this.$emit("changeActiveComponent", activeTab);
      this.type = activeTab;
    },
  },
};
</script>
